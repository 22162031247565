import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

import { LoadingButton } from "@/components/_uiext"
import useAdminApplicationStore from "@/stores/useAdminApplicationStore"

import { Dialog, DialogContent } from "@repo/ui/components/ui/dialog"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { APPLICATION_STATUS } from "@repo/util/constant"

import { useAdminAppContext } from "../../AdminAppContext"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export default function StatusChangeDialog(props: Readonly<Props>) {
  const { open, setOpen } = props
  const store = useAdminApplicationStore()
  const { data, setData } = useAdminAppContext()

  const [newStatus, setNewStatus] = useState(data?.status ?? "")

  const handleSaveClick = () => {
    store.updateStatusAction(data.uuid, newStatus, (newData) => {
      setData(newData)
      setOpen(false)
    })
  }

  useEffect(() => {
    setNewStatus(data?.status)
  }, [data?.status])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="flex h-full flex-col justify-between gap-0 bg-white p-10 md:h-auto">
        <div className="flex flex-col">
          <h1 className="text-main mb-4 text-center text-lg font-semibold">Application status</h1>

          <p className="text-default mb-6 text-center text-base font-normal">
            Please select the status for Application{" "}
            <span className="text-main">
              #
              {Number(data?.id ?? "")
                .toString()
                .padStart(10, "0")}
            </span>
          </p>

          <p className="text-main mb-2 text-sm font-normal">Application status</p>

          <Select
            value={newStatus}
            onValueChange={(value: string) => {
              setNewStatus(value)
            }}
          >
            <SelectTrigger className="text-main mb-6 h-12 px-4 py-2 text-sm">
              <SelectValue placeholder={newStatus} />
            </SelectTrigger>

            <SelectContent>
              {Object.entries(APPLICATION_STATUS).map(([k, v]) => (
                <SelectItem key={k} value={v.key}>
                  {v.key}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="flex justify-center">
          <LoadingButton
            loading={store.loading}
            disabled={data?.status === newStatus}
            className="w-full px-6 py-4 md:w-auto"
            onClick={handleSaveClick}
          >
            Save
          </LoadingButton>
        </div>
      </DialogContent>
    </Dialog>
  )
}
