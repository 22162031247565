import React, { useEffect, useRef, useState } from "react"

import { Link } from "react-router-dom"

import ApplicationLayout from "@/components/_layout/ApplicationLayout"
import useApplicationDraftStore from "@/stores/useApplicationDraftStore"

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from "@repo/ui/components/ui/breadcrumb"
import { APPLICATION_STEPS, LOAN_DOCUMENT_TYPES } from "@repo/util/constant"

import MainForm from "./MainForm"

import { AppContextProvider } from "../AppContext"

export default function ApplicationsCreate() {
  const initialized = useRef(false)
  const store = useApplicationDraftStore()

  const [initialSteps, setInitialSteps] = useState(
    Object.entries(APPLICATION_STEPS).map(([k, v]) => {
      return v
    })
  )
  const [initialActiveStep, setInitialActiveStep] = useState(0)
  const [initialLoading, setInitialLoading] = useState(true)
  const [initialData, setInitialData] = useState<any>(null)

  const initialize = () => {
    setInitialLoading(true)
    store.createAction(
      {
        current_step: APPLICATION_STEPS.BorrowerSelection,
        max_step: APPLICATION_STEPS.BorrowerSelection,
        loan_document_type: LOAN_DOCUMENT_TYPES.PrivateCredit.key,
        proportion: 0.5
      },
      (newData) => {
        setInitialData(newData)

        const activeStep = initialSteps.indexOf(newData.current_step ?? "")
        setInitialActiveStep(activeStep === -1 ? 0 : activeStep)

        setTimeout(() => {
          setInitialLoading(false)
        }, 1000)
      }
    )
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      initialize()
    }
  }, [])

  return (
    <AppContextProvider
      initialSteps={initialSteps}
      initialActiveStep={initialActiveStep}
      initialLoading={initialLoading}
      initialData={initialData}
    >
      <ApplicationLayout
        breadcrumb={
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <Link to="/dashboard" className="text-default text-sm font-normal">
                  Dashboard
                </Link>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <Link to="/applications/list" className="text-default text-sm font-normal">
                  Applications
                </Link>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage className="text-main text-sm font-semibold">
                  Create application
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        }
      >
        <div className="flex flex-col">
          <div className="mb-4 block w-full rounded-xl border border-[#ECECEC] bg-white p-8 lg:hidden">
            <h3 className="text-main text-lg font-semibold">Create application</h3>
          </div>

          <MainForm />
        </div>
      </ApplicationLayout>
    </AppContextProvider>
  )
}
