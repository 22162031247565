import React, { useEffect, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { Button } from "@repo/ui/components/ui/button"

import LeadDetails from "./sections/LeadDetails"
import { LOADING_TIMEOUT } from "@/utils/constants"
import { LoadingPage } from "@/components/_uiext"

const FormSchema = z.object({
  full_name: z.string().min(1, { message: "This field is required" }),
  mobile_number: z.string().min(1, { message: "This field is required" }),
  email: z.string().email({ message: "This field is required" }),
  requirement: z.string().min(1, { message: "This field is required" }),
  created_at: z.string().min(1, { message: "This field is required" })
})

type FormSchemaType = z.infer<typeof FormSchema>

interface Props {
  data: Record<string, any>
}

export default function MainForm({ data }: Readonly<Props>) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      full_name: "",
      mobile_number: "",
      email: "",
      requirement: "",
      created_at: ""
    }
  })

  const handleCancelClick = () => {
    navigate(-1)
  }

  useEffect(() => {
    form.reset({
      full_name: data.full_name ?? "",
      mobile_number: data.mobile_number ?? "",
      email: data.email ?? "",
      requirement: data.requirement ?? "",
      created_at: data.created_at ?? ""
    })

    setTimeout(() => {
      setLoading(false)
    }, LOADING_TIMEOUT)
  }, [data])

  return (
    <FormProvider {...form}>
      <LoadingPage loading={loading}>
        <form className="flex flex-col">
          <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
            <div className="flex w-full flex-1 flex-col gap-5 xl:pr-8">
              <div>
                <LeadDetails />
              </div>
            </div>

            <div className="hidden w-80 xl:inline-block" />
          </div>

          <div className="flex w-full justify-end gap-2 pb-2 pt-6">
            <Button
              type="button"
              className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
              onClick={handleCancelClick}
            >
              Back
            </Button>
          </div>
        </form>
      </LoadingPage>
    </FormProvider>
  )
}
