import React from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { motion } from "framer-motion"
import json2mq from "json2mq"
import { useForm } from "react-hook-form"
import { useMediaQuery } from "react-responsive"
import * as z from "zod"

import AuthLayout from "@/components/_layout/AuthLayout"
import Logo from "@/components/_layout/components/Logo"
import { LoadingButton } from "@/components/_uiext"
import usePasswordResetStore from "@/stores/usePasswordResetStore"
import { ANIMATION_DURATION } from "@/utils/constants"

import { Form, FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"

const FormSchema = z.object({
  email: z.string().email({
    message: "Please enter a valid email address"
  })
})

type FormSchemaType = z.infer<typeof FormSchema>

export default function PasswordReset() {
  const store = usePasswordResetStore()

  const isMobile = useMediaQuery({ query: json2mq({ maxWidth: 767 }) })

  const variants = {
    initial: {
      opacity: 0,
      y: 20
    },
    animate: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * ANIMATION_DURATION,
        duration: ANIMATION_DURATION
      }
    })
  }

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: ""
    }
  })

  const onSubmit = (values: FormSchemaType) => {
    store.forgotPasswordAction(values.email, () => {
      form.reset()
    })
  }

  return (
    <AuthLayout>
      <div className="flex h-full w-full flex-col gap-8">
        <motion.div
          custom={0}
          initial="initial"
          animate="animate"
          variants={variants}
          className="flex flex-wrap items-center justify-center gap-4 md:justify-between"
        >
          <Logo />
        </motion.div>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-1 flex-col justify-center"
          >
            <motion.h3
              custom={1}
              initial="initial"
              animate="animate"
              variants={variants}
              className="text-main mb-2.5 text-lg font-semibold"
            >
              Password reset
            </motion.h3>

            <motion.p
              custom={2}
              initial="initial"
              animate="animate"
              variants={variants}
              className="mb-8 text-sm font-normal"
            >
              Enter your email address below and we'll send you an email with guidance on how to
              reset your password. If you need any additional support - send us an email on{" "}
              <span className="text-main font-medium">{import.meta.env.VITE_SUPPORT_EMAIL}</span>
            </motion.p>

            <motion.div
              custom={3}
              initial="initial"
              animate="animate"
              variants={variants}
              className="mb-6 w-full"
            >
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-normal">Email address</FormLabel>
                    <Input autoFocus={!isMobile} autoComplete="off" type="email" {...field} />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </motion.div>

            <motion.div
              custom={4}
              initial="initial"
              animate="animate"
              variants={variants}
              className="w-full"
            >
              <LoadingButton type="submit" loading={store.loading}>
                Send password reset link
              </LoadingButton>
            </motion.div>
          </form>
        </Form>
      </div>
    </AuthLayout>
  )
}
