import qs from "qs"

import api from "./api"

// Get
export const apiGetApplicationsList = (params: any) =>
  api().get(`/applications?${qs.stringify(params)}`)
export const apiGetApplicationByUuid = (uuid: string) => api().get(`/applications/${uuid}`)

// Archive
export const apiArchiveApplication = (uuid: string) => api().patch(`/applications/${uuid}/archive`)

// Update
export const apiUpdateApplication = (uuid: string, params: any) =>
  api().putForm(`/applications/${uuid}`, params)

// Update indicative approval
export const apiUpdateApplicationIndicativeApproval = (uuid: string, params: any) =>
  api().putForm(`/applications/indicative-approval/${uuid}`, params)

// Update formal approval
export const apiUpdateApplicationFormalApproval = (uuid: string, params: any) =>
  api().putForm(`/applications/formal-approval/${uuid}`, params)
