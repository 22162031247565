import React, { Dispatch, SetStateAction, useState } from "react"

import clsx from "clsx"

import { IconFilter } from "@/components/_icons"
import { PopDlg, PopDlgContent, PopDlgTrigger } from "@/components/_uiext/PopDlg"
import { ITableParams } from "@/types/interface"

import { Button } from "@repo/ui/components/ui/button"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { ALL, USER_ROLES } from "@repo/util/constant"
import { capitalize } from "@/utils/formatter"

interface Props {
  tableParams: ITableParams
  setTableParams: Dispatch<SetStateAction<ITableParams>>
}

export default function FilterPopover({ tableParams, setTableParams }: Readonly<Props>) {
  const [open, setOpen] = useState(false)
  const [isArchived, setIsArchived] = useState(tableParams.filter.is_archived)
  const [isApproved, setIsApproved] = useState(tableParams.filter.is_approved)
  const [isLocked, setIsLocked] = useState(tableParams.filter.is_locked)
  const [fullName, setFullName] = useState(tableParams.filter.full_name)
  const [role, setRole] = useState(tableParams.filter.role)
  const [email, setEmail] = useState(tableParams.filter.email)
  const [mobileNumber, setMobileNumber] = useState(tableParams.filter.mobile_number)

  const handleResetClick = () => {
    setIsArchived("")
    setIsApproved("")
    setIsLocked("")
    setRole("")
    setFullName("")
    setEmail("")
    setMobileNumber("")

    setTableParams((prev: ITableParams) => ({
      ...prev,
      filter: {
        ...prev.filter,
        is_archived: "",
        is_approved: "",
        is_locked: "",
        role: "",
        full_name: "",
        email: "",
        mobile_number: ""
      },
      pagination: {
        ...prev.pagination,
        page_index: 1
      }
    }))
    setOpen(false)
  }

  const handleApplyClick = () => {
    setTableParams((prev: ITableParams) => ({
      ...prev,
      filter: {
        ...prev.filter,
        is_archived: isArchived,
        is_approved: isApproved,
        is_locked: isLocked,
        role,
        full_name: fullName,
        email,
        mobile_number: mobileNumber
      },
      pagination: {
        ...prev.pagination,
        page_index: 1
      }
    }))
    setOpen(false)
  }

  return (
    <PopDlg open={open} onOpenChange={setOpen}>
      <PopDlgTrigger asChild>
        <Button
          className={clsx(
            "text-default flex h-auto w-full items-center justify-center gap-1.5 rounded-xl border bg-transparent px-4 py-3 text-sm font-semibold hover:bg-transparent md:w-auto",
            open ? "border-primary" : "border-[#868194]"
          )}
        >
          <IconFilter className="text-xl" />
          Filter
        </Button>
      </PopDlgTrigger>

      <PopDlgContent align="end" className="w-full md:w-[400px]">
        <div className="flex flex-col justify-center gap-4">
          <p className="text-main text-sm">Filter</p>

          <Select value={isArchived} onValueChange={(value) => setIsArchived(value)}>
            <SelectTrigger
              className={clsx("px-4 py-1 text-sm", isArchived ? "text-main" : "text-default")}
            >
              <SelectValue placeholder="Active state" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem className="capitalize" value={ALL}>
                ({capitalize(ALL)})
              </SelectItem>
              <SelectItem value="false">Active</SelectItem>
              <SelectItem value="true">Archived</SelectItem>
            </SelectContent>
          </Select>

          <Select value={isApproved} onValueChange={(value) => setIsApproved(value)}>
            <SelectTrigger
              className={clsx("px-4 py-1 text-sm", isApproved ? "text-main" : "text-default")}
            >
              <SelectValue placeholder="Approve state" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem className="capitalize" value={ALL}>
                ({capitalize(ALL)})
              </SelectItem>
              <SelectItem value="true">Approved</SelectItem>
              <SelectItem value="false">Disapproved</SelectItem>
            </SelectContent>
          </Select>

          <Select value={isLocked} onValueChange={(value) => setIsLocked(value)}>
            <SelectTrigger
              className={clsx("px-4 py-1 text-sm", isLocked ? "text-main" : "text-default")}
            >
              <SelectValue placeholder="Lock state" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem className="capitalize" value={ALL}>
                ({capitalize(ALL)})
              </SelectItem>
              <SelectItem value="true">Locked</SelectItem>
              <SelectItem value="false">Unlocked</SelectItem>
            </SelectContent>
          </Select>

          <Select
            value={role}
            onValueChange={(value: string) => {
              setRole(value)
            }}
          >
            <SelectTrigger
              className={clsx("px-4 py-1 text-sm", role ? "text-main" : "text-default")}
            >
              <SelectValue placeholder="Account type" />
            </SelectTrigger>

            <SelectContent>
              <SelectItem className="capitalize" value={ALL}>
                ({capitalize(ALL)})
              </SelectItem>
              {[
                USER_ROLES.Employee,
                USER_ROLES.Introducer,
                USER_ROLES.Broker,
                USER_ROLES.Enterprise,
                USER_ROLES.Administrator
              ].map((r) => (
                <SelectItem key={r} className="capitalize" value={r}>
                  {capitalize(r)}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Input
            placeholder="Full name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />

          <Input
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            placeholder="Mobile number"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
          />

          <div className="flex justify-end gap-2.5">
            <Button
              className="text-default h-auto w-full border border-[#868194] bg-transparent px-6 py-4 text-sm font-semibold hover:bg-transparent md:w-auto"
              onClick={handleResetClick}
            >
              Reset
            </Button>
            <Button
              className="border-primary h-auto w-full border px-6 py-4 text-sm font-semibold md:w-auto"
              onClick={handleApplyClick}
            >
              Apply filter
            </Button>
          </div>
        </div>
      </PopDlgContent>
    </PopDlg>
  )
}
