import React, { forwardRef, useCallback, useEffect, useState } from "react"

import { useDropzone } from "react-dropzone"

import { IconUpload } from "../_icons"

import { Button } from "@repo/ui/components/ui/button"
import { Avatar, AvatarFallback, AvatarImage } from "@repo/ui/components/ui/avatar"

interface Props {
  readOnly?: boolean
  placeholder?: string
  name?: string
  value?: File | string | null
  onChange?: Function
}

function AvatarUpload(
  { name, value, onChange, readOnly, placeholder = "Avatar" }: Props,
  ref: any
) {
  const [file, setFile] = useState<File | string | null>(value ?? null)

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (typeof onChange === "function") {
        onChange(acceptedFiles[0])
      }

      setFile(acceptedFiles[0])
    },
    [file, onChange]
  )

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": []
    },
    maxSize: 5 * 1024 * 1024,
    maxFiles: 1,
    onDrop
  })

  useEffect(() => {
    setFile(value ?? null)
  }, [value])

  return (
    <div className="flex items-center gap-4">
      <input {...getInputProps()} />

      {!file && (
        <div
          className="flex h-16 w-16 cursor-pointer items-center justify-center rounded-full bg-[#C8C8C8]"
          {...(readOnly ? {} : getRootProps())}
        >
          <Avatar className="h-16 w-16">
            <AvatarImage src={""} alt={placeholder} />
            <AvatarFallback className="uppercase">{placeholder}</AvatarFallback>
          </Avatar>
        </div>
      )}

      {!!file && (
        <Avatar className="h-16 w-16" {...(readOnly ? {} : getRootProps())}>
          <AvatarImage
            src={typeof file === "object" ? URL.createObjectURL(file) : file}
            alt={placeholder}
            className="object-cover"
          />
          <AvatarFallback className="uppercase">{placeholder}</AvatarFallback>
        </Avatar>
      )}

      {!readOnly && (
        <Button
          type="button"
          className="text-default flex h-auto items-center gap-1.5 border border-[#868194] bg-white p-3 font-semibold hover:bg-transparent"
          {...(readOnly ? {} : getRootProps())}
        >
          <IconUpload />
          Upload image
        </Button>
      )}
    </div>
  )
}

export default forwardRef(AvatarUpload)
