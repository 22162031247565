import React, { Dispatch, SetStateAction } from "react"

import clsx from "clsx"
import json2mq from "json2mq"
import { useMediaQuery } from "react-responsive"

import { Dialog, DialogContent, DialogTrigger } from "@repo/ui/components/ui/dialog"
import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/components/ui/popover"

interface PopDlgProps {
  children: React.ReactNode
  open?: boolean
  onOpenChange?: Dispatch<SetStateAction<boolean>>
}

function PopDlg({ children, open = false, onOpenChange }: PopDlgProps) {
  const isMobile = useMediaQuery({ query: json2mq({ maxWidth: 767 }) })
  const Container = isMobile ? Dialog : Popover

  return (
    <Container open={open} onOpenChange={onOpenChange}>
      {children}
    </Container>
  )
}

interface PopDlgTriggerProps {
  children: React.ReactNode
  asChild?: boolean
  className?: string
}
function PopDlgTrigger({ children, asChild = false, className }: PopDlgTriggerProps) {
  const isMobile = useMediaQuery({ query: json2mq({ maxWidth: 767 }) })
  const Trigger = isMobile ? DialogTrigger : PopoverTrigger

  return (
    <Trigger asChild={asChild} className={className}>
      {children}
    </Trigger>
  )
}

interface PopDlgContentProps {
  children: React.ReactNode
  align?: "start" | "center" | "end"
  className?: string
}
function PopDlgContent({ children, align = "start", className }: PopDlgContentProps) {
  const isMobile = useMediaQuery({ query: json2mq({ maxWidth: 767 }) })

  if (isMobile) {
    return (
      <DialogContent className={clsx("h-screen bg-white", className)}>{children}</DialogContent>
    )
  }

  return (
    <PopoverContent align={align} className={clsx(className)}>
      {children}
    </PopoverContent>
  )
}

export { PopDlg, PopDlgTrigger, PopDlgContent }
