import React, { useEffect, useState } from "react"

import { Link, useParams } from "react-router-dom"

import UserLayout from "@/components/_layout/UserLayout"
import useBorrowerStore from "@/stores/useBorrowerStore"

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from "@repo/ui/components/ui/breadcrumb"

import MainForm from "./MainForm"

export default function BorrowersEdit() {
  const { uuid } = useParams()
  const store = useBorrowerStore()
  const [data, setData] = useState<any>({})

  useEffect(() => {
    store.getOneByUuidAction(uuid ?? "", (newData) => {
      setData(newData)
    })
  }, [uuid])

  return (
    <UserLayout
      breadcrumb={
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <Link to="/dashboard" className="text-default text-sm font-normal">
                Dashboard
              </Link>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <Link to="/borrowers/list" className="text-default text-sm font-normal">
                My borrowers
              </Link>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage className="text-main text-sm font-semibold">
                {`${data?.entity_name ?? ""}`}
              </BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      }
    >
      <div className="flex flex-col">
        <div className="mb-4 block w-full rounded-xl border border-[#ECECEC] bg-white p-8 lg:hidden">
          <h3 className="text-main text-lg font-semibold">Edit borrower</h3>
        </div>

        <MainForm data={data} />
      </div>
    </UserLayout>
  )
}
