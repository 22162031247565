import React from "react"

import { StyleSheet, Text, View } from "@react-pdf/renderer"

export default function Footer() {
  const styles = StyleSheet.create({
    footer: {
      left: 0,
      right: 0,
      bottom: 0,
      width: "92%",
      height: 48,
      paddingHorizontal: 0,
      paddingTop: 8,
      paddingBottom: 16,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderTop: "1px solid #09ECB3",
      marginTop: 32,
      marginHorizontal: "auto"
    }
  })

  return (
    <View style={styles.footer} fixed>
      <Text
        style={{
          color: "#0E052B",
          fontSize: 10,
          fontWeight: 400,
          textAlign: "right"
        }}
        fixed
      >
        Powered by <Text style={{ fontWeight: 600 }}>PFG Capital Pty Ltd</Text>
      </Text>

      <Text
        style={{
          color: "#0E052B",
          fontSize: 10,
          fontWeight: 400,
          textAlign: "right"
        }}
        render={({ pageNumber, totalPages }) => `${pageNumber - 1} / ${totalPages - 1}`}
        fixed
      />
    </View>
  )
}
