import React, { useEffect } from "react"

import { InputMask } from "@react-input/mask"
import { APIProvider } from "@vis.gl/react-google-maps"
import clsx from "clsx"
import { format, parse } from "date-fns"
import _ from "lodash"
import { useFormContext } from "react-hook-form"
import { extractPlace } from "@/utils/google"

import { EntityAutoComplete, PlacesAutoPredict } from "@/components/_uiext"
import ApiAusAbr from "@/api/ausAbrApi"
import { formatABN } from "@/utils/formatter"

import { FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { abnEntity2fcEntity, ENTITY_TYPES, TRUST_TYPES } from "@repo/util/constant"

interface Props {
  isView?: boolean
}

export default function EntityDetails(props: Readonly<Props>) {
  const { isView = false } = props
  const form = useFormContext()

  useEffect(() => {
    if (
      form.watch("entity_type") === ENTITY_TYPES.Company.key ||
      form.watch("entity_type") === ENTITY_TYPES.Trust.key ||
      form.watch("entity_type") === ENTITY_TYPES.SMSF.key
    ) {
      const abn: string = form.watch("abn")
      form.setValue("acn", abn?.substring(3))
    } else {
      form.setValue("acn", "")
    }
  }, [form.watch("abn"), form.watch("entity_type")])

  return (
    <div className="flex w-full flex-col">
      <h3 className="text-main mb-4 py-4 text-lg font-semibold">Entity details</h3>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="entity_type"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Entity type</FormLabel>
                <Select disabled={isView} onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger
                    className={clsx(
                      "h-12 w-full text-sm",
                      field.value ? "text-main" : "text-default"
                    )}
                  >
                    <SelectValue placeholder="Please select" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(ENTITY_TYPES).map(([k, v]) => (
                      <SelectItem key={k} value={v.key}>
                        {v.key}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="hidden flex-1 md:block" />
      </div>

      <div
        className={clsx(
          "mb-4 flex w-full flex-col gap-4 md:flex-row md:items-center md:gap-8",
          !form.watch("entity_type") && "hidden"
        )}
      >
        <div className="flex-1">
          <FormField
            control={form.control}
            name="temp_abn"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Entity name</FormLabel>
                <EntityAutoComplete
                  disabled={isView}
                  value={field.value}
                  nameValue={form.getValues("entity_name")}
                  onValueChange={async (v) => {
                    field.onChange(v.Abn)
                    form.setValue("entity_name", v.Name)
                    form.setValue("abn", formatABN(v.Abn))

                    const detail = await ApiAusAbr.getByAbn(v.Abn)

                    if (detail.Gst) {
                      const date = parse(detail.Gst, "yyyy-MM-dd", new Date())
                      const formattedDate = format(date, "'Registered from' dd MMM yyyy")
                      form.setValue("gst_registered", formattedDate)
                    } else {
                      form.setValue("gst_registered", "")
                    }

                    const newEntityType = abnEntity2fcEntity(_.startCase(detail?.EntityTypeName))
                    if (newEntityType !== null && form.watch("entity_type") !== newEntityType) {
                      form.setValue("entity_type", newEntityType)
                    }
                  }}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="gst_registered"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Goods & services tax (GST)
                </FormLabel>
                <Input disabled={isView} autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div
        className={clsx(
          "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
          !form.watch("entity_type") && "hidden"
        )}
      >
        <div className="flex-1">
          <FormField
            control={form.control}
            name="abn"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Australian business number (ABN)
                </FormLabel>
                <Input disabled={isView} autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <div
            className={clsx(
              form.watch("entity_type") === ENTITY_TYPES.Company.key ||
                form.watch("entity_type") === ENTITY_TYPES.Trust.key ||
                form.watch("entity_type") === ENTITY_TYPES.SMSF.key
                ? "block"
                : "hidden"
            )}
          >
            <FormField
              control={form.control}
              name="acn"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-main text-sm font-normal">
                    Australian company number (ACN)
                  </FormLabel>
                  <Input disabled={isView} autoComplete="off" {...field} />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
      </div>

      <div
        className={clsx(
          "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
          (!form.watch("entity_type") || form.watch("entity_type") !== ENTITY_TYPES.Trust.key) &&
            "hidden"
        )}
      >
        <div className="flex-1">
          <FormField
            control={form.control}
            name="trust_type"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Type of trust</FormLabel>
                <Select disabled={isView} onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger
                    className={clsx(
                      "h-12 w-full text-sm",
                      field.value ? "text-main" : "text-default"
                    )}
                  >
                    <SelectValue placeholder="Please select" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(TRUST_TYPES).map(([k, v]) => (
                      <SelectItem key={k} value={v.key}>
                        {v.key}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <div
            className={clsx(
              form.watch("trust_type") === TRUST_TYPES.Other.key ? "block" : "hidden"
            )}
          >
            <FormField
              control={form.control}
              name="trust_type_other"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-main text-sm font-normal">
                    Please enter your type of trust
                  </FormLabel>
                  <Input disabled={isView} autoComplete="off" {...field} />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
      </div>

      <div
        className={clsx(
          "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
          !form.watch("entity_type") && "hidden"
        )}
      >
        <div className="flex-1">
          <FormField
            control={form.control}
            name="principal_place"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Principal place of business (not a PO Box)
                </FormLabel>
                <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY ?? ""}>
                  <PlacesAutoPredict
                    disabled={isView}
                    textValue={field.value}
                    onPlaceSelect={(result: Record<string, any> | null) => {
                      if (result) {
                        const placeInfo = extractPlace(result)
                        field.onChange(placeInfo.formatted_address)
                      }
                    }}
                  />
                </APIProvider>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="registered_place"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Registered place of business
                </FormLabel>
                <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY ?? ""}>
                  <PlacesAutoPredict
                    disabled={isView}
                    textValue={field.value}
                    onPlaceSelect={(result: Record<string, any> | null) => {
                      if (result) {
                        const placeInfo = extractPlace(result)
                        field.onChange(placeInfo.formatted_address)
                      }
                    }}
                  />
                </APIProvider>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div
        className={clsx(
          "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
          !form.watch("entity_type") && "hidden"
        )}
      >
        <div className="flex-1">
          <FormField
            control={form.control}
            name="entity_email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Entity email address
                </FormLabel>
                <Input disabled={isView} type="email" autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex-1">
          <FormField
            control={form.control}
            name="entity_contact_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Entity phone number</FormLabel>
                <InputMask
                  disabled={isView}
                  showMask
                  component={Input}
                  mask="04__ ___ ___"
                  placeholder="04__ ___ ___"
                  replacement={{ _: /\d/ }}
                  ref={field.ref}
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div
        className={clsx(
          "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
          !form.watch("entity_type") && "hidden"
        )}
      >
        <div className="flex-1">
          <FormField
            control={form.control}
            name="primary_business_activity"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Primary business activity
                </FormLabel>
                <Input disabled={isView} autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex-1">
          <FormField
            control={form.control}
            name="website"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Website</FormLabel>
                <Input
                  disabled={isView}
                  type="url"
                  autoComplete="off"
                  {...field}
                  value={field.value ?? ""}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  )
}
