export const SITE_TITLE = "FundsConnect"

/* ---------------------------------------------------------------- */

export const ALL = "all"

export const YN = {
  Yes: "Yes",
  No: "No"
}

export const TITLES = {
  Mr: "Mr",
  Mrs: "Mrs",
  Miss: "Miss",
  Ms: "Ms",
  Dr: "Dr",
  Other: "Other"
}

export const GENDERS = {
  Male: "Male",
  Female: "Female",
  NonBinary: "Non-binary"
}

export const MARITAL_STATUSES = {
  Single: "Single",
  Married: "Married",
  Defacto: "De facto",
  Divorced: "Divorced",
  Widowed: "Widowed",
  Other: "Other"
}

export const RESIDENTIAL_STATUSES = {
  Owner: "Owner",
  Buying: "Buying",
  Boarding: "Boarding",
  Renting: "Renting",
  WithParents: "With parents"
}

/* ---------------------------------------------------------------- */

export const CRON_JOB_TYPES = {
  ENTITY_MEMBERSHIP_EXPIRY: "Entity membership expiry",
  ENTITY_ACL_EXPIRY: "Entity ACL expiry",
  ENTITY_PII_EXPIRY: "Entity PII expiry",

  BUSINESS_USER_MEMBERSHIP_EXPIRY: "Business user membership expiry",
  BUSINESS_USER_ACR_EXPIRY: "Business user ACR expiry",
  BUSINESS_USER_PII_EXPIRY: "Business user PII expiry",

  APPLICATION_INVISIBLE_DELETE: "Application invisible delete"
}

/* ---------------------------------------------------------------- */

export const NOTIFICATION_TYPES = {
  APPLICATION: "Application",
  IntroducerLead: "Introducer lead"
}

export const APPLICATION_STEPS = {
  BorrowerSelection: "Borrower selection",
  FundingSolution: "Funding solution",
  LoanType: "Loan type",
  LoanDetails: "Loan details",
  SecurityDetails: "Security details",
  LenderSelection: "Lender selection",
  EntityAL: "Entity's A & L",
  IndividualAL: "Individual's A & L",
  SupportingDocuments: "Supporting documents",
  ReviewApplication: "Review application"
}

export const USER_ROLES = {
  Employee: "employee",
  Broker: "broker",
  Introducer: "introducer",
  Enterprise: "enterprise",
  Administrator: "administrator"
}

export const BUSINESS_ROLE = {
  Manager: "manager",
  Employee: "employee"
}

export const ENTITY_TYPES = {
  Company: {
    key: "Company",
    description:
      "This is just for company text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    color: {
      text: "#7459BB",
      background: "#7459BB26",
      graph: "#7459BB"
    }
  },
  Trust: {
    key: "Trust (with corporate trustee only)",
    description:
      "This is just for trust text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    color: {
      text: "#5FCB6A",
      background: "#5FCB6A26",
      graph: "#5FCB6A"
    }
  },
  SMSF: {
    key: "SMSF (with corporate trustee only)",
    description:
      "This is just for SMSF text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    color: {
      text: "#4B8CEE",
      background: "#4B8CEE26",
      graph: "#4B8CEE"
    }
  },

  Partnership: {
    key: "Partnership",
    description:
      "This is just for partnership text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    color: {
      text: "#52DAE3",
      background: "#52DAE326",
      graph: "#52DAE3"
    }
  },
  SoleTrader: {
    key: "Sole trader",
    description:
      "This is just for sole trader text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    color: {
      text: "#D366E4",
      background: "#D366E426",
      graph: "#D366E4"
    }
  },

  Individual: {
    key: "Individual",
    description:
      "This is just for individual text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    color: {
      text: "#48E3A4",
      background: "#48E3A426",
      graph: "#48E3A4"
    }
  }
}

export const TRUST_TYPES = {
  Family: {
    key: "Family"
  },
  Discretionary: {
    key: "Discretionary"
  },
  Testamentary: {
    key: "Testamentary"
  },
  UnitTrust: {
    key: "Unit trust"
  },
  Other: {
    key: "Other"
  }
}

export const MAX_BORROWER_INDIVIDUAL_COUNT = 100

export const MAX_LENDER_CONTACT_COUNT = 100

export const BORROWER_INDIVIDUAL_ROLES = {
  Director: {
    key: "director",
    entity_types: [ENTITY_TYPES.Company.key, ENTITY_TYPES.Trust.key, ENTITY_TYPES.SMSF.key]
  },
  Guarantor: {
    key: "guarantor",
    entity_types: [ENTITY_TYPES.Company.key, ENTITY_TYPES.Trust.key, ENTITY_TYPES.SMSF.key]
  },
  Shareholder: {
    key: "shareholder",
    entity_types: [ENTITY_TYPES.Company.key, ENTITY_TYPES.Trust.key, ENTITY_TYPES.SMSF.key]
  },
  Beneficiary: {
    key: "beneficiary",
    entity_types: [ENTITY_TYPES.Trust.key]
  },
  Member: {
    key: "member",
    entity_types: [ENTITY_TYPES.SMSF.key]
  }
}

/* ---------------------------------------------------------------- */
export const APPLICATION_STATUS = {
  Incomplete: {
    key: "Incomplete",
    color: {
      text: "#F59E0B",
      background: "#F59E0B26"
    }
  },
  Received: {
    key: "Received",
    color: {
      text: "#379FFF",
      background: "#379FFF26"
    }
  },
  Credit: {
    key: "Credit",
    color: {
      text: "#8BCCEE",
      background: "#8BCCEE26"
    }
  },
  FurtherInformationRequestedFromCredit: {
    key: "Further information requested from credit",
    color: {
      text: "#000000",
      background: "#00000026"
    }
  },
  Approved: {
    key: "Approved",
    color: {
      text: "#F59E0B",
      background: "#F59E0B26"
    }
  },
  Declined: {
    key: "Declined",
    color: {
      text: "#EF4444",
      background: "#EF444426"
    }
  },
  Withdrawn: {
    key: "Withdrawn",
    color: {
      text: "#483CD0",
      background: "#483CD026"
    }
  },
  Settled: {
    key: "Settled",
    color: {
      text: "#10B981",
      background: "#10B98126"
    }
  },
  Indicative: {
    key: "Indicative",
    color: {
      text: "#E5B24E",
      background: "#E5B24E26"
    }
  },
  IndicativeSigned: {
    key: "Indicative signed",
    color: {
      text: "#E5B24E",
      background: "#E5B24E26"
    }
  },
  Formal: {
    key: "Formal",
    color: {
      text: "#C10BC5",
      background: "#C10BC526"
    }
  },
  FormalSigned: {
    key: "Formal signed",
    color: {
      text: "#C10BC5",
      background: "#C10BC526"
    }
  }
}

/* ---------------------------------------------------------------- */

export const AGGREGATORS = {
  LoanMarketGroup: {
    key: "Loan market group (LMG)"
  },
  AustralianFinanceGroup: {
    key: "Australian finance group (AFG)"
  },
  Finsure: {
    key: "Finsure"
  },
  Connective: {
    key: "Connective"
  },
  SpecialistFinanceGroup: {
    key: "Specialist finance group (SFG)"
  },
  VowFinancial: {
    key: "Vow financial"
  },
  NationalMortgageBrokers: {
    key: "National mortgage brokers (nMB)"
  },
  OutsourceFinancial: {
    key: "Outsource financial"
  },
  MoneyQuest: {
    key: "Money quest"
  },
  Other: {
    key: "Other"
  }
}

export const ENTITY_INDUSTRIES = [
  "Mining and resources",
  "Agriculture",
  "Finance and insurance",
  "Healthcare and social assistance",
  "Education",
  "Tourism",
  "Construction",
  "Manufacturing",
  "Technology and innovation",
  "Retail trade"
]

export const FUNDING_SOLUTIONS = {
  ConstructionFinance: {
    key: "Construction finance",
    description:
      "Finances building projects, secured by the project’s underlying property value. The full capital stack may apply for complex developments."
  },
  AgriculturalBusinessFinance: {
    key: "Agri business finance",
    description:
      "Funding for farming operations, including land, equipment, livestock, and working capital. Secured by agricultural assets like property and commodities."
  },
  CashflowFinance: {
    key: "Cashflow finance",
    description:
      "Provides liquidity by advancing funds against invoices or projected sales. Includes debtor, trade, and supply chain finance, often without real estate security."
  },
  CorporateFinance: {
    key: "Corporate finance",
    description:
      "Funding for acquisitions or growth, secured by property, assets, or cash flow. A flexible solution utilising the full capital stack where needed."
  },
  PreDevelopmentFinance: {
    key: "Pre-development finance",
    description:
      "Covers pre-construction costs, secured by land or other property. Helps developers reduce upfront capital commitments."
  },
  PropertyFinance: {
    key: "Property finance",
    description:
      "Funding for property purchases or refinancing, secured by residential, commercial, industrial, land, or specialised property."
  },
  SMEFinance: {
    key: "SME finance",
    description:
      "Provides capital for SMEs to grow, acquire equipment, or manage costs, bypassing traditional bank lending hurdles."
  },
  SMSFFinance: {
    key: "SMSF finance",
    description:
      "Enables SMSFs to purchase or refinance residential or commercial property, typically under a LRB arrangement."
  }
}

/* ---------------------------------------------------------------- */

export const LOAN_TYPES = {
  // Construction
  SeniorDebt: {
    funding_solutions: [FUNDING_SOLUTIONS.ConstructionFinance.key],
    key: "Senior debt",
    description: "Funding to secure primary debt with first claim on project assets."
  },
  StretchedSeniorDebt: {
    funding_solutions: [FUNDING_SOLUTIONS.ConstructionFinance.key],
    key: "Stretched senior debt",
    description: "Funding that provides an up lift in the LVR of Senior debt."
  },
  MezzanineDebt: {
    funding_solutions: [FUNDING_SOLUTIONS.ConstructionFinance.key],
    key: "Mezzanine debt",
    description: "Funding to secure “Junior Debt” that bridges equity and senior loans."
  },
  PreferredEquity: {
    funding_solutions: [FUNDING_SOLUTIONS.ConstructionFinance.key],
    key: "Preferred equity",
    description: "Funding as equity-like capital with preference in payouts."
  },
  JointVenture: {
    funding_solutions: [FUNDING_SOLUTIONS.ConstructionFinance.key],
    key: "Joint venture",
    description: "Funding for co-investment partnerships in shared project ownership."
  },

  // Cashflow
  DebtorFinance: {
    funding_solutions: [FUNDING_SOLUTIONS.CashflowFinance.key],
    key: "Debtor finance",
    description: "Funding to leverage unpaid invoices to secure immediate funds."
  },
  TradeFinance: {
    funding_solutions: [FUNDING_SOLUTIONS.CashflowFinance.key],
    key: "Trade finance",
    description: "Funding for international and domestic trade transactions."
  },
  SupplyChainFinance: {
    funding_solutions: [FUNDING_SOLUTIONS.CashflowFinance.key],
    key: "Supply chain finance",
    description: "Funding to support supply chain operational cash flow."
  },

  // PreDevelopment
  SubDividedLandLots: {
    funding_solutions: [FUNDING_SOLUTIONS.PreDevelopmentFinance.key],
    key: "Sub divided land lots",
    description: "Funding for land lots ready for immediate construction."
  },
  VacantLand: {
    funding_solutions: [FUNDING_SOLUTIONS.PreDevelopmentFinance.key],
    key: "Vacant land (Englobo)",
    description: "Funding for undeveloped, large parcels of land."
  },
  ZonedLand: {
    funding_solutions: [FUNDING_SOLUTIONS.PreDevelopmentFinance.key],
    key: "Zoned land (DA approved)",
    description: "Funding for land with development approval."
  },

  // Agriculture business
  GrowthCapital: {
    funding_solutions: [FUNDING_SOLUTIONS.AgriculturalBusinessFinance.key],
    key: "Growth capital (incl. CapEx)",
    description: "Funding to expand agribusiness operations."
  },
  WorkingCapital: {
    funding_solutions: [FUNDING_SOLUTIONS.AgriculturalBusinessFinance.key],
    key: "Working capital",
    description: "Funding for daily business expenses."
  },
  SeasonalFluctuations: {
    funding_solutions: [FUNDING_SOLUTIONS.AgriculturalBusinessFinance.key],
    key: "Seasonal fluctuations",
    description: "Funding to manage cash flow in off-peak seasons."
  },
  InventoryStockManagement: {
    funding_solutions: [FUNDING_SOLUTIONS.AgriculturalBusinessFinance.key],
    key: "Inventory & stock management",
    description: "Funding for stock purchases."
  },

  // Corporate finance
  GrowthCapital2: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Growth capital",
    description: "Funding for scaling corporate operations."
  },
  MergersAcquisitions: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Mergers & acquisitions",
    description: "Funding for business purchases or mergers."
  },
  AssetBasedFinance: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Asset based finance",
    description: "Funding secured using business assets."
  },
  ShareBuyBack: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Share buy-back",
    description: "Funding to repurchase corporate shares."
  },
  WholesaleWarehousingFunding: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Wholesale/warehousing funding",
    description: "Funding for large stock purchases."
  },
  TurnaroundFinance: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Turnaround finance",
    description: "Funding to stabilise struggling businesses."
  },
  DebtRestructure: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Debt restructure",
    description: "Funding to adjust debt terms for improved financial health."
  },
  CashflowBasedFinance: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Cashflow based finance",
    description: "Funding based on company cash flow."
  },
  LargeScaleInfrastructureProjectFinance: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Large scale infrastructure & project finance",
    description: "Funding for major projects."
  },
  OwnershipSuccessionBuyouts: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Ownership succession & buyouts",
    description: "Funding for ownership transitions."
  },
  PortfolioAcquisition: {
    funding_solutions: [FUNDING_SOLUTIONS.CorporateFinance.key],
    key: "Portfolio acquisition",
    description: "Funding for purchasing asset portfolios."
  },

  // Property
  Refinance: {
    funding_solutions: [FUNDING_SOLUTIONS.PropertyFinance.key],
    key: "Refinance",
    description: "Funding to restructure existing property loans for better terms."
  },
  Purchase: {
    funding_solutions: [FUNDING_SOLUTIONS.PropertyFinance.key],
    key: "Purchase",
    description: "Funding to acquire residential or commercial property assets."
  },
  EquityRelease: {
    funding_solutions: [FUNDING_SOLUTIONS.PropertyFinance.key],
    key: "Equity release",
    description: "Funding to unlock property equity for investment or cash flow."
  },
  BridgingLoan: {
    funding_solutions: [FUNDING_SOLUTIONS.PropertyFinance.key],
    key: "Bridging loan",
    description: "Funding for short-term needs to cover property transaction gaps."
  },
  SecondMortgage: {
    funding_solutions: [FUNDING_SOLUTIONS.PropertyFinance.key],
    key: "2nd mortgage",
    description: "Funding as an additional 2nd loan secured against existing property."
  },
  ResidualStock: {
    funding_solutions: [FUNDING_SOLUTIONS.PropertyFinance.key],
    key: "Residual stock",
    description: "Funding for unsold units in completed developments."
  },

  // SME
  Refinance2: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Refinance",
    description: "Funding to optimise debt for cash flow improvements."
  },
  WorkingCapital2: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Working capital",
    description: "Funding for daily business expenses."
  },
  InventoryStockManagement2: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Inventory & stock management",
    description: "Funding for stock purchases."
  },
  Acquisition: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Acquisition",
    description: "Funding for business acquisitions."
  },
  EquityRelease2: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Equity release",
    description: "Funding to unlock business equity for growth."
  },
  Bridging: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Bridging",
    description: "Funding for short-term immediate capital needs."
  },
  TaxDebts: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Tax debts",
    description: "Funding to settle tax obligations."
  },
  ExpansionGrowth: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Expansion & growth",
    description: "Funding to scale business operations."
  },
  TechnologyUpgrades: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Technology upgrades",
    description: "Funding for tech investments to enhance efficiency."
  },
  HumanResourcing: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Human resourcing",
    description: "Funding for hiring and payroll."
  },
  AdvertisingMarketing: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Advertising & marketing",
    description: "Funding for marketing  and promotional activities."
  },
  RenovationsUpgrades: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Renovations & upgrades",
    description: "Funding for physical improvements."
  },
  DebtConsolidation: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Debt consolidation",
    description: "Funding to combine and restructure business debts."
  },
  RestoreCreditRating: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Restore credit rating",
    description: "Funding to improve credit standings."
  },
  SeasonalFluctuations2: {
    funding_solutions: [FUNDING_SOLUTIONS.SMEFinance.key],
    key: "Seasonal fluctuations",
    description: "Funding to manage cash flow in off-peak seasons."
  },

  // SMSF
  Refinance3: {
    funding_solutions: [FUNDING_SOLUTIONS.SMSFFinance.key],
    key: "Refinance",
    description: "Funding to restructure SMSF loan terms."
  },
  Purchase3: {
    funding_solutions: [FUNDING_SOLUTIONS.SMSFFinance.key],
    key: "Purchase",
    description: "Funding for SMSF property acquisitions."
  }
}

/* ---------------------------------------------------------------- */

export const LOAN_DOCUMENT_TYPES = {
  FullDoc: {
    key: "Full doc",
    description:
      "This is just for full doc text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    enabled: false
  },
  LowDoc: {
    key: "Low doc",
    description:
      "This is just for low doc text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    enabled: false
  },
  AltDoc: {
    key: "Alt doc",
    description:
      "This is just for alt doc text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    enabled: false
  },
  LeaseDoc: {
    key: "Lease doc",
    description:
      "This is just for lease doc text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    enabled: false
  },
  PrivateCredit: {
    key: "Private credit",
    description:
      "This is just for private credit text. Don’t be alarmed, this is just here to fill up space since your finalized copy isn’t ready yet.",
    enabled: true
  }
}

/* ---------------------------------------------------------------- */
// For construction finance
export const EXIT_STRATEGIES = {
  Refinance: "Refinance",
  SaleOfAsset: "Sale of asset",
  Other: "Other"
}

/* ---------------------------------------------------------------- */
// For agricultural business finance
export const AGRICULTURAL_COMMODITY_TYPES = {
  Dairy: "Dairy",
  Horticulture: "Horticulture",
  Protein: "Protein",
  Cropping: "Cropping",
  Forestry: "Forestry",
  FoodProcessing: "Food processing",
  Fibre: "Fibre"
}
/* ---------------------------------------------------------------- */
// For cashflow finance
export const CASHFLOW_TURNOVER_RANGES = [
  {
    key: 1,
    min: 1000000,
    max: 2000000
  },
  {
    key: 2,
    min: 2000001,
    max: 3000000
  },
  {
    key: 3,
    min: 3000001,
    max: 4000000
  },
  {
    key: 4,
    min: 4000001,
    max: 5000000
  },
  {
    key: 5,
    min: 5000001,
    max: 10000000
  },
  {
    key: 6,
    min: 10000001,
    max: 20000000
  },
  {
    key: 7,
    min: 20000001,
    max: 0
  }
]

export const CASHFLOW_INDUSTRY_SECTORS = {
  ImportWholesale: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Import & wholesale"
  },
  LabourHire: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Labour hire"
  },
  TrafficManagement: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Traffic management"
  },
  Manufacturing: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Manufacturing"
  },
  Transport: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Transport"
  },
  Printing: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Printing"
  },
  MiningServices: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Mining services"
  },
  FoodBeverage: {
    loan_types: [LOAN_TYPES.DebtorFinance.key, LOAN_TYPES.SupplyChainFinance.key],
    key: "Food & beverage"
  },
  Pharmaceuticals: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Pharmaceuticals"
  },
  Healthcare: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Healthcare"
  },
  ITServices: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "IT services"
  },
  MediaTelecommunications: {
    loan_types: [LOAN_TYPES.DebtorFinance.key],
    key: "Media & telecommunications"
  },

  Agriculture: {
    loan_types: [LOAN_TYPES.TradeFinance.key],
    key: "Agriculture (food products & raw materials)"
  },
  ImportExport: {
    loan_types: [LOAN_TYPES.TradeFinance.key],
    key: "Import/export - importers & exporters"
  },
  Construction: {
    loan_types: [LOAN_TYPES.TradeFinance.key],
    key: "Construction (building materials & heavy machinery)"
  },
  Retailers: {
    loan_types: [LOAN_TYPES.TradeFinance.key],
    key: "Retailers (apparel, electronics, furniture etc)"
  },
  Pharmaceutical: {
    loan_types: [LOAN_TYPES.TradeFinance.key],
    key: "Pharmaceutical (medical supplies; drugs & medicines)"
  },
  Manufacturers: {
    loan_types: [LOAN_TYPES.TradeFinance.key],
    key: "Manufacturers (industrial equipment, consumer goods & automotive parts)"
  },
  EnergyCompanies: {
    loan_types: [LOAN_TYPES.TradeFinance.key],
    key: "Energy companies (oil & gas & renewable energy components)"
  },
  Wholesalers: {
    loan_types: [LOAN_TYPES.TradeFinance.key],
    key: "Wholesalers (bulk products & intermediate goods)"
  },

  Agriculture3: {
    loan_types: [LOAN_TYPES.SupplyChainFinance.key],
    key: "Agriculture"
  },
  Automotive: {
    loan_types: [LOAN_TYPES.SupplyChainFinance.key],
    key: "Automotive"
  },
  Construction3: {
    loan_types: [LOAN_TYPES.SupplyChainFinance.key],
    key: "Construction"
  },
  ElectronicsTechnology: {
    loan_types: [LOAN_TYPES.SupplyChainFinance.key],
    key: "Electronics & technology"
  },
  TextileApparel: {
    loan_types: [LOAN_TYPES.SupplyChainFinance.key],
    key: "Textile & apparel"
  },
  FurnitureHomeGoods: {
    loan_types: [LOAN_TYPES.SupplyChainFinance.key],
    key: "Furniture & home goods"
  },
  Retail: {
    loan_types: [LOAN_TYPES.SupplyChainFinance.key],
    key: "Retail"
  },
  Manufacturers3: {
    loan_types: [LOAN_TYPES.SupplyChainFinance.key],
    key: "Manufacturers"
  }
}
/* ---------------------------------------------------------------- */
// For corporate finance
export const CORPORATE_INDUSTRY_SECTORS = {
  ManufacturingIndustrial:
    "Manufacturing & industrial (automotive; aerospace & defence; heavy machinery; textiles; chemicals & plastics)",
  EnergyUtilities: "Energy & utilities (oil & gas; renewable energy; utilities & mining)",
  ConstructionRealEstate:
    "Construction & real estate (large scale commercial & residential real estate projects)",
  TransportationLogistics:
    "Transportation & logistics (shipping & maritime; aviation; rail & public transport; trucking & freight)",
  TechnologyTelecommunications:
    "Technology & telecommunications (IT; telco providers; ISP and data centres)",
  HealthcarePharmaceuticals:
    "Healthcare & pharmaceuticals (healthcare providers; pharmaceuticals; medical devices & biotechnology)",
  RetailConsumerGoods:
    "Retail & consumer goods (retail chains; consumer goods & food and beverage)",
  FinancialServicesInsurance:
    "Financial services & insurance (private equity & venture capital for growth, expansion and M&A's)",
  HospitalityLeisure:
    "Hospitality & leisure (hotels & resorts; travel & tourism; restaurants & food service)",
  AgricultureAgribusiness:
    "Agriculture & agribusiness (farming & livestock; food processing; AgTech)",
  MediaEntertainment:
    "Media & entertainment (film & television production; music industry; publishing & print media)",
  Education: "Education (private educational institutions; universities & EdTech)",
  EnvironmentalSustainableIndustries:
    "Environmental & sustainable industries (sustainable agriculture; recycling & waste management & green technology)",
  PublicSectorInfrastructure:
    "Public sector & infrastructure (government contract & public infrastructure projects)",
  NonProfitSocialEnterprises:
    "Non-profit & social enterprises (non-profit organisations & social impact ventures)"
}
/* ---------------------------------------------------------------- */
// For pre-development finance
export const DEVELOPMENT_LOAN_PURPOSES = {
  Purchase: "Purchase",
  Refinance: "Refinance",
  EquityRelease: "Equity release"
}

export const DEVELOPMENT_SECURITY_TYPES = {
  CommercialLandLots: {
    loan_types: [LOAN_TYPES.SubDividedLandLots.key],
    key: "Commercial land lots"
  },
  ResidentialLandLots: {
    loan_types: [LOAN_TYPES.SubDividedLandLots.key],
    key: "Residential land lots"
  },
  IndustrialLandLots: {
    loan_types: [LOAN_TYPES.SubDividedLandLots.key],
    key: "Industrial land lots"
  },
  RuralLandLots: {
    loan_types: [LOAN_TYPES.SubDividedLandLots.key],
    key: "Rural land lots (excluding agricultural land)"
  },

  VacantCommercialLand: {
    loan_types: [LOAN_TYPES.VacantLand.key],
    key: "Vacant commercial land"
  },
  VacantResidentialLand: {
    loan_types: [LOAN_TYPES.VacantLand.key],
    key: "Vacant residential land"
  },
  VacantIndustrialLand: {
    loan_types: [LOAN_TYPES.VacantLand.key],
    key: "Vacant industrial land"
  },
  VacantRuralLand: {
    loan_types: [LOAN_TYPES.VacantLand.key],
    key: "Vacant rural land (excluding agricultural land)"
  },

  ZonedCommercialLand: {
    loan_types: [LOAN_TYPES.ZonedLand.key],
    key: "Zoned commercial land"
  },
  ZonedResidentialLand: {
    loan_types: [LOAN_TYPES.ZonedLand.key],
    key: "Zoned residential land"
  },
  ZonedIndustrialLand: {
    loan_types: [LOAN_TYPES.ZonedLand.key],
    key: "Zoned industrial land"
  },
  ZonedRuralLand: {
    loan_types: [LOAN_TYPES.ZonedLand.key],
    key: "Zoned rural land (excluding agricultural land)"
  }
}
/* ---------------------------------------------------------------- */
// For construction & property & sme & smsf
export const COMMON_SECURITY_TYPES = {
  ResidentialProperty: "Residential property",
  CommercialProperty: "Commercial property",
  IndustrialProperty: "Industrial property",
  LandProperty: "Land property",
  MixedUseProperty: "Mixed use property",
  RetailProperty: "Retail property",
  SpecialisedProperty: "Specialised property"
}

export const COMMON_SUB_SECURITY_TYPES = [
  {
    keys: [
      "Apartment/unit",
      "Duplex",
      "House",
      "Luxury residential",
      "Multi-residential on one title",
      "Quadplex",
      "Semi-detached homes",
      "Terrace",
      "Townhouse",
      "Triplex",
      "Villa"
    ],
    security_type: COMMON_SECURITY_TYPES.ResidentialProperty
  },
  {
    keys: [
      "A grade office (modern, high-quality buildings in prime business districts)",
      "B grade office (10–20 yrs old, mid-rise well-maintained buildings in secondary locations)",
      "C grade office (> 20 yrs old, older office blocks in suburban or lower-demand areas)",
      "D grade office (30+ yrs old, run-down buildings in declining or low-traffic areas)",
      "Offices (strata)",
      "Professional suites"
    ],
    security_type: COMMON_SECURITY_TYPES.CommercialProperty
  },
  {
    keys: [
      "Cold storage facilities",
      "Data centres",
      "Factory units",
      "Heavy industrial",
      "Light industrial",
      "Logistics centres",
      "Manufacturing plants",
      "Mining and resources facilities",
      "Panel beaters/smash repair workshops",
      "Research & development (R&D) facilities",
      "Warehouses",
      "Waste management facilities"
    ],
    security_type: COMMON_SECURITY_TYPES.IndustrialProperty
  },
  {
    keys: [
      "Agricultural land (with enterprise)",
      "Agricultural land (without enterprise)",
      "Commercial land lots",
      "Industrial land lots",
      "Residential land lots",
      "Rural land (Englobo/Greenfields)",
      "Rural land lots (excluding agricultural land)",
      "Zoned commercial land",
      "Zoned industrial land",
      "Zoned residential land",
      "Zoned rural land (excluding agricultural land)"
    ],
    security_type: COMMON_SECURITY_TYPES.LandProperty
  },
  {
    keys: [
      "Residential and offices",
      "Residential and retail",
      "Residential, retail, and offices",
      "Retail and offices",
      "Transit-oriented developments"
    ],
    security_type: COMMON_SECURITY_TYPES.MixedUseProperty
  },
  {
    keys: [
      "Fast food outlet (Franchise)",
      "Standalone retail shop",
      "Shopping centres (Metropolitan)",
      "Shopping centres (Regional)",
      "Show room (e.g. kitchen display)",
      "Strip shops (neighbourhood)"
    ],
    security_type: COMMON_SECURITY_TYPES.RetailProperty
  },
  {
    keys: [
      "Aged care facilities",
      "Backpackers",
      "Boarding houses",
      "Car parking facilities",
      "Car wash",
      "Car yards",
      "Caravan & camping parks",
      "Childcare centres",
      "Cinemas, theatres & performance spaces",
      "Educational institutions",
      "Funeral parlors",
      "Gated communities",
      "Golf courses",
      "Gyms and fitness centres",
      "Healthcare facilities",
      "Hospitals (public & private)",
      "Hostels",
      "Hotels and resorts",
      "Hydropower plants",
      "Land lease communities",
      "Leisure and entertainment",
      "Libraries and archives",
      "Licensed clubs",
      "Life science",
      "Manufactured home estates",
      "Medical centres/clinics",
      "Motels",
      "Museums and galleries",
      "Nightclubs and bars",
      "Nurseries (plant)",
      "Nursing homes",
      "Private clubs",
      "Pubs",
      "Reception centres",
      "Rehabilitation centres",
      "Religious buildings",
      "Restaurants & cafes",
      "Self-storage",
      "Service stations",
      "Solar farms",
      "Spas and wellness retreats",
      "Sporting centres",
      "Student accommodation",
      "Truck stops",
      "Vineyard estates",
      "Wind farms"
    ],
    security_type: COMMON_SECURITY_TYPES.SpecialisedProperty
  }
]
/* ---------------------------------------------------------------- */
export const LOAN_TERMS = {
  M1: {
    key: 1,
    text: "1 month"
  },
  M2: {
    key: 2,
    text: "2 months"
  },
  M3: {
    key: 3,
    text: "3 months"
  },
  M6: {
    key: 6,
    text: "6 months"
  },
  M12: {
    key: 12,
    text: "12 months"
  },
  M18: {
    key: 18,
    text: "18 months"
  },
  M24: {
    key: 24,
    text: "24 months"
  },
  M30: {
    key: 30,
    text: "30 months"
  },
  M36: {
    key: 36,
    text: "36 months"
  },
  M48: {
    key: 48,
    text: "48 months"
  },
  M60: {
    key: 60,
    text: "60 months"
  },
  M300: {
    key: 300,
    text: "25 years"
  },
  M360: {
    key: 360,
    text: "30 years"
  }
}

export const REPAYMENTS = {
  CapitalisedInterest: "Capitalised interest (into the facility)",
  InterestOnly: "Interest only (paid monthly)",
  PrepaidInterest: "Prepaid interest (from funds advanced)"
}

export const REGIONS = {
  CapitalCities: "Capital cities",
  Metropolitan: "Metropolitan - within 15 km radius of capital city centre",
  MajorRegional: "Major regional - population > 20,000"
}

export const COUNTRIES = {
  Australia: "Australia",
  NewZealand: "New Zealand"
}

export const AUSTRALIA_STATES = [
  "New South Wales",
  "Queensland",
  "South Australia",
  "Tasmania",
  "Victoria",
  "Western Australia",
  "Australian Capital Territory",
  "Northern Territory"
]

export const NEWZEALAND_STATES = ["North Island", "South Island"]

export const CHECK_ITEM_TYPES = {
  YesNo: "Yes/No",
  Input: "Input",
  Upload: "Upload"
}

/* ---------------------------------------------------------------- */

export const APPLICATION_ASSET_TYPES = {
  Entity: "entity",
  Individual: "individual"
}

export const APPLICATION_LIABILITY_TYPES = {
  Entity: "entity",
  Individual: "individual"
}

export const ASSETS_TYPES = {
  RealEstate: "Real estate",
  Cash: "Cash",
  Stocks: "Stocks",
  Bonds: "Bonds",
  Vehicles: "Vehicles",
  Jewelry: "Jewelry",
  Livestock: "Livestock"
}

export const LIABILITIES_TYPES = {
  Mortgage: "Mortgage",
  Loans: "Loans",
  Overdrafts: "Overdrafts",
  Alimony: "Alimony"
}

/* ---------------------------------------------------------------- */

export const MEMBERSHIP_ASSOCIATIONS = ["CAFBA", "FBAA", "MFAA", "Other"]

/* ---------------------------------------------------------------- */

export const BANK_BRANCHES = [
  "Commonwealth bank of australia (CBA)",
  "Westpac banking corporation",
  "National australia bank (NAB)",
  "Australia and New Zealand banking group (ANZ)",
  "Bendigo and Adelaide bank",
  "Macquarie bank",
  "St. George bank",
  "Bank of Queensland",
  "Suncorp bank",
  "Other"
]

/* ---------------------------------------------------------------- */

export const DOCUMENT_TYPES = {
  Primary: "primary",
  Secondary: "secondary"
}

/* ---------------------------------------------------------------- */

export const PRIVY_USER_LEVEL = {
  Bronze: "bronze",
  Silver: "silver",
  Gold: "gold"
}

export const PRIVY_STATUS = {
  Pending: "pending",
  WaitingVerification: "waiting_verification",
  Verified: "verified",
  Rejected: "rejected"
}

/* ---------------------------------------------------------------- */

export const WS_ROOMS = {
  Notification: "FC-ROOM-NOTIFICATION"
}

export const WS_EVENT = {
  NotificationListFromServer: "FC-EVENT-NOTIFICATION-LIST-FROM-SERVER",
  NotificationOneFromServer: "FC-EVENT-NOTIFICATION-ONE-FROM-SERVER"
}

/* ---------------------------------------------------------------- */

export const abnEntity2fcEntity = (abnEntity: string) => {
  const entityMap: Record<string, any> = {
    "Approved Deposit Fund": null,
    "APRA Regulated Non-Public Offer Fund": null,
    "APRA Regulated Public Offer Fund": null,
    "APRA Regulated Fund (Fund Type Unknown)": null,
    "ATO Regulated Self-Managed Superannuation Fund": ENTITY_TYPES.Company.key,
    "Australian Private Company": ENTITY_TYPES.Company.key,
    "Australian Public Company": ENTITY_TYPES.Company.key,
    "Cash Management Trust": null,
    "Commonwealth Government APRA Regulated Public Sector Fund": null,
    "Commonwealth Government APRA Regulated Public Sector Scheme": null,
    "Commonwealth Government Cash Management Trust": null,
    "Commonwealth Government Company": null,
    "Commonwealth Government Co-operative": null,
    "Commonwealth Government Corporate Unit Trust": null,
    "Commonwealth Government Discretionary Investment Trust": null,
    "Commonwealth Government Discretionary Services Management Trust": null,
    "Commonwealth Government Discretionary Trading Trust": null,
    "Commonwealth Government Entity": null,
    "Commonwealth Government Fixed Trust": null,
    "Commonwealth Government Fixed Unit Trust": null,
    "Commonwealth Government Hybrid Trust": null,
    "Commonwealth Government Limited Partnership": null,
    "Commonwealth Government Listed Public Unit Trust": null,
    "Commonwealth Government Non-Regulated Super Fund": null,
    "Commonwealth Government Other Incorporated Entity": null,
    "Commonwealth Government Other Unincorporated Entity": null,
    "Commonwealth Government Partnership": null,
    "Commonwealth Government Pooled Development Fund": null,
    "Commonwealth Government Private Company": null,
    "Commonwealth Government Public Company": null,
    "Commonwealth Government Public Trading Trust": null,
    "Commonwealth Government Statutory Authority": null,
    "Commonwealth Government Strata Title": null,
    "Commonwealth Government Super Fund": null,
    "Commonwealth Government Trust": null,
    "Commonwealth Government Unlisted Public Unit Trust": null,
    // "Co-operative": ENTITY_TYPES.CoOperative.key,
    "Co-operative": null,
    "Corporate Collective Investment Vehicle (CCIV) Sub-Fund": null,
    "Corporate Unit Trust": ENTITY_TYPES.Trust.key,
    "Deceased Estate": null,
    "Diplomatic/Consulate Body or High Commission": null,
    "Discretionary Investment Trust": ENTITY_TYPES.Trust.key,
    "Discretionary Services Management Trust": ENTITY_TYPES.Trust.key,
    "Discretionary Trading Trust": ENTITY_TYPES.Trust.key,
    "Family Partnership": ENTITY_TYPES.Partnership.key,
    "First Home Saver Accounts (FHSA) Trust": null,
    "Fixed Trust": ENTITY_TYPES.Trust.key,
    "Fixed Unit Trust": ENTITY_TYPES.Trust.key,
    "Hybrid Trust": ENTITY_TYPES.Trust.key,
    "Individual Sole Trader": ENTITY_TYPES.Individual.key,
    "Limited Partnership": ENTITY_TYPES.Partnership.key,
    "Listed Public Unit Trust": ENTITY_TYPES.Trust.key,
    "Local Government APRA Regulated Public Sector Fund": null,
    "Local Government APRA Regulated Public Sector Scheme": null,
    "Local Government Cash Management Trust": null,
    "Local Government Company": null,
    "Local Government Co-operative": null,
    "Local Government Corporate Unit Trust": null,
    "Local Government Discretionary Investment Trust": null,
    "Local Government Discretionary Services Management Trust": null,
    "Local Government Discretionary Trading Trust": null,
    "Local Government Entity": null,
    "Local Government Fixed Trust": null,
    "Local Government Fixed Unit Trust": null,
    "Local Government Hybrid Trust": null,
    "Local Government Limited Partnership": null,
    "Local Government Listed Public Unit Trust": null,
    "Local Government Non-Regulated Super Fund": null,
    "Local Government Other Incorporated Entity": null,
    "Local Government Other Unincorporated Entity": null,
    "Local Government Partnership": null,
    "Local Government Pooled Development Fund": null,
    "Local Government Private Company": null,
    "Local Government Public Company": null,
    "Local Government Public Trading Trust": null,
    "Local Government Statutory Authority": null,
    "Local Government Strata Title": null,
    "Local Government Trust": null,
    "Local Government Unlisted Public Unit Trust": null,
    "Non-Regulated Superannuation Fund": ENTITY_TYPES.Company.key,
    "Other Incorporated Entity": null,
    "Other Partnership": ENTITY_TYPES.Partnership.key,
    "Other Trust": ENTITY_TYPES.Trust.key,
    // "Other Unincorporated Entity": ENTITY_TYPES.Association.key,
    "Other Unincorporated Entity": null,
    "Pooled Development Fund": null,
    "Pooled Superannuation Trust": null,
    "Public Trading Trust": ENTITY_TYPES.Trust.key,
    "Small APRA Fund": ENTITY_TYPES.Company.key,
    "State Government APRA Regulated Public Sector Scheme": null,
    "State Government Cash Management Trust": null,
    "State Government Company": null,
    "State Government Co-operative": null,
    "State Government Corporate Unit Trust": null,
    "State Government Discretionary Investment Trust": null,
    "State Government Discretionary Services Management Trust": null,
    "State Government Discretionary Trading Trust": null,
    "State Government Entity": null,
    "State Government Fixed Trust": null,
    "State Government Fixed Unit Trust": null,
    "State Government Hybrid Trust": null,
    "State Government Limited Partnership": null,
    "State Government Listed Public Unit Trust": null,
    "State Government Non-Regulated Super Fund": null,
    "State Government Other Incorporated Entity": null,
    "State Government Other Unincorporated Entity": null,
    "State Government Partnership": null,
    "State Government Pooled Development Fund": null,
    "State Government Private Company": null,
    "State Government Public Company": null,
    "State Government Public Trading Trust": null,
    "State Government Statutory Authority": null,
    "State Government Strata Title": null,
    "State Government Trust": null,
    "State Government Unlisted Public Unit Trust": null,
    "State Government APRA Regulated Public Sector Fund": null,
    "Strata-title": null,
    "Super fund": ENTITY_TYPES.Company.key,
    "Territory Government APRA Regulated Public Sector Fund": null,
    "Territory Government APRA Regulated Public Sector Scheme": null,
    "Territory Government Cash Management Trust": null,
    "Territory Government Co-operative": null,
    "Territory Government Corporate Unit Trust": null,
    "Territory Government Discretionary Investment Trust": null,
    "Territory Government Discretionary Services Management Trust": null,
    "Territory Government Discretionary Trading Trust": null,
    "Territory Government Entity": null,
    "Territory Government Fixed Trust": null,
    "Territory Government Fixed Unit Trust": null,
    "Territory Government Hybrid Trust": null,
    "Territory Government Limited Partnership": null,
    "Territory Government Listed Public Unit Trust": null,
    "Territory Government Non-Regulated Super Fund": null,
    "Territory Government Other Incorporated Entity": null,
    "Territory Government Other Unincorporated Entity": null,
    "Territory Government Partnership": null,
    "Territory Government Pooled Development Fund": null,
    "Territory Government Private Company": null,
    "Territory Government Public Company": null,
    "Territory Government Public Trading Trust": null,
    "Territory Government Statutory Authority": null,
    "Territory Government Strata Title": null,
    "Territory Government Trust": null,
    "Territory Government Unlisted Public Unit Trust": null,
    "Unlisted Public Unit Trust": ENTITY_TYPES.Trust.key
  }

  return entityMap[abnEntity]
}
