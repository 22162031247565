import React, { useEffect, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { useInView } from "react-intersection-observer"
import { useNavigate } from "react-router-dom"

import { LoadingButton, LoadingPage, StickyShortcut } from "@/components/_uiext"
import useAdminLenderStore from "@/stores/useAdminLenderStore"
import { INVIEW_THRESHOLD, LOADING_TIMEOUT } from "@/utils/constants"

import { Button } from "@repo/ui/components/ui/button"

import { LenderSchema, LenderSchemaType } from "../_sections/Schema"
import BasicInformation from "../_sections/BasicInformation"
import LenderDetails from "../_sections/LenderDetails"
import FilesAndAttachments from "../_sections/FilesAndAttachments"
import Contacts from "../_sections/Contacts"

export default function MainForm() {
  const store = useAdminLenderStore()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  const { ref: ref1, inView: inView1 } = useInView({ threshold: INVIEW_THRESHOLD })
  const { ref: ref2, inView: inView2 } = useInView({ threshold: INVIEW_THRESHOLD })
  const { ref: ref3, inView: inView3 } = useInView({ threshold: INVIEW_THRESHOLD ** 5 })
  const { ref: ref4, inView: inView4 } = useInView({ threshold: INVIEW_THRESHOLD })

  const form = useForm<LenderSchemaType>({
    resolver: zodResolver(LenderSchema),
    defaultValues: {
      lender_name: "",
      lender_number: "",
      lender_email: "",
      lender_mobile_number: "",
      website: undefined,

      contacts: [],

      funding_solutions: [],
      fs: [],

      documents: [],
      uploaded_documents: [],
      removed_documents: []
    }
  })

  const initialize = () => {
    store.getMaxIdAction((data) => {
      form.setValue(
        "lender_number",
        `L-${Number(data + 1)
          .toString()
          .padStart(4, "0")}`
      )
    })

    setTimeout(() => {
      setLoading(false)
    }, LOADING_TIMEOUT)
  }

  const handleCancelClick = () => {
    navigate("/admin/lenders/list")
  }

  const onSubmit = (values: LenderSchemaType) => {
    store.createAction(values, () => {
      navigate("/admin/lenders/list")
    })
  }

  useEffect(() => {
    initialize()
  }, [])

  return (
    <FormProvider {...form}>
      <LoadingPage loading={loading}>
        <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
            <div className="flex w-full flex-1 flex-col gap-5 xl:pr-8">
              <div ref={ref1} id="basic-information">
                <BasicInformation isView={false} />
              </div>

              <div ref={ref2} id="key-contacts">
                <Contacts isView={false} fieldName="contacts" />
              </div>

              <div ref={ref3} id="lender-details">
                <LenderDetails isView={false} />
              </div>

              <div ref={ref4} id="files-and-attachments">
                <FilesAndAttachments isView={false} />
              </div>
            </div>

            <StickyShortcut
              items={[
                {
                  id: "basic-information",
                  label: "Basic information",
                  inView: inView1
                },
                {
                  id: "key-contacts",
                  label: "Key contacts",
                  inView: inView2
                },
                {
                  id: "lender-details",
                  label: "Lender details",
                  inView: inView3
                },
                {
                  id: "files-and-attachments",
                  label: "Files and attachments",
                  inView: inView4
                }
              ]}
            />
          </div>

          <div className="flex w-full justify-end gap-2 pb-2 pt-6">
            <Button
              type="button"
              className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>

            <LoadingButton loading={store.loading} type="submit" className="px-6 py-4 md:!w-auto">
              Add lender
            </LoadingButton>
          </div>
        </form>
      </LoadingPage>
    </FormProvider>
  )
}
