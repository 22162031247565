import React, { Dispatch, SetStateAction } from "react"

import { LoadingButton } from "@/components/_uiext"
import useAdminLenderStore from "@/stores/useAdminLenderStore"

import { Dialog, DialogContent } from "@repo/ui/components/ui/dialog"
import { Button } from "@repo/ui/components/ui/button"

interface Props {
  targetData: any
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export default function DuplicateConfirmDialog(props: Readonly<Props>) {
  const { targetData, open, setOpen } = props
  const store = useAdminLenderStore()

  const handleCancelClick = () => {
    setOpen(false)
  }

  const handleSaveClick = () => {
    store.duplicateAction(targetData.uuid, () => {
      setOpen(false)
    })
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        className="flex h-full flex-col justify-between gap-0 bg-white p-10 md:h-auto"
        onOpenAutoFocus={(e: any) => e.preventDefault()}
      >
        <div className="mb-6 flex flex-col">
          <h1 className="text-main mb-2.5 text-lg font-semibold">Duplicate</h1>

          <p className="text-default mb-6 text-left text-sm font-normal">
            Are you really going to duplicate the following lender?
          </p>

          <div className="grid w-fit grid-cols-2 gap-2 self-center">
            <p className="text-main text-sm font-normal">Lender number:</p>
            <p className="text-main text-sm font-semibold">{targetData.lender_number}</p>
            <p className="text-main text-sm font-normal">Lender name:</p>
            <p className="text-main text-sm font-semibold">{targetData.lender_name}</p>
          </div>
        </div>

        <div className="flex justify-center gap-2.5">
          <Button
            type="button"
            className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
            onClick={handleCancelClick}
          >
            No
          </Button>

          <LoadingButton
            loading={store.loading}
            className="w-full px-6 py-4 md:w-auto"
            onClick={handleSaveClick}
          >
            Yes
          </LoadingButton>
        </div>
      </DialogContent>
    </Dialog>
  )
}
