import React, { useEffect, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { useInView } from "react-intersection-observer"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { LoadingButton, LoadingPage, StickyShortcut } from "@/components/_uiext"
import useAdminUserStore from "@/stores/useAdminUserStore"
import { INVIEW_THRESHOLD, LOADING_TIMEOUT } from "@/utils/constants"

import { Button } from "@repo/ui/components/ui/button"
import { ENTITY_TYPES } from "@repo/util/constant"
import { CLIENT_MSG } from "@repo/i18n"

import BasicInformation from "../_sections/BasicInformation"
import BusinessSettingIntroducer from "../_sections/BusinessSettingIntroducer"

const FormSchema = z
  .object({
    image: z.any().optional(),
    role: z.string().min(1, { message: "User type is required" }),
    first_name: z.string().min(1, { message: "First name is required" }),
    last_name: z.string().min(1, { message: "Last name is required" }),
    email: z.string().email({
      message: "Please enter a valid email address"
    }),
    mobile_number: z.string().min(1, {
      message: "Please enter a valid mobile number"
    }),
    is_archived: z.boolean(),
    is_approved: z.boolean(),
    is_locked: z.boolean(),

    entity: z.object({
      entity_type: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      temp_abn: z
        .string({ required_error: CLIENT_MSG.FIELD_REQUIRED })
        .min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      entity_name: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      abn: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      acn: z.string(),
      trust_type: z.string(),
      trust_type_other: z.string(),
      gst_registered: z.string().default(""),
      principal_place: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      registered_place: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      entity_email: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }).email(),
      entity_contact_number: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      primary_business_activity: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      website: z.string().url().optional()
    })
  })
  .superRefine((data: any, ctx) => {
    const entityTypesRequiringACN = [
      ENTITY_TYPES.Company.key,
      ENTITY_TYPES.Trust.key,
      ENTITY_TYPES.SMSF.key
    ]
    if (entityTypesRequiringACN.includes(data.entity.entity_type) && !data.entity.acn) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: CLIENT_MSG.FIELD_REQUIRED,
        path: ["entity.acn"]
      })
    }
  })

type FormSchemaType = z.infer<typeof FormSchema>

interface Props {
  data: any
}

export default function IntroducerForm({ data }: Readonly<Props>) {
  const store = useAdminUserStore()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  const { ref: ref1, inView: inView1 } = useInView({ threshold: INVIEW_THRESHOLD })
  const { ref: ref2, inView: inView2 } = useInView({ threshold: INVIEW_THRESHOLD })

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      image: null,
      role: "",
      first_name: "",
      last_name: "",
      email: "",
      mobile_number: "",
      is_archived: false,
      is_approved: false,
      is_locked: false,

      entity: {
        entity_type: "",
        entity_name: "",
        abn: "",
        acn: "",
        trust_type: "",
        trust_type_other: "",
        gst_registered: "",
        principal_place: "",
        registered_place: "",
        entity_email: "",
        entity_contact_number: "",
        primary_business_activity: "",
        website: undefined
      }
    }
  })

  const handleCancelClick = () => {
    navigate("/admin/users/list")
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateAction(data.uuid, values, () => {
      navigate("/admin/users/list")
    })
  }

  useEffect(() => {
    form.reset({
      ...data,

      entity: {
        ...data.entity,
        temp_abn: data.entity?.entity_name,
        website: data.entity?.website ?? undefined,
        trust_type_other: data.entity?.trust_type_other ?? ""
      }
    })

    setTimeout(() => {
      setLoading(false)
    }, LOADING_TIMEOUT)
  }, [data])

  return (
    <FormProvider {...form}>
      <LoadingPage loading={loading}>
        <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
            <div className="flex w-full flex-1 flex-col gap-5 xl:pr-8">
              <div ref={ref1} id="basic-information">
                <BasicInformation isView={false} />
              </div>

              <div ref={ref2} id="business-setting">
                <BusinessSettingIntroducer isView={false} />
              </div>
            </div>

            <StickyShortcut
              items={[
                {
                  id: "basic-information",
                  label: "Basic information",
                  inView: inView1
                },
                {
                  id: "business-setting",
                  label: "Business setting information",
                  inView: inView2
                }
              ]}
            />
          </div>

          <div className="flex w-full justify-end gap-2 pb-2 pt-6">
            <Button
              type="button"
              className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>

            <LoadingButton loading={store.loading} type="submit" className="px-6 py-4 md:!w-auto">
              Update account
            </LoadingButton>
          </div>
        </form>
      </LoadingPage>
    </FormProvider>
  )
}
