import React, { useEffect, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { useInView } from "react-intersection-observer"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { LoadingPage, StickyShortcut } from "@/components/_uiext"
import useAdminUserStore from "@/stores/useAdminUserStore"
import { INVIEW_THRESHOLD, LOADING_TIMEOUT } from "@/utils/constants"

import { Button } from "@repo/ui/components/ui/button"

import BasicInformation from "../_sections/BasicInformation"
import BusinessUser from "../_sections/BusinessUser"

const FormSchema = z.object({
  image: z.any().optional(),
  role: z.string().min(1, { message: "User type is required" }),
  first_name: z.string().min(1, { message: "First name is required" }),
  last_name: z.string().min(1, { message: "Last name is required" }),
  email: z.string().email({
    message: "Please enter a valid email address"
  }),
  mobile_number: z.string().min(1, {
    message: "Please enter a valid mobile number"
  }),
  is_archived: z.boolean(),
  is_approved: z.boolean(),
  is_locked: z.boolean(),

  membership_association: z.string().min(1, { message: "Association is required" }),
  membership_certificate: z
    .string()
    .min(1, { message: "Membership certificate number is required" }),
  membership_expiry: z.coerce.date(),

  acr_certificate: z.string().min(1, { message: "This field is required" }),
  acr_expiry: z.coerce.date(),

  pii_certificate: z.string().min(1, { message: "Insurance policy number is required" }),
  pii_expiry: z.coerce.date()
})

type FormSchemaType = z.infer<typeof FormSchema>

interface Props {
  data: any
}

export default function EmployeeForm({ data }: Readonly<Props>) {
  const store = useAdminUserStore()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const { ref: ref1, inView: inView1 } = useInView({ threshold: INVIEW_THRESHOLD })
  const { ref: ref2, inView: inView2 } = useInView({ threshold: INVIEW_THRESHOLD })

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      image: null,
      role: "",
      first_name: "",
      last_name: "",
      email: "",
      mobile_number: "",
      is_archived: false,
      is_approved: false,
      is_locked: false,

      membership_association: "",
      membership_certificate: "",
      membership_expiry: undefined,

      acr_certificate: "",
      acr_expiry: undefined,

      pii_certificate: "",
      pii_expiry: undefined
    }
  })

  const handleCancelClick = () => {
    navigate("/admin/users/list")
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateAction(data.uuid, values, () => {
      navigate("/admin/users/list")
    })
  }

  useEffect(() => {
    form.reset({
      ...data
    })

    setTimeout(() => {
      setLoading(false)
    }, LOADING_TIMEOUT)
  }, [data])

  return (
    <FormProvider {...form}>
      <LoadingPage loading={loading}>
        <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
            <div className="flex w-full flex-1 flex-col gap-5 xl:pr-8">
              <div ref={ref1} id="basic-information">
                <BasicInformation isView={true} />
              </div>

              <div ref={ref2} id="business-user">
                <BusinessUser isView={true} />
              </div>
            </div>

            <StickyShortcut
              items={[
                {
                  id: "basic-information",
                  label: "Basic information",
                  inView: inView1
                },
                {
                  id: "business-user",
                  label: "Business user information",
                  inView: inView2
                }
              ]}
            />
          </div>

          <div className="flex w-full justify-end gap-2 pb-2 pt-6">
            <Button
              type="button"
              className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
          </div>
        </form>
      </LoadingPage>
    </FormProvider>
  )
}
