import React, { useEffect, useState } from "react"

import { Link, useParams } from "react-router-dom"

import AdminLayout from "@/components/_layout/AdminLayout"
import useAdminApplicationStore from "@/stores/useAdminApplicationStore"

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from "@repo/ui/components/ui/breadcrumb"
import { APPLICATION_STEPS } from "@repo/util/constant"

import MainForm from "./MainForm"
import { AdminAppContextProvider } from "../AdminAppContext"

export default function AdminApplicationsView() {
  const { uuid } = useParams()
  const store = useAdminApplicationStore()

  const [initialSteps, setInitialSteps] = useState(
    Object.entries(APPLICATION_STEPS).map(([k, v]) => {
      return v
    })
  )
  const [initialActiveStep, setInitialActiveStep] = useState(0)
  const [initialLoading, setInitialLoading] = useState(true)
  const [initialData, setInitialData] = useState<any>(null)

  const initialize = () => {
    setInitialLoading(true)
    store.getOneByUuidAction(uuid ?? "", (newData) => {
      setInitialData(newData)

      const activeStep = initialSteps.indexOf(newData?.current_step ?? "")
      setInitialActiveStep(activeStep === -1 ? 0 : activeStep)

      setTimeout(() => {
        setInitialLoading(false)
      }, 1000)
    })
  }

  useEffect(() => {
    initialize()
  }, [uuid])

  return (
    <AdminAppContextProvider
      initialSteps={initialSteps}
      initialActiveStep={initialActiveStep}
      initialLoading={initialLoading}
      initialData={initialData}
    >
      <AdminLayout
        breadcrumb={
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <Link to="/admin/dashboard" className="text-default text-sm font-normal">
                  Dashboard
                </Link>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <Link to="/admin/applications/list" className="text-default text-sm font-normal">
                  Applications
                </Link>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage className="text-main text-sm font-semibold">
                  {initialData?.id?.toString().padStart(10, "0")}
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        }
      >
        <div className="flex flex-col">
          <div className="mb-4 block w-full rounded-xl border border-[#ECECEC] bg-white p-8 lg:hidden">
            <h3 className="text-main text-lg font-semibold">View application</h3>
          </div>

          <MainForm />
        </div>
      </AdminLayout>
    </AdminAppContextProvider>
  )
}
