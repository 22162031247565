import React from "react"

import clsx from "clsx"
import { MdCheckCircle, MdInfoOutline } from "react-icons/md"

import { IconApplicantType } from "@/components/_icons"

import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/components/ui/popover"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@repo/ui/components/ui/tooltip"

interface Props {
  isSelected: boolean
  value: string
  description: string
  onClick: () => void
}

export default function LoanTypeItem(props: Readonly<Props>) {
  const { isSelected, value, description, onClick } = props

  return (
    <div
      className={clsx(
        "relative flex min-h-40 flex-1 cursor-pointer flex-col items-center rounded-2xl border px-2.5 py-7 md:px-5",
        isSelected ? "border-primary" : ""
      )}
      onClick={onClick}
    >
      <TooltipProvider delayDuration={0} skipDelayDuration={0}>
        <Tooltip>
          <TooltipTrigger className="absolute left-3 top-3 hidden md:block">
            <MdInfoOutline className="text-default" />
          </TooltipTrigger>
          <TooltipContent side="bottom" className="w-40 p-3.5" align="start">
            <p className="text-xs font-normal">{description}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <Popover>
        <PopoverTrigger
          className="absolute left-3 top-3 block md:hidden"
          onClick={(e) => e.stopPropagation()}
        >
          <MdInfoOutline className="text-default" />
        </PopoverTrigger>
        <PopoverContent side="bottom" className="w-40 p-3.5" align="end">
          <p className="text-xs font-normal">{description}</p>
        </PopoverContent>
      </Popover>

      {isSelected && (
        <div className="absolute right-3 top-3">
          <MdCheckCircle className="text-primary" />
        </div>
      )}

      <div
        className={clsx(
          "mb-2 flex h-12 w-12 items-center justify-center rounded-full",
          isSelected ? "bg-primary" : "bg-[#CDCDCD]"
        )}
      >
        <IconApplicantType className="text-base text-white" />
      </div>

      <div
        className={clsx(
          "text-center text-base font-normal",
          isSelected ? "text-main" : "text-default"
        )}
      >
        {value}
      </div>
    </div>
  )
}
