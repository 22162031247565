import React, { ReactNode, useEffect, useState } from "react"

import {
  IconApplicationDeclined,
  IconApplicationIncomplete,
  IconApplicationSettled,
  IconApplicationSubmitted
} from "@/components/_icons"
import { APPLICATION_STATUS } from "@repo/util/constant"

interface ItemProps {
  icon: ReactNode
  count: number
  text: string
}

function StatusCounterItem(props: Readonly<ItemProps>) {
  const { icon, count, text } = props

  return (
    <div className="flex flex-1 gap-4 rounded-2xl border border-[#ECECEC] bg-white p-4">
      {icon}

      <div className="flex flex-col gap-1">
        <h2 className="text-main text-2xl font-semibold">{count}</h2>
        <p className="text-default text-sm font-normal">{text}</p>
      </div>
    </div>
  )
}

interface Props {
  data: any
}

export default function StatusCounter(props: Readonly<Props>) {
  const { data } = props

  const [items, setItems] = useState<any[]>([])

  useEffect(() => {
    const newItems = []
    newItems.push({
      icon: (
        <div className="flex max-h-14 min-h-14 min-w-14 max-w-14 items-center justify-center rounded-full bg-[#3B82F633]">
          <IconApplicationSubmitted className="text-2xl text-[#3B82F6]" />
        </div>
      ),
      count: data?.filter((d: any) => d.key === APPLICATION_STATUS.Received.key)?.[0].count,
      text: "Application submitted"
    })

    newItems.push({
      icon: (
        <div className="flex max-h-14 min-h-14 min-w-14 max-w-14 items-center justify-center rounded-full bg-[#10B98133]">
          <IconApplicationSettled className="text-3xl text-[#10B981]" />
        </div>
      ),
      count: data?.filter((d: any) => d.key === APPLICATION_STATUS.Settled.key)?.[0].count,
      text: "Application settled"
    })

    newItems.push({
      icon: (
        <div className="flex max-h-14 min-h-14 min-w-14 max-w-14 items-center justify-center rounded-full bg-[#EF444433]">
          <IconApplicationDeclined className="text-2xl text-[#EF4444]" />
        </div>
      ),
      count: data?.filter((d: any) => d.key === APPLICATION_STATUS.Declined.key)?.[0].count,
      text: "Application declined"
    })

    newItems.push({
      icon: (
        <div className="flex max-h-14 min-h-14 min-w-14 max-w-14 items-center justify-center rounded-full bg-[#F59E0B33]">
          <IconApplicationIncomplete className="text-2xl text-[#F59E0B]" />
        </div>
      ),
      count: data?.filter((d: any) => d.key === APPLICATION_STATUS.Incomplete.key)?.[0].count,
      text: "Application incomplete"
    })

    setItems(newItems)
  }, [data])

  return (
    <div className="mb-4 grid w-full grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
      {items.map((i) => (
        <StatusCounterItem key={i.text} {...i} />
      ))}
    </div>
  )
}
