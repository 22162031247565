import React from "react"

import { Button } from "@repo/ui/components/ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "@repo/ui/components/ui/dialog"
import { Separator } from "@repo/ui/components/ui/separator"

interface Props {
  data: any
  excludedLenders: any[]
}

export function LenderTraceDialog(props: Props) {
  const { data, excludedLenders } = props

  return (
    <Dialog>
      <DialogTrigger asChild className="fixed bottom-4 right-4">
        <Button variant="destructive">?</Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-main">Excluded lenders</DialogTitle>
          <DialogDescription className="text-destructive">
            This feature will only be available during development.
          </DialogDescription>
        </DialogHeader>

        <div className="grid grid-cols-3 gap-2">
          <div className="text-main text-sm font-medium">Funding solution:</div>
          <div className="text-main col-span-2 text-sm">{data?.funding_solution}</div>

          <div className="text-main text-sm font-medium">Loan type:</div>
          <div className="text-main col-span-2 text-sm">{data?.loan_type}</div>
        </div>

        <Separator />

        <div className="grid grid-cols-3 gap-2">
          {excludedLenders.length > 0 &&
            excludedLenders.map((el) => (
              <React.Fragment key={el.lender.uuid}>
                <div className="text-main text-sm font-medium">{el.lender.lender_number}:</div>
                <div className="text-main col-span-2 text-sm">{el.reason}</div>
              </React.Fragment>
            ))}

          {!excludedLenders.length && (
            <React.Fragment>
              <div className="text-main col-span-3 text-sm">
                None of the lenders supports this funding solution.
              </div>
            </React.Fragment>
          )}
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="destructive">Close</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
