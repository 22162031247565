import React, { Dispatch, SetStateAction } from "react"

import { IconCheckPolygon } from "@/components/_icons"

import { Dialog, DialogContent } from "@repo/ui/components/ui/dialog"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export function SubmitSuccessDialog(props: Readonly<Props>) {
  const { open, setOpen } = props

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        hideClose
        className="flex h-full flex-col gap-0 bg-white p-10 md:h-auto"
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
      >
        <div className="flex h-full flex-col items-center justify-center">
          <div className="flex flex-col">
            <div className="mb-4 flex justify-center">
              <IconCheckPolygon className="text-primary text-6xl" />
            </div>

            <h1 className="text-main mb-4 text-center text-lg font-semibold">Success!</h1>

            <p className="text-default mb-6 text-center text-base font-normal">
              You have completed the Privacy Consent, Declarations & Authority to Act form.
            </p>

            <p className="text-default text-center text-base font-normal">
              We will notify your broker.
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
