export const formatABN = (abn: string) => {
  if (abn && abn.length === 11) return abn.replace(/\B(?=(\d{3})+(?!\d))/g, " ")

  return ""
}

export const capitalize = (word: string) => {
  if (!word) return ""

  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}
