import React from "react"

import { useFormContext } from "react-hook-form"

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { Checkbox } from "@repo/ui/components/ui/checkbox"
import { FUNDING_SOLUTIONS, LOAN_TYPES } from "@repo/util/constant"

import FundingSolutions from "./FundingSolutions"

interface Props {
  isView?: boolean
}

export default function LenderDetails(props: Readonly<Props>) {
  const { isView = false } = props
  const form = useFormContext()

  return (
    <div className="flex w-full flex-col">
      <h3 className="text-main py-4 text-lg font-semibold">Lender details</h3>

      <div className="mb-8 w-full">
        <FormField
          control={form.control}
          name="funding_solutions"
          render={({ field }) => (
            <div className="flex flex-col gap-2">
              <FormLabel className="text-main text-base font-medium">Funding solutions</FormLabel>
              <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-3">
                {Object.entries(FUNDING_SOLUTIONS)
                  .sort()
                  .map(([k, v]) => (
                    <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          disabled={
                            !Object.values(LOAN_TYPES).filter((lt) =>
                              lt.funding_solutions.includes(v.key)
                            ).length || isView
                          }
                          checked={field.value?.includes(v.key)}
                          onCheckedChange={(checked) => {
                            const updatedValue = field.value || []
                            if (checked) {
                              field.onChange([...updatedValue, v.key])
                              form.setValue("fs", [
                                ...form.getValues().fs,
                                {
                                  funding_solution: v.key,
                                  lt: []
                                }
                              ])
                            } else {
                              field.onChange(updatedValue.filter((item: string) => item !== v.key))
                              form.setValue(
                                "fs",
                                form
                                  .getValues()
                                  .fs.filter((item: any) => item.funding_solution !== v.key)
                              )
                            }
                          }}
                          className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                        />
                      </FormControl>
                      <FormLabel className="text-main font-normal">{v.key}</FormLabel>
                    </FormItem>
                  ))}
              </div>
              <FormMessage />
            </div>
          )}
        />
      </div>

      <FundingSolutions isView={isView} />
    </div>
  )
}
