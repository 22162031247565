import React, { useEffect, useState } from "react"

import numeral from "numeral"

import { useAppContext } from "@/pages/applications/AppContext"

import { FUNDING_SOLUTIONS } from "@repo/util/constant"

export default function LenderSelectionSummary() {
  const { data } = useAppContext()
  const [filteredFslt, setFilteredFslt] = useState<any>(null)

  useEffect(() => {
    const arrFS = data?.lender?.fs?.filter(
      (fs: any) => fs?.funding_solution === data?.funding_solution
    )
    if (!arrFS || !arrFS.length) {
      return
    }

    const arrLT = arrFS?.[0]?.lt?.filter((l: any) => l.loan_type === data?.loan_type)
    if (!arrLT || !arrLT.length) {
      return
    }

    setFilteredFslt(arrLT?.[0])
  }, [data])

  return (
    <div className="relative flex w-full flex-col rounded-2xl border p-4 md:p-6">
      <h3 className="text-main mb-4 text-lg font-semibold">{data?.lender?.lender_number}</h3>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
        <div className="flex flex-row gap-4 md:flex-col">
          <div className="flex flex-1 flex-col gap-2">
            <span className="text-default text-sm font-normal">Interest rate from</span>
            <span className="text-main text-sm font-normal">
              {filteredFslt?.interest_rate ?? 0}%
            </span>
          </div>

          <div className="flex flex-1 flex-col gap-2">
            <span className="text-default text-sm font-normal">Maximum LVR</span>
            <span className="text-main text-sm font-normal">
              {filteredFslt?.loan_value_ratio ?? 0}%
            </span>
          </div>
        </div>

        {data?.funding_solution !== FUNDING_SOLUTIONS.CashflowFinance.key && (
          <div className="flex flex-row gap-4 md:flex-col">
            <div className="flex flex-1 flex-col gap-2">
              <span className="text-default text-sm font-normal">Min. loan size</span>
              <span className="text-main text-sm font-normal">
                {numeral(filteredFslt?.minimum_loan_size).format("$ 0[.]00 a")}
              </span>
            </div>

            <div className="flex flex-1 flex-col gap-2">
              <span className="text-default text-sm font-normal">Max. loan size</span>
              <span className="text-main text-sm font-normal">
                {numeral(filteredFslt?.maximum_loan_size).format("$ 0[.]00 a")}
              </span>
            </div>
          </div>
        )}

        {data?.funding_solution === FUNDING_SOLUTIONS.CashflowFinance.key && (
          <div className="flex flex-row gap-4 md:flex-col">
            <div className="flex flex-1 flex-col gap-2">
              <span className="text-default text-sm font-normal">Annual turnover range</span>

              <div className="flex flex-col gap-0.5">
                {filteredFslt?.turnover_ranges
                  ?.filter((tr: any) => tr.checked === "true")
                  ?.map((tr: any) => (
                    <div key={`${tr.min}-${tr.max}`} className="flex items-center gap-1">
                      <span className="text-main text-sm font-normal">
                        {numeral(tr.min).format("$ 0[.]00 a")}
                      </span>
                      -
                      <span className="text-main text-sm font-normal">
                        {Number(tr.max) !== 0 && numeral(tr.max).format("$ 0[.]00 a")}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-row gap-4 md:flex-col">
          <div className="flex flex-1 flex-col gap-2">
            <span className="text-default text-sm font-normal">Min. loan term</span>
            <span className="text-main text-sm font-normal">
              {filteredFslt?.minimum_loan_term ?? 0} Months
            </span>
          </div>

          <div className="flex flex-1 flex-col gap-2">
            <span className="text-default text-sm font-normal">Max. loan term</span>
            <span className="text-main text-sm font-normal">
              {filteredFslt?.maximum_loan_term ?? 0} Months
            </span>
          </div>
        </div>

        <div className="flex flex-row gap-4 md:flex-col">
          <div className="flex flex-1 flex-col gap-2">
            <span className="text-default text-sm font-normal">Payment options</span>
            <span className="text-main text-sm font-normal">
              {filteredFslt?.repayments?.map((r: string) => <div key={r}>{r}</div>)}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
