import api from "./api"

// Get
const getBusinessSetting = () => api().get(`/business/settings`)

// Update
const updateBusinessSetting = (data: any) => api().putForm(`/business/settings`, data)

// Verify
const verifyIdentity = () => api().post("/business/settings/verify-identity")

export default {
  getBusinessSetting,
  updateBusinessSetting,
  verifyIdentity
}
