import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

import { format } from "date-fns"
import { NumericFormat } from "react-number-format"

import { IconCalendar } from "@/components/_icons"
import { LoadingButton } from "@/components/_uiext"
import useAdminApplicationStore from "@/stores/useAdminApplicationStore"

import { Calendar } from "@repo/ui/components/ui/calendar"
import { Dialog, DialogContent } from "@repo/ui/components/ui/dialog"
import { FormControl } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/components/ui/popover"
import { ScrollArea } from "@repo/ui/components/ui/scroll-area"

import { useAdminAppContext } from "../../AdminAppContext"

import ReferrerAutoComplete from "./ReferrerAutoComplete"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export default function EarningsDialog(props: Readonly<Props>) {
  const { open, setOpen } = props
  const store = useAdminApplicationStore()
  const { data, setData } = useAdminAppContext()

  const [openCalendar, setOpenCalendar] = useState(false)
  const [settledAmount, setSettledAmount] = useState("")
  const [commissionsEarned, setCommissionsEarned] = useState("")
  const [earningDate, setEarningDate] = useState<any>(undefined)
  const [referrers, setReferrers] = useState<any[]>([])

  const handleSaveClick = () => {
    store.updateEarningAction(
      data.uuid,
      {
        settled_amount: settledAmount,
        commissions_earned: commissionsEarned,
        earning_date: earningDate,
        introducers: referrers.map((r: any) => r.uuid)
      },
      (newData) => {
        setData(newData)
        setOpen(false)
      }
    )
  }

  useEffect(() => {
    setSettledAmount(data?.settled_amount)
    setCommissionsEarned(data?.commissions_earned)
    setEarningDate(data?.earning_date)
    setReferrers(data?.referrals?.map((r: any) => r.introducer))
  }, [data])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="h-full bg-white p-4 md:h-auto">
        <ScrollArea>
          <div className="flex flex-col p-4">
            <h1 className="text-main mb-4 text-center text-lg font-semibold">
              Earnings & referrers
            </h1>

            <p className="text-default mb-6 text-center text-base font-normal">
              Please enter the commission earned, settled amount and date for Application{" "}
              <span className="text-main">
                #
                {Number(data?.id ?? "")
                  .toString()
                  .padStart(10, "0")}
              </span>
            </p>

            <p className="text-main mb-2 text-sm font-normal">Settled amount</p>
            <NumericFormat
              className="mb-6"
              customInput={Input}
              thousandSeparator
              prefix="$"
              placeholder="$"
              value={settledAmount}
              onValueChange={(values, sourceInfo) => {
                setSettledAmount(values.value)
              }}
            />

            <p className="text-main mb-2 text-sm font-normal">Commissions earned</p>
            <NumericFormat
              className="mb-6"
              customInput={Input}
              thousandSeparator
              prefix="$"
              placeholder="$"
              value={commissionsEarned}
              onValueChange={(values, sourceInfo) => {
                setCommissionsEarned(values.value)
              }}
            />

            <p className="text-main mb-2 text-sm font-normal">Date</p>
            <Popover open={openCalendar} onOpenChange={setOpenCalendar}>
              <PopoverTrigger asChild className="mb-6">
                <FormControl>
                  <div className="relative">
                    <Input
                      className="cursor-pointer"
                      readOnly
                      value={earningDate ? format(earningDate, "dd/MM/yyyy") : ""}
                    />
                    <div className="absolute right-[17.5px] top-[50%] translate-y-[-50%]">
                      <IconCalendar className="text-default text-lg" />
                    </div>
                  </div>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  initialFocus
                  captionLayout="dropdown-buttons"
                  fromYear={new Date().getFullYear()}
                  toYear={new Date().getFullYear() + 10}
                  mode="single"
                  selected={earningDate ?? ""}
                  onSelect={(date) => {
                    setEarningDate(date)
                    setOpenCalendar(false)
                  }}
                  disabled={(date) => date < new Date("1900-01-01")}
                />
              </PopoverContent>
            </Popover>

            <p className="text-default mb-2 text-center text-base font-normal">
              Please select introducers whom you intend to lead to application{" "}
              <span className="text-main">
                #
                {Number(data?.id ?? "")
                  .toString()
                  .padStart(10, "0")}
              </span>
              {""}.
            </p>
            <div className="mb-4">
              <ReferrerAutoComplete
                disabled={false}
                value={referrers}
                onValueChange={(value: any) => setReferrers(value)}
              />
            </div>

            <div className="flex justify-center">
              <LoadingButton
                loading={store.loading}
                className="w-full px-6 py-4 md:w-auto"
                onClick={handleSaveClick}
              >
                Submit
              </LoadingButton>
            </div>
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}
