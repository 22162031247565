import React, { Dispatch, SetStateAction } from "react"

import { LuSearch } from "react-icons/lu"

import { ITableParams } from "@/types/interface"

import { Input } from "@repo/ui/components/ui/input"

import FilterPopover from "./FilterPopover"

interface Props {
  tableParams: ITableParams
  setTableParams: Dispatch<SetStateAction<ITableParams>>
}

export default function Header({ tableParams, setTableParams }: Readonly<Props>) {
  return (
    <div className="flex flex-col gap-4 rounded-t-xl border border-[#ECECEC] bg-white p-4 md:flex-row md:items-center md:justify-between">
      <div className="flex flex-col justify-between gap-2.5 md:flex-row">
        <h3 className="text-main text-lg font-semibold">Users</h3>
      </div>

      <div className="flex items-center justify-between gap-2">
        <FilterPopover tableParams={tableParams} setTableParams={setTableParams} />
        <div className="relative w-full">
          <Input
            autoFocus
            className="h-10 w-full md:w-64"
            placeholder="Search user"
            value={tableParams.filter.keyword}
            onChange={(e) => {
              setTableParams((prev: ITableParams) => ({
                ...prev,
                filter: {
                  ...prev.filter,
                  keyword: e.target.value
                }
              }))
            }}
          />
          <div className="absolute right-2 top-0 translate-y-[50%]">
            <LuSearch className="text-xl" />
          </div>
        </div>
      </div>
    </div>
  )
}
