import React, { useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { InputMask } from "@react-input/mask"
import clsx from "clsx"
import json2mq from "json2mq"
import { useForm } from "react-hook-form"
import { useMediaQuery } from "react-responsive"
import * as z from "zod"

import { IconCreateApplication } from "@/components/_icons"
import useLeadStore from "@/stores/useLeadStore"
import { LoadingButton } from "@/components/_uiext"

import { Button } from "@repo/ui/components/ui/button"
import { Checkbox } from "@repo/ui/components/ui/checkbox"
import { Dialog, DialogContent, DialogTrigger } from "@repo/ui/components/ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import { ScrollArea } from "@repo/ui/components/ui/scroll-area"
import { Textarea } from "@repo/ui/components/ui/textarea"
import { SITE_TITLE } from "@repo/util/constant"
import { CLIENT_MSG } from "@repo/i18n"

const FormSchema = z.object({
  full_name: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
  mobile_number: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
  email: z.string().email({ message: CLIENT_MSG.FIELD_REQUIRED }),
  requirement: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
  is_agreed: z
    .boolean({
      invalid_type_error: CLIENT_MSG.FIELD_REQUIRED,
      required_error: CLIENT_MSG.FIELD_REQUIRED
    })
    .refine((val) => val === true, {
      message: CLIENT_MSG.FIELD_REQUIRED
    }),
  is_agreed2: z
    .boolean({
      invalid_type_error: CLIENT_MSG.FIELD_REQUIRED,
      required_error: CLIENT_MSG.FIELD_REQUIRED
    })
    .refine((val) => val === true, {
      message: CLIENT_MSG.FIELD_REQUIRED
    })
})

type FormSchemaType = z.infer<typeof FormSchema>

interface Props {
  type: "sidebar" | "button" | "link"
  disabled?: boolean
}

export default function NewLeadDialog(props: Readonly<Props>) {
  const { type, disabled = false } = props
  const store = useLeadStore()
  const [open, setOpen] = useState(false)

  const isMobile = useMediaQuery({ query: json2mq({ maxWidth: 767 }) })

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      full_name: "",
      mobile_number: "",
      email: "",
      requirement: "",
      is_agreed: false,
      is_agreed2: false
    }
  })

  const onSubmit = (values: FormSchemaType) => {
    store.createAction(values, () => {
      form.reset()
      setOpen(false)
    })
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {type === "sidebar" && (
        <DialogTrigger disabled={disabled} asChild>
          <Button className="h-auto w-full rounded-xl py-4 text-sm font-semibold text-white">
            <IconCreateApplication className="mr-1 text-2xl" /> Add new lead
          </Button>
        </DialogTrigger>
      )}

      {type === "button" && (
        <DialogTrigger disabled={disabled} asChild>
          <Button className="border-primary order-1 flex h-auto w-full items-center gap-1.5 border px-4 py-3 font-semibold md:order-2 md:w-auto">
            <IconCreateApplication className="text-lg" /> Add new lead
          </Button>
        </DialogTrigger>
      )}

      {type === "link" && (
        <DialogTrigger disabled={disabled}>
          <div
            className={clsx(
              "flex cursor-pointer items-center gap-1 py-1 text-sm",
              disabled && "cursor-auto opacity-50"
            )}
          >
            <IconCreateApplication className="text-xl" />
            Add new lead
          </div>
        </DialogTrigger>
      )}

      <DialogContent
        className="h-full bg-white p-0 md:h-auto"
        onOpenAutoFocus={(e: any) => e.preventDefault()}
      >
        <Form {...form}>
          <ScrollArea type="always" className="max-h-screen p-10">
            <form className="flex flex-col px-1" onSubmit={form.handleSubmit(onSubmit)}>
              <div className="text-main mb-4 text-center text-lg font-semibold">Add new lead</div>

              <p className="text-default mb-4 text-center text-base font-normal">
                Please enter the details of your lead.
              </p>

              <div className="mb-4">
                <FormField
                  control={form.control}
                  name="full_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-main mb-2 text-sm font-normal">
                        Full name
                      </FormLabel>
                      <Input autoFocus={!isMobile} autoComplete="off" {...field} />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="mb-4">
                <FormField
                  control={form.control}
                  name="mobile_number"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-main mb-2 text-sm font-normal">
                        Mobile number
                      </FormLabel>
                      <InputMask
                        showMask
                        component={Input}
                        mask="04__ ___ ___"
                        placeholder="04__ ___ ___"
                        replacement={{ _: /\d/ }}
                        ref={field.ref}
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="mb-4">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-main mb-2 text-sm font-normal">
                        Email address
                      </FormLabel>
                      <Input autoComplete="off" type="email" {...field} />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="mb-4">
                <FormField
                  control={form.control}
                  name="requirement"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-main mb-2 text-sm font-normal">
                        Tell us about your leads requirement
                      </FormLabel>
                      <Textarea className="text-main" {...field} />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="mb-2">
                <FormField
                  control={form.control}
                  name="is_agreed"
                  render={({ field }) => (
                    <FormItem className="flex items-start space-x-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={(checked) => {
                            field.onChange(checked)
                          }}
                          className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                        />
                      </FormControl>
                      <div className="flex flex-col">
                        <FormLabel className="text-main text-sm font-normal">
                          By proceeding with this introduction, I agree to receive a referral fee of
                          0.22% (GST inclusive) which will be paid on the successful settlement of
                          this loan.
                        </FormLabel>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              </div>

              <div className="mb-4">
                <FormField
                  control={form.control}
                  name="is_agreed2"
                  render={({ field }) => (
                    <FormItem className="flex items-start space-x-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={(checked) => {
                            field.onChange(checked)
                          }}
                          className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                        />
                      </FormControl>
                      <div className="flex flex-col">
                        <FormLabel className="text-main text-sm font-normal">
                          I agree to a phone call introduction with the borrower and a {SITE_TITLE}{" "}
                          representative.
                        </FormLabel>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              </div>

              <div className="text-center">
                <LoadingButton
                  loading={store.loading}
                  type="submit"
                  className="px-4 py-4 md:!w-auto"
                >
                  Submit new lead
                </LoadingButton>
              </div>
            </form>
          </ScrollArea>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
