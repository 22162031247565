import qs from "qs"

import api from "./api"

// Get borrowers
export const apiGetBorrowersAtApplicationDraft = (applicationUuid: string, params: any) =>
  api().get(`/application/drafts/${applicationUuid}/get-borrowers?${qs.stringify(params)}`)

// Find lender
export const findLenders = (uuid: string, params: any) =>
  api().get(`/application/drafts/${uuid}/find-lenders?${qs.stringify(params)}`)

// Create
export const apiCreateAtApplicationDraft = (params: any) =>
  api().postForm("/application/drafts", params)

// Update
export const apiUpdateAtApplicationDraft = (uuid: string, params: any) =>
  api().putForm(`/application/drafts/${uuid}`, params)

// Submit
export const apiSubmitAtApplicationDraft = (uuid: string) =>
  api().patch(`/application/drafts/${uuid}`)

// Invite borrowers
export const apiInviteBorrowersAtApplicationDraft = (applicationUuid: string, params: any) =>
  api().postForm(`/application/drafts/${applicationUuid}/invite-borrowers`, params)

// Invite email
export const apiInviteEmailAtApplicationDraft = (inviteUuid: string) =>
  api().postForm(`/application/drafts/invite-email/${inviteUuid}`)

// Consent to act request
export const apiConsentToActRequestAtApplicationDraft = (applicationUuid: string) =>
  api().postForm(`/application/drafts/${applicationUuid}/consent-to-act-request`)

// Consent email
export const apiConsentEmailAtApplicationDraft = (consentUuid: string) =>
  api().postForm(`/application/drafts/consent-email/${consentUuid}`)

// ID verify request
export const apiIdVerifyRequestAtApplicationDraft = (applicationUuid: string) =>
  api().postForm(`/application/drafts/${applicationUuid}/id-verify-request`)

// Verify email
export const apiVerifyEmailAtApplicationDraft = (verifyUuid: string) =>
  api().postForm(`/application/drafts/verify-email/${verifyUuid}`)

// Refresh ID verifications
export const apiRefreshVerificationAtApplicationDraft = (applicationUuid: string) =>
  api().postForm(`/application/drafts/${applicationUuid}/refresh-verification`)

export default {
  findLenders
}
