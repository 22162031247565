import React, { Dispatch, SetStateAction, useState } from "react"

import clsx from "clsx"
import { PatternFormat } from "react-number-format"

import { IconFilter } from "@/components/_icons"
import { PopDlg, PopDlgContent, PopDlgTrigger } from "@/components/_uiext/PopDlg"
import { ITableParams } from "@/types/interface"
import { capitalize } from "@/utils/formatter"

import { Button } from "@repo/ui/components/ui/button"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { ALL } from "@repo/util/constant"

interface Props {
  tableParams: ITableParams
  setTableParams: Dispatch<SetStateAction<ITableParams>>
}

export default function FilterPopover({ tableParams, setTableParams }: Readonly<Props>) {
  const [open, setOpen] = useState(false)
  const [isArchived, setIsArchived] = useState(tableParams.filter.is_archived)
  const [createdAt, setCreatedAt] = useState(tableParams.filter.created_at)
  const [fullName, setFullName] = useState(tableParams.filter.full_name)
  const [mobileNumber, setMobileNumber] = useState(tableParams.filter.mobile_number)
  const [email, setEmail] = useState(tableParams.filter.email)

  const handleResetClick = () => {
    setIsArchived("")
    setCreatedAt("")
    setFullName("")
    setMobileNumber("")
    setEmail("")

    setTableParams((prev: ITableParams) => ({
      ...prev,
      filter: {
        ...prev.filter,
        is_archived: "",
        created_at: "",
        full_name: "",
        mobile_number: "",
        email: ""
      },
      pagination: {
        ...prev.pagination,
        page_index: 1
      }
    }))

    setOpen(false)
  }

  const handleApplyClick = () => {
    setTableParams((prev: ITableParams) => ({
      ...prev,
      filter: {
        ...prev.filter,
        is_archived: isArchived,
        created_at: createdAt,
        full_name: fullName,
        mobile_number: mobileNumber,
        email: email
      },
      pagination: {
        ...prev.pagination,
        page_index: 1
      }
    }))

    setOpen(false)
  }

  return (
    <PopDlg open={open} onOpenChange={setOpen}>
      <PopDlgTrigger asChild>
        <Button
          className={clsx(
            "text-default flex h-auto w-full items-center justify-center gap-1.5 rounded-xl border bg-transparent px-4 py-3 text-sm font-semibold hover:bg-transparent md:w-auto",
            open ? "border-primary" : "border-[#868194]"
          )}
        >
          <IconFilter className="text-xl" />
          Filter
        </Button>
      </PopDlgTrigger>

      <PopDlgContent align="end" className="w-full md:w-[400px]">
        <div className="flex flex-col justify-center gap-4">
          <p className="text-main text-sm">Filter</p>

          <Select value={isArchived} onValueChange={(value) => setIsArchived(value)}>
            <SelectTrigger
              className={clsx("px-4 py-1 text-sm", isArchived ? "text-main" : "text-default")}
            >
              <SelectValue placeholder="State" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem className="capitalize" value={ALL}>
                ({capitalize(ALL)})
              </SelectItem>
              <SelectItem value="false">Submitted</SelectItem>
              <SelectItem value="true">Archived</SelectItem>
            </SelectContent>
          </Select>
          <Input
            placeholder="Submission date & time"
            value={createdAt}
            onChange={(e) => setCreatedAt(e.target.value)}
          />
          <Input
            placeholder="Full name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          <PatternFormat
            customInput={Input}
            placeholder="Mobile number"
            format="#### ### ###"
            mask="_"
            allowEmptyFormatting={false}
            value={mobileNumber ?? ""}
            onValueChange={(values, sourceInfo) => {
              setMobileNumber(values.formattedValue)
            }}
          />
          <Input
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <div className="flex justify-end gap-2.5">
            <Button
              className="text-default h-auto w-full border border-[#868194] bg-transparent px-6 py-4 text-sm font-semibold hover:bg-transparent md:w-auto"
              onClick={handleResetClick}
            >
              Reset
            </Button>
            <Button
              className="border-primary h-auto w-full border px-6 py-4 text-sm font-semibold md:w-auto"
              onClick={handleApplyClick}
            >
              Apply filter
            </Button>
          </div>
        </div>
      </PopDlgContent>
    </PopDlg>
  )
}
