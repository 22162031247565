import React, { Dispatch, SetStateAction, useEffect } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"

import { ApprovalUpload, LoadingButton } from "@/components/_uiext"
import useApplicationStore from "@/stores/useApplicationStore"

import { Dialog, DialogContent } from "@repo/ui/components/ui/dialog"
import { Form, FormField, FormItem, FormMessage } from "@repo/ui/components/ui/form"

import { useAppContext } from "../../AppContext"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const FormSchema = z.object({
  formal_approval_signed: z
    .any()
    .refine((file) => file !== null, "Formal approval signed is required")
})

type FormSchemaType = z.infer<typeof FormSchema>

export default function FormalDialog(props: Readonly<Props>) {
  const { open, setOpen } = props
  const store = useApplicationStore()
  const { data, setData } = useAppContext()

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      formal_approval_signed: null
    }
  })

  const onSubmit = (values: FormSchemaType) => {
    store.updateFormalApprovalAction(
      data?.uuid,
      {
        ...values
      },
      (newData) => {
        setData(newData)
        setOpen(false)
      }
    )
  }

  useEffect(() => {
    if (data?.formal_approval_signed) {
      form.setValue("formal_approval_signed", data?.formal_approval_signed)
    }
  }, [data])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="flex h-full flex-col gap-0 bg-white p-10 md:h-auto md:min-w-[640px]">
        <Form {...form}>
          <form
            className="flex h-full flex-col justify-between"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <div className="flex flex-col">
              <h1 className="text-main mb-4 text-center text-lg font-semibold">Formal approval</h1>

              <p className="text-default mb-6 text-center text-base font-normal">
                Please see below attachment of Formal approval for Application{" "}
                <span className="text-main">
                  #
                  {Number(data?.id ?? "")
                    .toString()
                    .padStart(10, "0")}
                </span>
              </p>

              <div className="mb-6 flex w-full flex-col gap-6 md:flex-row">
                <div className="flex-1">
                  <ApprovalUpload
                    readOnly
                    text="Download formal approval"
                    value={data?.formal_approval}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name="formal_approval_signed"
                    render={({ field }) => (
                      <FormItem>
                        <ApprovalUpload text="Upload formal approval signed" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <LoadingButton
                loading={store.loading}
                type="submit"
                className="w-full px-6 py-4 md:w-auto"
              >
                Submit
              </LoadingButton>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
