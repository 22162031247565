import React, { Dispatch, SetStateAction } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import st from "string-template"
import * as z from "zod"

import { LoadingButton } from "@/components/_uiext"
import useApplicationConsentStore from "@/stores/useApplicationConsentStore"

import { Dialog, DialogContent } from "@repo/ui/components/ui/dialog"
import { Form, FormControl, FormField, FormItem, FormMessage } from "@repo/ui/components/ui/form"
import { InputOTP, InputOTPGroup, InputOTPSlot } from "@repo/ui/components/ui/input-otp"
import { CLIENT_MSG } from "@repo/i18n"
import { SITE_TITLE } from "@repo/util/constant"

const FormSchema = z.object({
  sms_code: z.string().min(6, {
    message: CLIENT_MSG.FIELD_REQUIRED
  })
})

type FormSchemaType = z.infer<typeof FormSchema>

interface Props {
  consentUuid: string
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onSuccessCallback?: (newAppData: any) => void
}

export default function VerifyDialog(props: Readonly<Props>) {
  const { consentUuid, open, setOpen, onSuccessCallback } = props
  const store = useApplicationConsentStore()

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      sms_code: ""
    }
  })

  const handleCompletePin = () => {
    form.handleSubmit(onSubmit)()
  }

  const onSubmit = (values: FormSchemaType) => {
    store.verifyConsentAction(
      consentUuid,
      values.sms_code,
      (newData) => {
        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(newData)
        }
        setOpen(false)
      },
      () => {
        form.resetField("sms_code")
      }
    )
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        hideClose
        className="h-full bg-white p-8 md:h-auto"
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex h-full flex-col justify-center gap-8"
          >
            <h1 className="text-main text-center text-lg font-semibold">
              {st(CLIENT_MSG.APP_CONSENT_VERIFY_TITLE, { site_title: SITE_TITLE })}
            </h1>

            <div className="w-full">
              <FormField
                control={form.control}
                name="sms_code"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <InputOTP autoFocus maxLength={6} onComplete={handleCompletePin} {...field}>
                        <InputOTPGroup className="flex w-full justify-between gap-2 md:gap-4">
                          <InputOTPSlot className="h-[75px] w-full !rounded-xl border" index={0} />
                          <InputOTPSlot className="h-[75px] w-full rounded-xl border" index={1} />
                          <InputOTPSlot className="h-[75px] w-full rounded-xl border" index={2} />
                          <InputOTPSlot className="h-[75px] w-full rounded-xl border" index={3} />
                          <InputOTPSlot className="h-[75px] w-full rounded-xl border" index={4} />
                          <InputOTPSlot className="h-[75px] w-full !rounded-xl border" index={5} />
                        </InputOTPGroup>
                      </InputOTP>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex justify-center">
              <LoadingButton
                loading={store.loading}
                type="button"
                className="w-full px-6 py-4 md:w-auto"
              >
                Verify
              </LoadingButton>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
