import React, { useEffect } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { IconNext } from "@/components/_icons"
import { LoadingButton, StickyShortcut } from "@/components/_uiext"
import useApplicationDraftStore from "@/stores/useApplicationDraftStore"

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@repo/ui/components/ui/accordion"
import { Button } from "@repo/ui/components/ui/button"
import { APPLICATION_ASSET_TYPES, APPLICATION_LIABILITY_TYPES } from "@repo/util/constant"
import { CLIENT_MSG } from "@repo/i18n"

import { LOADING_TIMEOUT } from "../util"
import { useAppContext } from "../AppContext"

import IndividualALDetail from "./IndividualALDetail"
import IndividualALLiabilities from "./IndividualALLiabilities"
import IndividualALAssets from "./IndividualALAssets"

const FormSchema = z.object({
  individual_al: z.array(
    z.object({
      uuid: z.string().min(1).readonly(),
      role: z.string().readonly(),
      title: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      gender: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      first_name: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      middle_name: z.string(),
      last_name: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      marital_status: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),

      birth_date: z.date({ required_error: CLIENT_MSG.FIELD_REQUIRED }),
      birth_country: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      birth_place: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),

      residential_full_address: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      residential_street_address: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      residential_suburb: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      residential_state: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      residential_postcode: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      residential_years: z.number({ required_error: CLIENT_MSG.FIELD_REQUIRED }),
      residential_months: z.number().optional(),

      previous_full_address: z.string(),
      previous_street_address: z.string(),
      previous_suburb: z.string(),
      previous_state: z.string(),
      previous_postcode: z.string(),

      australian_resident: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      current_residential_status: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      postal_address: z.string(),
      email: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }).email(),
      mobile_number: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),

      drivers_license_number: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      card_number: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
      expiry_date: z.date({ required_error: CLIENT_MSG.FIELD_REQUIRED }),

      individual_assets: z
        .array(
          z.object({
            type: z.string().min(1),
            individual_uuid: z.string().min(1),
            description: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
            value: z.coerce.number({ invalid_type_error: CLIENT_MSG.FIELD_REQUIRED }),
            associated_debt: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
            lender: z.string().optional(),
            monthly_repayment: z.coerce.number().optional()
          })
        )
        .default([]),

      individual_liabilities: z
        .array(
          z.object({
            type: z.string().min(1),
            individual_uuid: z.string().min(1),
            description: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
            amount_owing: z.coerce.number({ invalid_type_error: CLIENT_MSG.FIELD_REQUIRED }),
            lender: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
            monthly_repayment: z.coerce.number({ invalid_type_error: CLIENT_MSG.FIELD_REQUIRED })
          })
        )
        .default([])
    })
  )
})

type FormSchemaType = z.infer<typeof FormSchema>

export default function IndividualAL() {
  const { steps, activeStep, setActiveStep, setLoading, data, setData } = useAppContext()
  const navigate = useNavigate()
  const store = useApplicationDraftStore()

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      individual_al: []
    }
  })

  const { fields } = useFieldArray({
    control: form.control,
    name: "individual_al"
  })

  const handleBackClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          ...form.getValues(),
          current_step: steps[activeStep - 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev - 1)

        setTimeout(() => {
          setLoading(false)
        }, LOADING_TIMEOUT)
      }
    )
  }

  const handleSaveClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          ...form.getValues(),
          current_step: steps[activeStep]
        }
      },
      (newData) => {
        setData(newData)

        setTimeout(() => {
          setLoading(false)
          navigate("/applications/list")
        }, LOADING_TIMEOUT)
      }
    )
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateAction(
      data.uuid,
      {
        params: {
          ...values,
          current_step: steps[activeStep + 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev + 1)
      }
    )
  }

  useEffect(() => {
    if (Object.keys(data).length) {
      const borrowerIndividuals = data?.borrower?.individuals ?? []
      let individual_al = borrowerIndividuals.map((bi: any) => {
        return {
          ...bi,
          residential_months: bi.residential_months ?? undefined,
          birth_date: new Date(bi.birth_date),
          expiry_date: new Date(bi.expiry_date),
          individual_assets:
            data?.assets?.filter(
              (a: any) =>
                a.type === APPLICATION_ASSET_TYPES.Individual && a.individual_uuid === bi.uuid
            ) ?? [],
          individual_liabilities:
            data?.liabilities?.filter(
              (a: any) =>
                a.type === APPLICATION_LIABILITY_TYPES.Individual && a.individual_uuid === bi.uuid
            ) ?? []
        }
      })
      form.reset({ individual_al: individual_al })
    } else {
      form.reset({ individual_al: [] })
    }
  }, [data])

  return (
    <FormProvider {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col pr-0 xl:pr-8">
            <h3 className="text-main mb-2 text-lg font-semibold">Individual’s A & L</h3>

            <p className="text-default mb-8 text-sm font-normal">
              Please enter Individual's assets & liabilities.
            </p>

            {fields.map((item, index) => (
              <Accordion key={item.id} type="multiple" className="w-full">
                <AccordionItem className="mb-4 rounded-2xl border p-4" value="entity-details">
                  <AccordionTrigger className="h-24 hover:no-underline md:h-12" tabIndex={-1}>
                    <div className="flex items-center justify-between gap-4">
                      <h3 className="text-main text-lg font-semibold">
                        {item.title}. {item.first_name} {item.middle_name} {item.last_name}
                      </h3>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="flex flex-col p-0">
                    <IndividualALDetail item={item} />
                    <IndividualALAssets index={index} item={item} />
                    <IndividualALLiabilities index={index} item={item} />
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            ))}
          </div>

          <StickyShortcut items={[]} />
        </div>

        <div className="flex w-full justify-between gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto bg-transparent px-0 py-4 font-semibold hover:bg-transparent"
            onClick={handleSaveClick}
          >
            Save and exit
          </Button>

          <div className="flex justify-end gap-2">
            <Button
              type="button"
              className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
              onClick={handleBackClick}
            >
              Back
            </Button>

            <LoadingButton
              loading={store.loading}
              type="submit"
              className="flex items-center gap-4 px-6 py-4 md:!w-auto"
            >
              Next
              <IconNext className="text-sm" />
            </LoadingButton>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
