import React from "react"

import Membership from "./bu/Membership"
import AustralianCreditRepresentative from "./bu/AustralianCreditRepresentative"
import ProfessionalIndemnityInsurance from "./bu/ProfessionalIndemnityInsurance"

interface Props {
  isView?: boolean
}

export default function BusinessUser(props: Readonly<Props>) {
  const { isView = false } = props

  return (
    <div className="flex w-full flex-col">
      <h2 className="text-main py-4 text-xl font-semibold">Business user information</h2>

      <div className="mb-4" id="membership">
        <Membership isView={isView} />
      </div>

      <div className="mb-4" id="australian-credit-representative">
        <AustralianCreditRepresentative isView={isView} />
      </div>

      <div id="professional-indemnity-insurance">
        <ProfessionalIndemnityInsurance isView={isView} />
      </div>
    </div>
  )
}
