import React, { Dispatch, SetStateAction, useState } from "react"

import clsx from "clsx"

import { IconFilter } from "@/components/_icons"
import { PopDlg, PopDlgContent, PopDlgTrigger } from "@/components/_uiext/PopDlg"
import { ITableParams } from "@/types/interface"

import { Button } from "@repo/ui/components/ui/button"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { ALL, ENTITY_TYPES } from "@repo/util/constant"
import { capitalize } from "@/utils/formatter"

interface Props {
  tableParams: ITableParams
  setTableParams: Dispatch<SetStateAction<ITableParams>>
}

export default function FilterPopover({ tableParams, setTableParams }: Readonly<Props>) {
  const [open, setOpen] = useState(false)
  const [isArchived, setIsArchived] = useState(tableParams.filter.is_archived)
  const [entityName, setEntityName] = useState(tableParams.filter.entity_name)
  const [entityType, setEntityType] = useState(tableParams.filter.entity_type)
  const [abn, setAbn] = useState(tableParams.filter.abn)

  const handleResetClick = () => {
    setIsArchived("")
    setEntityType("")
    setEntityName("")
    setAbn("")

    setTableParams((prev: ITableParams) => ({
      ...prev,
      filter: {
        ...prev.filter,
        is_archived: "",
        entity_type: "",
        entity_name: "",
        abn: ""
      },
      pagination: {
        ...prev.pagination,
        page_index: 1
      }
    }))
    setOpen(false)
  }

  const handleApplyClick = () => {
    setTableParams((prev: ITableParams) => ({
      ...prev,
      filter: {
        ...prev.filter,
        is_archived: isArchived,
        entity_type: entityType,
        entity_name: entityName,
        abn: abn
      },
      pagination: {
        ...prev.pagination,
        page_index: 1
      }
    }))
    setOpen(false)
  }

  return (
    <PopDlg open={open} onOpenChange={setOpen}>
      <PopDlgTrigger asChild>
        <Button
          className={clsx(
            "text-default flex h-auto w-full items-center justify-center gap-1.5 rounded-xl border bg-transparent px-4 py-3 text-sm font-semibold hover:bg-transparent md:w-auto",
            open ? "border-primary" : "border-[#868194]"
          )}
        >
          <IconFilter className="text-xl" />
          Filter
        </Button>
      </PopDlgTrigger>

      <PopDlgContent align="end" className="w-full md:w-[400px]">
        <div className="flex flex-col justify-center gap-4">
          <p className="text-main text-sm">Filter</p>

          <Select value={isArchived} onValueChange={(value) => setIsArchived(value)}>
            <SelectTrigger
              className={clsx("px-4 py-1 text-sm", isArchived ? "text-main" : "text-default")}
            >
              <SelectValue placeholder="State" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem className="capitalize" value={ALL}>
                ({capitalize(ALL)})
              </SelectItem>
              <SelectItem value="false">Active</SelectItem>
              <SelectItem value="true">Archived</SelectItem>
            </SelectContent>
          </Select>
          <Select
            value={entityType}
            onValueChange={(value: string) => {
              setEntityType(value)
            }}
          >
            <SelectTrigger
              className={clsx(
                "px-4 py-1 text-sm capitalize",
                entityType ? "text-main" : "text-default"
              )}
            >
              <SelectValue placeholder="Entity type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem className="capitalize" value={ALL}>
                ({capitalize(ALL)})
              </SelectItem>
              {Object.entries(ENTITY_TYPES).map(([k, v]) => (
                <SelectItem key={k} className="capitalize" value={v.key}>
                  {v.key}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Input
            placeholder="Entity name"
            value={entityName}
            onChange={(e) => setEntityName(e.target.value)}
          />
          <Input placeholder="ABN" value={abn} onChange={(e) => setAbn(e.target.value)} />

          <div className="flex justify-end gap-2.5">
            <Button
              className="text-default h-auto w-full border border-[#868194] bg-transparent px-6 py-4 text-sm font-semibold hover:bg-transparent md:w-auto"
              onClick={handleResetClick}
            >
              Reset
            </Button>
            <Button
              className="border-primary h-auto w-full border px-6 py-4 text-sm font-semibold md:w-auto"
              onClick={handleApplyClick}
            >
              Apply filter
            </Button>
          </div>
        </div>
      </PopDlgContent>
    </PopDlg>
  )
}
