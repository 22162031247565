import React, { useEffect, useRef, useState } from "react"

import SignatureCanvas from "react-signature-canvas"

import { LoadingButton } from "@/components/_uiext"

import { Button } from "@repo/ui/components/ui/button"
import { Dialog, DialogClose, DialogContent, DialogTrigger } from "@repo/ui/components/ui/dialog"

interface Props {
  value?: string
  onConfirm?: (signURI: string) => void
}

export default function SignatureDialog(props: Readonly<Props>) {
  const { value, onConfirm } = props
  const signRef = useRef<SignatureCanvas>(null)
  const [open, setOpen] = useState(false)

  const handleClearClick = () => {
    signRef?.current?.clear()
  }

  const handleOkClick = () => {
    if (typeof onConfirm === "function") {
      onConfirm(signRef?.current?.getTrimmedCanvas().toDataURL("image/png") ?? "")
      setOpen(false)
    }
  }

  useEffect(() => {
    if (value?.includes("data:image/") && open) {
      setTimeout(() => {
        signRef?.current?.clear()
        signRef?.current?.fromDataURL(value)
      }, 10)
    }

    if (value?.includes("http") && open) {
      setTimeout(() => {
        signRef?.current?.clear()
        signRef?.current?.fromDataURL(value)
      }, 10)
    }
  }, [value, open])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        {(!value || typeof value === "undefined") && (
          <div className="h-24 w-full rounded-xl border" />
        )}
        {typeof value === "string" && (
          <div className="flex h-24 w-full justify-center rounded-xl border">
            <img src={value} className="aspect-[16/9] h-24" />
          </div>
        )}
      </DialogTrigger>
      <DialogContent className="flex aspect-[16/9] flex-col gap-0 bg-white p-8 md:min-w-[640px]">
        <div className="mb-4 h-full w-full">
          <SignatureCanvas
            ref={signRef}
            canvasProps={{
              className: "cursor-pointer bg-background rounded-xl h-full w-full"
            }}
          />
        </div>

        <div className="flex justify-end gap-2">
          <Button
            className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
            onClick={handleClearClick}
          >
            Clear
          </Button>

          <LoadingButton
            className="flex items-center gap-4 px-6 py-4 md:!w-auto"
            onClick={handleOkClick}
          >
            OK
          </LoadingButton>
        </div>
      </DialogContent>
    </Dialog>
  )
}
