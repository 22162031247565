import React, { useEffect, useRef, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import pluralize from "pluralize"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { IconNext } from "@/components/_icons"
import { LoadingButton } from "@/components/_uiext"
import useAdminApplicationDraftStore from "@/stores/useAdminApplicationDraftStore"

import { Button } from "@repo/ui/components/ui/button"
import { Form, FormField, FormItem, FormMessage } from "@repo/ui/components/ui/form"
import { CLIENT_MSG } from "@repo/i18n"

import LenderItem from "../_components/LenderItem"
import LenderFilterPopover from "../_components/LenderFilterPopover"

import { LOADING_TIMEOUT } from "../util"
import { useAdminAppContext } from "../AdminAppContext"
import { LenderTraceDialog } from "../_components/LenderTraceDialog"

const FormSchema = z.object({
  lender_uuid: z
    .string({
      invalid_type_error: CLIENT_MSG.CHOOSE_ONE_ITEM
    })
    .min(1, { message: CLIENT_MSG.CHOOSE_ONE_ITEM })
})

type FormSchemaType = z.infer<typeof FormSchema>

export default function LenderSelection() {
  const { steps, activeStep, setActiveStep, setLoading, data, setData } = useAdminAppContext()
  const navigate = useNavigate()
  const store = useAdminApplicationDraftStore()

  const initialized = useRef(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [lenders, setLenders] = useState<any[]>([])
  const [excludedLenders, setExcludedLenders] = useState<any[]>([])
  const [filterParams, setFilterParams] = useState<any>({
    interest_rate: "",
    loan_value_ratio: "",
    minimum_loan_size: "",
    maximum_loan_size: "",
    minimum_loan_term: "",
    maximum_loan_term: "",
    payment_option: ""
  })

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      lender_uuid: undefined
    }
  })

  const initialize = () => {
    store.findLendersAction(data.uuid, { filter: filterParams }, (newData) => {
      if (
        newData?.filteredLenders?.filter((nd: any) => nd.uuid === form.getValues("lender_uuid"))
          ?.length === 0
      ) {
        form.setValue("lender_uuid", "")
      }
      setLenders(JSON.parse(JSON.stringify(newData.filteredLenders)))
      setExcludedLenders(JSON.parse(JSON.stringify(newData.excludedLenders)))

      setPageLoading(false)
    })
  }

  const handleBackClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          lender_uuid: form.getValues("lender_uuid") || null,
          current_step: steps[activeStep - 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev - 1)

        setTimeout(() => {
          setLoading(false)
        }, LOADING_TIMEOUT)
      }
    )
  }

  const handleSaveClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          lender_uuid: form.getValues("lender_uuid") || null,
          current_step: steps[activeStep]
        }
      },
      (newData) => {
        setData(newData)

        setTimeout(() => {
          setLoading(false)
          navigate("/admin/applications/list")
        }, LOADING_TIMEOUT)
      }
    )
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateAction(
      data.uuid,
      {
        params: {
          ...values,
          current_step: steps[activeStep + 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev + 1)
      }
    )
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      initialize()
    }
  }, [])

  useEffect(() => {
    form.reset({
      lender_uuid: data?.lender_uuid || null
    })
  }, [data])

  useEffect(() => {
    initialize()
  }, [filterParams])

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col pr-0">
            <div className="mb-6 flex flex-col gap-2 md:flex-row md:justify-between">
              <div className="flex flex-col">
                <h3 className="text-main mb-2 text-lg font-semibold">Lender selection</h3>

                <p className="text-default text-sm font-normal">
                  We have found{" "}
                  <span className="text-main font-medium">
                    {lenders?.length} potential {pluralize("lender", lenders?.length)}
                  </span>{" "}
                  that match your criteria.
                </p>
              </div>

              <LenderFilterPopover params={filterParams} setParams={setFilterParams} />
            </div>

            {pageLoading && <Skeleton className="h-60 w-full rounded-xl" />}

            {!pageLoading && (
              <>
                {lenders.length > 0 && (
                  <div className="w-full">
                    <FormField
                      control={form.control}
                      name="lender_uuid"
                      render={({ field }) => (
                        <FormItem>
                          <div className="flex flex-col gap-4">
                            {lenders.map((lender) => (
                              <LenderItem
                                key={lender.uuid}
                                isSelected={lender.uuid === field.value}
                                lender={lender}
                                data={data}
                                onClick={() => {
                                  field.onChange(lender.uuid)
                                }}
                              />
                            ))}
                          </div>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                )}

                {!lenders.length && (
                  <div className="text-destructive text-base font-normal">
                    No lenders found with the specified condition. For further assistance, please
                    contact <span className="font-semibold">support@fundsconnect.com.au</span>.
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <div className="flex w-full justify-between gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto bg-transparent px-0 py-4 font-semibold hover:bg-transparent"
            onClick={handleSaveClick}
          >
            Save and exit
          </Button>

          <div className="flex justify-end gap-2">
            <Button
              type="button"
              className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
              onClick={handleBackClick}
            >
              Back
            </Button>

            <LoadingButton
              loading={store.locked}
              type="submit"
              className="flex items-center gap-4 px-6 py-4 md:!w-auto"
            >
              Next
              <IconNext className="text-sm" />
            </LoadingButton>
          </div>
        </div>

        <LenderTraceDialog data={data} excludedLenders={excludedLenders} />
      </form>
    </Form>
  )
}
