import React, { useState } from "react"

import { format } from "date-fns"
import { useFormContext } from "react-hook-form"

import { IconCalendar } from "@/components/_icons"

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/components/ui/popover"
import { Calendar } from "@repo/ui/components/ui/calendar"

interface Props {
  isView?: boolean
}

export default function AustralianCreditRepresentative(props: Readonly<Props>) {
  const { isView } = props
  const form = useFormContext()
  const [openCalendar, setOpenCalendar] = useState(false)

  return (
    <div className="flex w-full flex-col">
      <h3 className="text-main mb-4 py-4 text-lg font-semibold">
        Australian credit representative
      </h3>

      <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="acr_certificate"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Australian credit representative number
                </FormLabel>
                <Input disabled={isView} {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="acr_expiry"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel className="text-main mb-1 text-sm font-normal">Expiry date</FormLabel>
                <Popover
                  open={openCalendar}
                  onOpenChange={(open) => {
                    if (!isView) {
                      setOpenCalendar(open)
                    }
                  }}
                >
                  <PopoverTrigger asChild>
                    <FormControl>
                      <div className="relative">
                        <Input
                          className="cursor-pointer"
                          readOnly
                          disabled={isView}
                          value={field.value ? format(field.value, "dd/MM/yyyy") : ""}
                        />
                        <div className="absolute right-[17.5px] top-[50%] translate-y-[-50%]">
                          <IconCalendar className="text-default text-lg" />
                        </div>
                      </div>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      captionLayout="dropdown-buttons"
                      fromYear={new Date().getFullYear()}
                      toYear={new Date().getFullYear() + 10}
                      mode="single"
                      selected={field.value}
                      defaultMonth={field.value}
                      onSelect={(date) => {
                        field.onChange(date)
                        setOpenCalendar(false)
                      }}
                      disabled={(date) => date < new Date("1900-01-01")}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  )
}
