import React, { useEffect } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import clsx from "clsx"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { IconAddBorrower, IconNext } from "@/components/_icons"
import { LoadingButton } from "@/components/_uiext"
import useApplicationDraftStore from "@/stores/useApplicationDraftStore"

import { Button } from "@repo/ui/components/ui/button"
import { Checkbox } from "@repo/ui/components/ui/checkbox"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { CLIENT_MSG } from "@repo/i18n"
import { SITE_TITLE } from "@repo/util/constant"

import BorrowerAutoComplete from "../_components/BorrowerAutoComplete"
import { LOADING_TIMEOUT } from "../util"
import { useAppContext } from "../AppContext"

const FormSchema = z.object({
  borrower_uuid: z.string({
    invalid_type_error: CLIENT_MSG.FIELD_REQUIRED,
    required_error: CLIENT_MSG.FIELD_REQUIRED
  }),
  applicant_type: z.string(),
  is_authorised: z
    .boolean({
      invalid_type_error: CLIENT_MSG.FIELD_REQUIRED,
      required_error: CLIENT_MSG.FIELD_REQUIRED
    })
    .refine((val) => val === true, {
      message: CLIENT_MSG.FIELD_REQUIRED
    }),
  is_tfn_removed: z
    .boolean({
      invalid_type_error: CLIENT_MSG.FIELD_REQUIRED,
      required_error: CLIENT_MSG.FIELD_REQUIRED
    })
    .refine((val) => val === true, {
      message: CLIENT_MSG.FIELD_REQUIRED
    }),
  is_acknowledged: z
    .boolean({
      invalid_type_error: CLIENT_MSG.FIELD_REQUIRED,
      required_error: CLIENT_MSG.FIELD_REQUIRED
    })
    .refine((val) => val === true, {
      message: CLIENT_MSG.FIELD_REQUIRED
    }),
  is_financed: z
    .boolean({
      invalid_type_error: CLIENT_MSG.FIELD_REQUIRED,
      required_error: CLIENT_MSG.FIELD_REQUIRED
    })
    .refine((val) => val === true, {
      message: CLIENT_MSG.FIELD_REQUIRED
    })
})

type FormSchemaType = z.infer<typeof FormSchema>

export default function BorrowerSelection() {
  const { steps, activeStep, setActiveStep, setLoading, data, setData } = useAppContext()
  const store = useApplicationDraftStore()
  const navigate = useNavigate()

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      borrower_uuid: undefined,
      applicant_type: "",
      is_authorised: false,
      is_tfn_removed: false,
      is_acknowledged: false,
      is_financed: false
    }
  })

  const handleAddBorrowerClick = () => {
    navigate("/borrowers/add")
  }

  const handleBackClick = () => {
    navigate(-1)
  }

  const handleSaveClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          ...form.getValues(),
          is_visible: true,
          current_step: steps[activeStep]
        }
      },
      (newData) => {
        setData(newData)

        setTimeout(() => {
          setLoading(false)
          navigate("/applications/list")
        }, LOADING_TIMEOUT)
      }
    )
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateAction(
      data.uuid,
      {
        params: {
          ...values,
          is_visible: true,
          current_step: steps[activeStep + 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev + 1)
      }
    )
  }

  useEffect(() => {
    form.reset({
      borrower_uuid: data?.borrower_uuid,
      applicant_type: data?.applicant_type ?? "",
      is_authorised: data?.is_authorised ?? false,
      is_tfn_removed: data?.is_tfn_removed ?? false,
      is_acknowledged: data?.is_acknowledged ?? false,
      is_financed: data?.is_financed ?? false
    })
  }, [data])

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col pr-0 xl:pr-[352px]">
            <h3 className="text-main mb-4 text-lg font-semibold">
              Select existing borrower or add a new borrower
            </h3>

            <div className="mb-6">
              <FormField
                control={form.control}
                name="borrower_uuid"
                render={({ field }) => (
                  <FormItem>
                    <BorrowerAutoComplete
                      applicationUuid={data?.uuid}
                      disabled={false}
                      value={field.value ?? ""}
                      onValueChange={(v) => {
                        field.onChange(v.uuid)
                        form.setValue("applicant_type", v.entity_type)
                      }}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-6 flex items-center justify-between">
              <div className="h-[1px] w-full border-t border-[#CDCDCD]" />
              <div className="bg-white px-6 text-sm">or</div>
              <div className="h-[1px] w-full border-t border-[#CDCDCD]" />
            </div>

            <div className="mb-6 w-full">
              <Button
                type="button"
                onClick={handleAddBorrowerClick}
                className="h-auto w-full gap-4 px-6 py-4"
              >
                <IconAddBorrower className="text-xl" />
                Add new borrower
              </Button>
            </div>

            <div className="mb-6 w-full">
              <FormField
                control={form.control}
                name="is_authorised"
                render={({ field }) => (
                  <FormItem className="flex items-start space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked)
                        }}
                        className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                      />
                    </FormControl>
                    <div className="flex flex-col">
                      <FormLabel className="text-main text-sm font-normal">
                        I am authorised to provide all personal information included in this
                        application to FundsConnect for the purpose of FundsConnect assessing this
                        application.
                      </FormLabel>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-6 w-full">
              <FormField
                control={form.control}
                name="is_tfn_removed"
                render={({ field }) => (
                  <FormItem className="flex items-start space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked)
                        }}
                        className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                      />
                    </FormControl>
                    <div className="flex flex-col">
                      <FormLabel className="text-main text-sm font-normal">
                        I confirm that I have removed TFNs (tax file numbers) from all supporting
                        documentation associated with this application.
                      </FormLabel>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-6 w-full">
              <FormField
                control={form.control}
                name="is_acknowledged"
                render={({ field }) => (
                  <FormItem className="flex items-start space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked)
                        }}
                        className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                      />
                    </FormControl>
                    <div className="flex flex-col">
                      <FormLabel className="text-main text-sm font-normal">
                        I acknowledge that {SITE_TITLE} is not a lender. All loans are subject to
                        the lender's credit approval processes, terms, conditions, fees, and
                        charges, which may change at the lender's discretion.
                      </FormLabel>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-6 w-full">
              <FormField
                control={form.control}
                name="is_financed"
                render={({ field }) => (
                  <FormItem className="flex items-start space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked)
                        }}
                        className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                      />
                    </FormControl>
                    <div className="flex flex-col">
                      <FormLabel className="text-main text-sm font-normal">
                        By proceeding with this finance application, I agree to receive a brokerage
                        fee of 0.55% (GST inclusive) which will be paid on the successful settlement
                        of this loan.
                      </FormLabel>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between pb-2 pt-6">
          <Button
            type="button"
            className={clsx(
              "text-default h-auto bg-transparent px-0 py-4 font-semibold hover:bg-transparent",
              !form.watch("borrower_uuid") ? "invisible" : "visible"
            )}
            onClick={handleSaveClick}
          >
            Save and exit
          </Button>

          <div className="flex justify-end gap-2">
            <Button
              type="button"
              className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
              onClick={handleBackClick}
            >
              Back
            </Button>

            <LoadingButton
              loading={store.loading}
              type="submit"
              className="flex items-center gap-4 px-6 py-4 md:!w-auto"
            >
              Next
              <IconNext className="text-sm" />
            </LoadingButton>
          </div>
        </div>
      </form>
    </Form>
  )
}
