import React, { useEffect, useState } from "react"

import { Text, View } from "@react-pdf/renderer"
import numeral from "numeral"

import {
  APPLICATION_ASSET_TYPES,
  APPLICATION_LIABILITY_TYPES,
  ENTITY_TYPES,
  TRUST_TYPES,
  YN
} from "@repo/util/constant"

interface Props {
  data: Record<string, any>
}

export default function ContentEntityAL(props: Readonly<Props>) {
  const { data } = props

  const [entityAssets, setEntityAssets] = useState<any[]>([])
  const [entityLiabilities, setEntityLiabilities] = useState<any[]>([])

  useEffect(() => {
    if (Object.keys(data ?? {}).length) {
      setEntityAssets(
        data?.assets?.filter((a: any) => a.type === APPLICATION_ASSET_TYPES.Entity) ?? []
      )
      setEntityLiabilities(
        data?.liabilities?.filter((l: any) => l.type === APPLICATION_LIABILITY_TYPES.Entity) ?? []
      )
    } else {
      setEntityAssets([])
      setEntityLiabilities([])
    }
  }, [data])

  return (
    <View style={{ display: "flex", flexDirection: "column" }} wrap={true}>
      <Text
        style={{
          color: "#0E052B",
          fontWeight: 600,
          fontSize: 14,
          borderBottom: "1px solid #CDCDCD"
        }}
      >
        Entity's A & L
      </Text>

      <View
        style={{
          paddingLeft: 32,
          paddingVertical: 16,
          display: "flex",
          flexDirection: "column",
          gap: 8
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>Entity details</Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Entity type
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {data?.borrower?.entity_type}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Entity name
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {data?.borrower?.entity_name}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>ABN</Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {data?.borrower?.abn}
          </Text>
        </View>

        {(data?.borrower?.entity_type === ENTITY_TYPES.Company.key ||
          data?.borrower?.entity_type === ENTITY_TYPES.Trust.key ||
          data?.borrower?.entity_type === ENTITY_TYPES.SMSF.key) && (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 16
            }}
          >
            <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>ACN</Text>
            <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
              {data?.borrower?.acn}
            </Text>
          </View>
        )}

        {data?.borrower?.entity_type === ENTITY_TYPES.Trust.key && (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 16
            }}
          >
            <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
              Type of trust
            </Text>
            <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
              {data?.borrower?.trust_type}
            </Text>
          </View>
        )}

        {data?.borrower?.entity_type === ENTITY_TYPES.Trust.key &&
          data?.borrower?.trust_type === TRUST_TYPES.Other.key && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Please enter your type of trust
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {data?.borrower?.trust_type_other}
              </Text>
            </View>
          )}

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Goods & services tax (GST)
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {data?.borrower?.gst_registered ?? ""}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Principal place of business (not a PO Box)
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {data?.borrower?.principal_place}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Registered place of business
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {data?.borrower?.registered_place}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Entity email address
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {data?.borrower?.entity_email}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Entity contact number
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {data?.borrower?.entity_contact_number}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
            Primary business activity
          </Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {data?.borrower?.primary_business_activity}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>Website</Text>
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
            {data?.borrower?.website}
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>Entity assets</Text>
        </View>

        {entityAssets.map((item, index) => (
          <React.Fragment key={item.id}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Description of asset
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.description}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Value of asset
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {numeral(item.value).format("$ 0,0[.]00")}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Does your asset have an associated debt?
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.associated_debt}
              </Text>
            </View>

            {item.associated_debt === YN.Yes && (
              <>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 16
                  }}
                >
                  <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                    Who is the lender?
                  </Text>
                  <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                    {item.lender}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 16
                  }}
                >
                  <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                    Monthly repayment / limit
                  </Text>
                  <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                    {numeral(item.monthly_repayment).format("$ 0,0[.]00")}
                  </Text>
                </View>
              </>
            )}

            <View style={{ borderBottom: "1px dashed #CDCDCD" }} />
          </React.Fragment>
        ))}

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16
          }}
        >
          <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
            Entity liabilities
          </Text>
        </View>

        {entityLiabilities.map((item, index) => (
          <React.Fragment key={item.id}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Description of liability
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.description}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Amount owing
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {numeral(item.amount_owing).format("$ 0,0[.]00")}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Who is the lender?
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {item.lender}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 12, flex: 1 }}>
                Monthly repayment / limit
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12, flex: 1 }}>
                {numeral(item.monthly_repayment).format("$ 0,0[.]00")}
              </Text>
            </View>

            <View style={{ borderBottom: "1px dashed #CDCDCD" }} />
          </React.Fragment>
        ))}
      </View>
    </View>
  )
}
