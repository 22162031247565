import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

import clsx from "clsx"

import { IconFilter } from "@/components/_icons"
import { PopDlg, PopDlgContent, PopDlgTrigger } from "@/components/_uiext/PopDlg"
import { ITableParams } from "@/types/interface"

import { Button } from "@repo/ui/components/ui/button"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { ALL, FUNDING_SOLUTIONS } from "@repo/util/constant"
import { capitalize } from "@/utils/formatter"

interface Props {
  tableParams: ITableParams
  setTableParams: Dispatch<SetStateAction<ITableParams>>
}

export default function FilterPopover({ tableParams, setTableParams }: Readonly<Props>) {
  const [open, setOpen] = useState(false)
  const [isArchived, setIsArchived] = useState(tableParams.filter.is_archived)
  const [fundingSolution, setFundingSolution] = useState(tableParams.filter.funding_solution)
  const [lenderName, setLenderName] = useState(tableParams.filter.lender_name)
  const [lenderNumber, setLenderNumber] = useState(tableParams.filter.lender_number)
  const [lenderEmail, setLenderEmail] = useState(tableParams.filter.lender_email)
  const [lenderMobileNumber, setLenderMobileNumber] = useState(
    tableParams.filter.lender_mobile_number
  )

  const handleResetClick = () => {
    setIsArchived("")
    setFundingSolution("")
    setLenderName("")
    setLenderNumber("")
    setLenderEmail("")
    setLenderMobileNumber("")

    setTableParams((prev: ITableParams) => ({
      ...prev,
      filter: {
        ...prev.filter,
        is_archived: "",
        funding_solution: "",
        lender_name: "",
        lender_number: "",
        lender_email: "",
        lender_mobile_number: ""
      },
      pagination: {
        ...prev.pagination,
        page_index: 1
      }
    }))
    setOpen(false)
  }

  const handleApplyClick = () => {
    setTableParams((prev: ITableParams) => ({
      ...prev,
      filter: {
        ...prev.filter,
        is_archived: isArchived,
        funding_solution: fundingSolution,
        lender_name: lenderName,
        lender_number: lenderNumber,
        lender_email: lenderEmail,
        lender_mobile_number: lenderMobileNumber
      },
      pagination: {
        ...prev.pagination,
        page_index: 1
      }
    }))
    setOpen(false)
  }

  useEffect(() => {
    setLenderName(tableParams.filter.lender_name)
  }, [tableParams.filter])

  return (
    <PopDlg open={open} onOpenChange={setOpen}>
      <PopDlgTrigger asChild>
        <Button
          className={clsx(
            "text-default flex h-auto w-full items-center justify-center gap-1.5 rounded-xl border bg-transparent px-4 py-3 text-sm font-semibold hover:bg-transparent md:w-auto",
            open ? "border-primary" : "border-[#868194]"
          )}
        >
          <IconFilter className="text-xl" />
          Filter
        </Button>
      </PopDlgTrigger>

      <PopDlgContent align="end" className="w-full md:w-[400px]">
        <div className="flex flex-col justify-center gap-4">
          <p className="text-main text-sm">Filter</p>

          <Select value={isArchived} onValueChange={(value) => setIsArchived(value)}>
            <SelectTrigger
              className={clsx(
                "px-4 py-1 text-sm capitalize",
                isArchived ? "text-main" : "text-default"
              )}
            >
              <SelectValue placeholder="State" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem className="capitalize" value={ALL}>
                ({ALL})
              </SelectItem>
              <SelectItem value="false">Active</SelectItem>
              <SelectItem value="true">Archived</SelectItem>
            </SelectContent>
          </Select>
          <Select
            value={fundingSolution}
            onValueChange={(value: string) => {
              setFundingSolution(value)
            }}
          >
            <SelectTrigger
              className={clsx("px-4 py-1 text-sm", fundingSolution ? "text-main" : "text-default")}
            >
              <SelectValue placeholder="Funding solution" />
            </SelectTrigger>

            <SelectContent>
              <SelectItem className="capitalize" value={ALL}>
                ({capitalize(ALL)})
              </SelectItem>
              {Object.entries(FUNDING_SOLUTIONS)
                .sort()
                .map(([k, v]) => (
                  <SelectItem key={k} value={v.key}>
                    {v.key}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
          <Input
            placeholder="Lender number"
            value={lenderNumber}
            onChange={(e) => setLenderNumber(e.target.value)}
          />
          <Input
            placeholder="Lender name"
            value={lenderName}
            onChange={(e) => setLenderName(e.target.value)}
          />
          <Input
            placeholder="Lender email address"
            value={lenderEmail}
            onChange={(e) => setLenderEmail(e.target.value)}
          />
          <Input
            placeholder="Lender phone number"
            value={lenderMobileNumber}
            onChange={(e) => setLenderMobileNumber(e.target.value)}
          />

          <div className="flex justify-end gap-2.5">
            <Button
              className="text-default h-auto w-full border border-[#868194] bg-transparent px-6 py-4 text-sm font-semibold hover:bg-transparent md:w-auto"
              onClick={handleResetClick}
            >
              Reset
            </Button>
            <Button
              className="border-primary h-auto w-full border px-6 py-4 text-sm font-semibold md:w-auto"
              onClick={handleApplyClick}
            >
              Apply filter
            </Button>
          </div>
        </div>
      </PopDlgContent>
    </PopDlg>
  )
}
