import React, { useEffect, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { useInView } from "react-intersection-observer"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { LoadingPage, StickyShortcut } from "@/components/_uiext"
import { LOADING_TIMEOUT } from "@/utils/constants"

import { Button } from "@repo/ui/components/ui/button"
import { ENTITY_TYPES } from "@repo/util/constant"
import { CLIENT_MSG } from "@repo/i18n"

import EntityDetails from "@/pages/borrowers/_sections/EntityDetails"
import MainContactPerson from "@/pages/borrowers/_sections/MainContactPerson"
import IndividualDetails from "@/pages/borrowers/_sections/IndividualDetails"
import EntityTypeQuestions from "@/pages/borrowers/_sections/EntityTypeQuestions"

const FormSchema = z
  .object({
    entity_type: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    temp_abn: z
      .string({ required_error: CLIENT_MSG.FIELD_REQUIRED })
      .min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    entity_name: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    abn: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    acn: z.string(),
    trust_type: z.string(),
    trust_type_other: z.string(),
    gst_registered: z.string().default(""),
    principal_place: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    registered_place: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    entity_email: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }).email(),
    entity_contact_number: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    primary_business_activity: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    website: z.string().url().optional(),

    mcp_first_name: z.string(),
    mcp_middle_name: z.string(),
    mcp_last_name: z.string(),
    mcp_email: z.string(),
    mcp_mobile_number: z.string(),
    mcp_position: z.string(),

    etq_trust_is_settlor: z.boolean().optional().default(false),
    etq_trust_settlor_name: z.string(),
    etq_trust_settlor_street_address: z.string(),
    etq_trust_settlor_suburb: z.string(),
    etq_trust_settlor_state: z.string(),
    etq_trust_settlor_postcode: z.string(),
    etq_trust_is_exposed_person: z.boolean().optional().default(false),

    individuals: z.array(
      z.object({
        role: z.string(),
        title: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
        gender: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
        first_name: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
        middle_name: z.string(),
        last_name: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
        marital_status: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),

        birth_date: z.date({ required_error: CLIENT_MSG.FIELD_REQUIRED }),
        birth_country: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
        birth_place: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),

        residential_full_address: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
        residential_street_address: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
        residential_suburb: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
        residential_state: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
        residential_postcode: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
        residential_years: z.number({ required_error: CLIENT_MSG.FIELD_REQUIRED }),
        residential_months: z.number().optional(),

        previous_full_address: z.string(),
        previous_street_address: z.string(),
        previous_suburb: z.string(),
        previous_state: z.string(),
        previous_postcode: z.string(),

        australian_resident: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
        current_residential_status: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
        postal_address: z.string(),
        email: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }).email(),
        mobile_number: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),

        drivers_license_number: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
        card_number: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
        expiry_date: z.date({ required_error: CLIENT_MSG.FIELD_REQUIRED }),

        rq_shareholder_is_beneficial_owner: z.boolean().optional().default(false),
        rq_shareholder_is_exposed_person: z.boolean().optional().default(false),
        rq_director_is_exposed_person: z.boolean().optional().default(false),
        rq_beneficiary_is_exposed_person: z.boolean().optional().default(false)
      })
    )
  })
  .superRefine((data: any, ctx) => {
    const entityTypesRequiringACN = [
      ENTITY_TYPES.Company.key,
      ENTITY_TYPES.Trust.key,
      ENTITY_TYPES.SMSF.key
    ]
    if (entityTypesRequiringACN.includes(data.entity_type) && !data.acn) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: CLIENT_MSG.FIELD_REQUIRED,
        path: ["acn"]
      })
    }

    const entityTypesRequiringMCP = [
      ENTITY_TYPES.Company.key,
      ENTITY_TYPES.Trust.key,
      ENTITY_TYPES.Partnership.key,
      ENTITY_TYPES.SMSF.key
    ]
    ;["mcp_first_name", "mcp_last_name", "mcp_email", "mcp_mobile_number", "mcp_position"].forEach(
      (field) => {
        if (entityTypesRequiringMCP.includes(data.entity_type) && !data[field]) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: CLIENT_MSG.FIELD_REQUIRED,
            path: [field]
          })
        }
      }
    )

    const entityTypesRequiringRole = [
      ENTITY_TYPES.Company.key,
      ENTITY_TYPES.Trust.key,
      ENTITY_TYPES.SMSF.key
    ]
    if (entityTypesRequiringRole.includes(data.entity_type)) {
      data.individuals.forEach((individual: any, index: number) => {
        if (!individual.role) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: CLIENT_MSG.FIELD_REQUIRED,
            path: ["individuals", index, "role"]
          })
        }
      })
    }

    ;[
      "etq_trust_settlor_name",
      "etq_trust_settlor_street_address",
      "etq_trust_settlor_suburb",
      "etq_trust_settlor_state",
      "etq_trust_settlor_postcode"
    ].forEach((field) => {
      if (
        data.entity_type === ENTITY_TYPES.Trust.key &&
        data.etq_trust_is_settlor &&
        !data[field]
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: CLIENT_MSG.FIELD_REQUIRED,
          path: [field]
        })
      }
    })
  })

type FormSchemaType = z.infer<typeof FormSchema>

interface Props {
  data: Record<string, any>
}

export default function MainForm({ data }: Readonly<Props>) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  const { ref: ref1, inView: inView1 } = useInView({ threshold: 0.1 })
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 0.1 })
  const { ref: ref3, inView: inView3 } = useInView({ threshold: 0.1 })
  const { ref: ref4, inView: inView4 } = useInView({ threshold: 0.1 })

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      entity_type: "",
      entity_name: "",
      abn: "",
      acn: "",
      trust_type: "",
      trust_type_other: "",
      gst_registered: "",
      principal_place: "",
      registered_place: "",
      entity_email: "",
      entity_contact_number: "",
      primary_business_activity: "",
      website: undefined,

      mcp_first_name: "",
      mcp_middle_name: "",
      mcp_last_name: "",
      mcp_email: "",
      mcp_mobile_number: "",
      mcp_position: "",

      etq_trust_is_settlor: false,
      etq_trust_settlor_name: "",
      etq_trust_settlor_street_address: "",
      etq_trust_settlor_suburb: "",
      etq_trust_settlor_state: "",
      etq_trust_settlor_postcode: "",
      etq_trust_is_exposed_person: false,

      individuals: []
    }
  })

  const handleCancelClick = () => {
    navigate("/admin/borrowers/list")
  }

  useEffect(() => {
    if (Object.keys(data).length) {
      form.reset({
        entity_type: data.entity_type ?? "",
        temp_abn: data.entity_name ?? "",
        entity_name: data.entity_name ?? "",
        abn: data.abn ?? "",
        acn: data.acn ?? "",
        trust_type: data.trust_type ?? "",
        trust_type_other: data.trust_type_other ?? "",
        gst_registered: data.gst_registered ?? "",
        principal_place: data.principal_place ?? "",
        registered_place: data.registered_place ?? "",
        entity_email: data.entity_email ?? "",
        entity_contact_number: data.entity_contact_number ?? "",
        primary_business_activity: data.primary_business_activity ?? "",
        website: data.website ?? undefined,

        mcp_first_name: data.mcp_first_name ?? "",
        mcp_middle_name: data.mcp_middle_name ?? "",
        mcp_last_name: data.mcp_last_name ?? "",
        mcp_email: data.mcp_email ?? "",
        mcp_mobile_number: data.mcp_mobile_number ?? "",
        mcp_position: data.mcp_position ?? "",

        etq_trust_is_settlor: data.etq_trust_is_settlor ?? false,
        etq_trust_settlor_name: data.etq_trust_settlor_name ?? "",
        etq_trust_settlor_street_address: data.etq_trust_settlor_street_address ?? "",
        etq_trust_settlor_suburb: data.etq_trust_settlor_suburb ?? "",
        etq_trust_settlor_state: data.etq_trust_settlor_state ?? "",
        etq_trust_settlor_postcode: data.etq_trust_settlor_postcode ?? "",
        etq_trust_is_exposed_person: data.etq_trust_is_exposed_person ?? false,

        individuals:
          data.individuals.map((bi: any) => ({
            ...bi,
            birth_date: new Date(bi.birth_date),
            expiry_date: new Date(bi.expiry_date)
          })) ?? []
      })

      setTimeout(() => {
        setLoading(false)
      }, LOADING_TIMEOUT)
    }
  }, [data])

  return (
    <FormProvider {...form}>
      <LoadingPage loading={loading}>
        <form className="flex flex-col">
          <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
            <div className="flex w-full flex-1 flex-col gap-5 xl:pr-8">
              <div ref={ref1} id="entity-details">
                <EntityDetails isView />
              </div>

              {form.watch("entity_type") &&
                form.watch("entity_type") !== ENTITY_TYPES.SoleTrader.key &&
                form.watch("entity_type") !== ENTITY_TYPES.Individual.key && (
                  <div ref={ref2} id="main-contact-person">
                    <MainContactPerson isView />
                  </div>
                )}

              <div ref={ref3} id="individual-details">
                <IndividualDetails isView fieldName="individuals" />
              </div>

              {form.watch("entity_type") &&
                form.watch("entity_type") === ENTITY_TYPES.Trust.key && (
                  <div ref={ref4} id="entity-type-questions">
                    <EntityTypeQuestions />
                  </div>
                )}
            </div>

            <StickyShortcut
              items={[
                {
                  id: "entity-details",
                  label: "Entity details",
                  inView: inView1
                },
                ...(form.watch("entity_type") &&
                form.watch("entity_type") !== ENTITY_TYPES.SoleTrader.key &&
                form.watch("entity_type") !== ENTITY_TYPES.Individual.key
                  ? [
                      {
                        id: "main-contact-person",
                        label: "Main contact person",
                        inView: inView2
                      }
                    ]
                  : []),
                {
                  id: "individual-details",
                  label: "Individual details",
                  inView: inView3
                },
                ...(form.watch("entity_type") &&
                form.watch("entity_type") === ENTITY_TYPES.Trust.key
                  ? [
                      {
                        id: "entity-type-questions",
                        label: "Questions",
                        inView: inView4
                      }
                    ]
                  : [])
              ]}
            />
          </div>

          <div className="flex w-full justify-end gap-2 pb-2 pt-6">
            <Button
              type="button"
              className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
              onClick={handleCancelClick}
            >
              Back
            </Button>
          </div>
        </form>
      </LoadingPage>
    </FormProvider>
  )
}
