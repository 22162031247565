import React, { useState, useRef, useCallback, type KeyboardEvent } from "react"

import clsx from "clsx"
import { Command as CommandPrimitive } from "cmdk"
import { LuCheck, LuTrash2 } from "react-icons/lu"

import { Avatar, AvatarFallback, AvatarImage } from "@repo/ui/components/ui/avatar"
import {
  CommandGroup,
  CommandItem,
  CommandList,
  CommandInput,
  CommandEmpty
} from "@repo/ui/components/ui/command"
import { ScrollArea } from "@repo/ui/components/ui/scroll-area"

interface Props {
  disabled?: boolean
  options: any[]
  value?: any[]
  onValueChange?: (value: Record<string, any>) => void
}

export default function IndividualAutoComplete(props: Readonly<Props>) {
  const { disabled = false, options, value, onValueChange } = props

  const inputRef = useRef<HTMLInputElement>(null)
  const [open, setOpen] = useState(false)
  const [selectedItems, setSelectedItems] = useState<any[]>(value ?? [])
  const [items, setItems] = useState<any[]>(options)

  const [inputValue, setInputValue] = useState<string>("")

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current
      if (!input) {
        return
      }

      // Keep the options displayed when the user is typing
      if (!open) {
        setOpen(true)
      }

      if (event.key === "Escape") {
        input.blur()
        event.stopPropagation()
      }
    },
    [open]
  )

  const handleBlur = useCallback(() => {
    setOpen(false)
  }, [selectedItems])

  const handleSelectOption = useCallback(
    (selectedOption: any) => {
      let updatedValue: any[] = JSON.parse(JSON.stringify(selectedItems))

      if (updatedValue.filter((v: any) => v.uuid === selectedOption.uuid).length > 0) {
        updatedValue = updatedValue.filter((v: any) => v.uuid !== selectedOption.uuid)
      } else {
        updatedValue.push(selectedOption)
      }

      setSelectedItems(updatedValue)
      onValueChange?.(updatedValue)

      // This is a hack to prevent the input from being focused after the user selects an option
      setTimeout(() => {
        inputRef?.current?.blur()
      }, 0)
    },
    [selectedItems, setSelectedItems, onValueChange, inputRef]
  )

  const handleRemoveItem = (uuid: string) => () => {
    let updatedValue: any[] = JSON.parse(JSON.stringify(selectedItems))
    updatedValue = updatedValue.filter((v: any) => v.uuid !== uuid)

    setSelectedItems(updatedValue)
    onValueChange?.(updatedValue)
  }

  return (
    <CommandPrimitive onKeyDown={handleKeyDown} shouldFilter={false}>
      <CommandInput
        hideSearchIcon
        hideDropdownIcon={false}
        ref={inputRef}
        value={inputValue}
        onValueChange={setInputValue}
        onBlur={handleBlur}
        onFocus={() => setOpen(true)}
        placeholder="Please select"
        disabled={disabled}
        className="text-main placeholder:text-default h-12 text-sm"
      />

      <div className="relative mt-1">
        <div
          className={clsx(
            "animate-in fade-in-0 zoom-in-95 absolute top-0 z-10 w-full rounded-xl bg-white outline-none",
            open ? "block" : "hidden"
          )}
        >
          <CommandList className="rounded-lg ring-1 ring-slate-200">
            <CommandEmpty>No assignees found.</CommandEmpty>
            <CommandGroup>
              {items.map((option) => (
                <CommandItem
                  key={option.uuid}
                  value={option.uuid}
                  onMouseDown={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                  }}
                  onSelect={() => handleSelectOption(option)}
                  className="flex items-center justify-between"
                >
                  <div className="flex items-center gap-2">
                    <Avatar className="h-8 w-8">
                      <AvatarImage
                        src={option.image}
                        alt={`${option.first_name} ${option.last_name}`}
                        className="object-cover"
                      />
                      <AvatarFallback className="uppercase">
                        {option.first_name[0]}
                        {option.last_name[0]}
                      </AvatarFallback>
                    </Avatar>

                    <div className="text-main text-sm">
                      {option.first_name} {option.middle_name} {option.last_name} - {option.email}
                    </div>
                  </div>

                  {selectedItems.filter((v: any) => v.uuid === option.uuid).length > 0 && (
                    <LuCheck className="text-main text-sm" />
                  )}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </div>

        <ScrollArea className="mt-2.5 h-[200px]" type="always">
          <div className={clsx("flex flex-col gap-2.5", selectedItems.length > 3 ? "pr-3" : "")}>
            {selectedItems.map((v: any) => (
              <div key={v.uuid} className="flex items-center justify-between rounded-lg border p-2">
                <div className="flex flex-1 items-center gap-2.5">
                  <Avatar className="h-10 w-10">
                    <AvatarImage
                      src={v.image}
                      alt={`${v.first_name} ${v.last_name}`}
                      className="object-cover"
                    />
                    <AvatarFallback className="uppercase">
                      {v.first_name[0]}
                      {v.last_name[0]}
                    </AvatarFallback>
                  </Avatar>

                  <div className="text-default text-sm">
                    {v.first_name} {v.middle_name} {v.last_name} - {v.email}
                  </div>
                </div>

                <LuTrash2
                  className="text-destructive cursor-pointer text-lg"
                  onClick={handleRemoveItem(v.uuid)}
                />
              </div>
            ))}
          </div>
        </ScrollArea>
      </div>
    </CommandPrimitive>
  )
}
