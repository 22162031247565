import React, { useEffect, useRef, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import * as z from "zod"

import { IconNext } from "@/components/_icons"
import GuestLayout from "@/components/_layout/GuestLayout"
import { LoadingButton, LoadingSpinner } from "@/components/_uiext"
import useApplicationInviteStore from "@/stores/useApplicationInviteStore"

import { APPLICATION_STATUS, CHECK_ITEM_TYPES, YN } from "@repo/util/constant"

import Notification from "./components/Notification"
import NotificationSticky from "./components/NotificationSticky"
import SupportingDocumentsItems from "../_sections/SupportingDocumentsItems"
import VerifyDialog from "./components/VerifyDialog"
import { SubmitSuccessDialog } from "./components/SubmitSuccessDialog"

const FormSchema = z.object({
  checkitems: z
    .array(
      z.object({
        section: z.string(),
        items: z
          .array(
            z
              .object({
                type: z.string(),
                text: z.string(),
                choice: z.enum([YN.Yes, YN.No]).default(YN.Yes),
                result: z.union([z.string(), z.instanceof(File)]).optional(),
                modifier_type: z.string().optional(),
                modifier_uuid: z.string().optional(),
                modified_at: z.string().optional()
              })
              .superRefine((data, ctx) => {
                if (data.choice === YN.Yes && !data.result) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["result"],
                    message: "This field is required"
                  })
                }
              })
          )
          .default([])
      })
    )
    .default([])
})

type FormSchemaType = z.infer<typeof FormSchema>

export default function ApplicationsInvites() {
  const { uuid: inviteUuid } = useParams()
  const navigate = useNavigate()
  const store = useApplicationInviteStore()

  const initialized = useRef(false)
  const [loading, setLoading] = useState(true)
  const [openVerify, setOpenVerify] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [appData, setAppData] = useState<any>(null)

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      checkitems: []
    }
  })

  const { fields } = useFieldArray({
    control: form.control,
    name: "checkitems"
  })

  const initialize = () => {
    store.getInviteAction(inviteUuid ?? "", (newData) => {
      if (newData.application.status !== APPLICATION_STATUS.Incomplete.key) {
        navigate(-1)
      } else {
        setOpenVerify(true)
        setLoading(false)
      }
    })
  }

  const handleVerifySuccess = (newData: any) => {
    setIsVerified(true)
    setAppData(newData)

    const fslt = newData?.lender?.fs
      ?.filter((fs: any) => fs.funding_solution === newData.funding_solution)?.[0]
      ?.lt?.filter((lt: any) => lt.loan_type === newData.loan_type)?.[0]

    if (fslt?.checkitems?.length > 0) {
      const updatedCheckitems: any[] = []
      fslt?.checkitems.forEach((i: any) => {
        const subitems = i.items
        const items: any[] = []
        subitems.forEach((si: any) => {
          items.push({
            ...si,
            choice: si.type === CHECK_ITEM_TYPES.YesNo ? YN.Yes : YN.No,
            result: ""
          })
        })
        updatedCheckitems.push({
          section: i.section,
          items
        })
      })
      form.setValue("checkitems", newData?.checkitems ? newData?.checkitems : updatedCheckitems)
    }
  }

  const onSubmit = (values: FormSchemaType) => {
    store.submitInviteAction(inviteUuid ?? "", values, (newData) => {
      setSubmitSuccess(true)
    })
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      initialize()
    }
  }, [inviteUuid])

  if (!isVerified) {
    return (
      <GuestLayout>
        <LoadingSpinner loading={loading} />

        <VerifyDialog
          inviteUuid={inviteUuid ?? ""}
          open={openVerify}
          setOpen={setOpenVerify}
          onSuccessCallback={handleVerifySuccess}
        />
      </GuestLayout>
    )
  }

  return (
    <GuestLayout>
      <FormProvider {...form}>
        <form className="flex flex-col gap-4" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-col rounded-xl border bg-white p-8 md:flex-row">
            <div className="flex flex-1 flex-col pr-0 xl:pr-8">
              <h3 className="text-main mb-4 text-lg font-semibold">Supporting documents</h3>

              <Notification />

              <div className="flex w-full flex-col gap-8">
                {fields.map((item, index) => (
                  <div key={item.id} className="flex flex-col">
                    <h3 className="text-main mb-4 text-lg font-semibold">{item.section}</h3>

                    <SupportingDocumentsItems
                      data={appData}
                      checkItemIndex={index}
                      modifierType="inviter"
                      modifierUuid={
                        appData?.invites?.filter((i: any) => i.uuid === inviteUuid)?.[0]
                          ?.individual_uuid
                      }
                    />
                  </div>
                ))}
              </div>
            </div>

            <NotificationSticky />
          </div>

          <div className="flex justify-end gap-2">
            <LoadingButton
              loading={store.loading}
              type="submit"
              className="flex items-center gap-4 px-6 py-4 md:!w-auto"
            >
              Submit
              <IconNext className="text-sm" />
            </LoadingButton>
          </div>
        </form>
      </FormProvider>

      <SubmitSuccessDialog open={submitSuccess} setOpen={setSubmitSuccess} />
    </GuestLayout>
  )
}
