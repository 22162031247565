import React, { Dispatch, SetStateAction } from "react"

import { useNavigate } from "react-router-dom"

import { IconCheckPolygon } from "@/components/_icons"
import { LoadingButton } from "@/components/_uiext"

import { Dialog, DialogContent } from "@repo/ui/components/ui/dialog"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export default function SubmitDialog(props: Readonly<Props>) {
  const { open, setOpen } = props
  const navigate = useNavigate()

  const handleViewApplicationsClick = () => {
    navigate("/applications/list")
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="flex h-full flex-col gap-0 bg-white p-10 md:h-auto">
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col">
            <div className="mb-4 flex justify-center">
              <IconCheckPolygon className="text-primary text-6xl" />
            </div>

            <h1 className="text-main mb-4 text-center text-lg font-semibold">
              Application submitted!
            </h1>

            <p className="text-default mb-6 text-center text-base font-normal">
              Your application has been successfully submitted to our credit team. A member from our
              credit team will be in touch with you shortly.
            </p>
          </div>

          <div className="flex justify-center">
            <LoadingButton
              type="button"
              onClick={handleViewApplicationsClick}
              className="w-full px-6 py-4 md:w-auto"
            >
              View my applications
            </LoadingButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
