import React from "react"

import { ColumnDef } from "@tanstack/react-table"
import { format } from "date-fns"
import numeral from "numeral"
import { useNavigate } from "react-router-dom"

import { IconArchive, IconEdit, IconMore, IconView } from "@/components/_icons"
import useAdminApplicationStore from "@/stores/useAdminApplicationStore"

import { Avatar, AvatarFallback, AvatarImage } from "@repo/ui/components/ui/avatar"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@repo/ui/components/ui/dropdown-menu"
import { Button } from "@repo/ui/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/components/ui/popover"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@repo/ui/components/ui/tooltip"
import { APPLICATION_STATUS } from "@repo/util/constant"

interface ActionColumnProps {
  row: any
}

function ActionColumn({ row }: Readonly<ActionColumnProps>) {
  const navigate = useNavigate()
  const store = useAdminApplicationStore()

  const handleViewClick = (uuid: string) => () => {
    navigate(`/admin/applications/view/${uuid}`)
  }

  const handleEditClick = (uuid: string) => () => {
    navigate(`/admin/applications/edit/${uuid}`)
  }

  const handleArchiveClick = (uuid: string) => () => {
    store.archiveAction(uuid)
  }

  return (
    <div className="text-center">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <IconMore className="text-2xl" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-32" align="end">
          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleViewClick(row.original.uuid)}
          >
            <IconView className="text-base" /> View
          </DropdownMenuItem>

          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleEditClick(row.original.uuid)}
          >
            <IconEdit className="text-base" />
            Edit
          </DropdownMenuItem>

          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleArchiveClick(row.original.uuid)}
          >
            <IconArchive className="text-base" />
            {!row.original.is_archived ? "Archive" : "Restore"}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

const columns: ColumnDef<any>[] = [
  {
    accessorKey: "created_at",
    header: () => <>Created on</>,
    cell: ({ row }) => (
      <div className="text-sm">{format(row.original.created_at, "dd/MM/yyyy")}</div>
    )
  },
  {
    accessorKey: "id",
    header: () => <>Application ID</>,
    cell: ({ row }) => (
      <div className="text-sm">{Number(row.original.id).toString().padStart(10, "0")}</div>
    )
  },
  {
    accessorKey: "entity_name",
    header: () => <>Entity name</>,
    cell: ({ row }) => <div className="text-sm">{row.original.borrower?.entity_name}</div>
  },
  {
    accessorKey: "loan_amount",
    header: () => <>Loan amount</>,
    cell: ({ row }) => (
      <div className="text-nowrap text-sm">
        {row.original.settled_amount && row.original.settled_amount !== row.original.loan_amount ? (
          <div className="flex flex-col">
            <span className="line-through">
              {numeral(row.original.loan_amount).format("$ 0,0")}
            </span>
            <span className="text-red-500">
              {numeral(row.original.settled_amount).format("$ 0,0")}
            </span>
          </div>
        ) : (
          <span>{numeral(row.original.loan_amount).format("$ 0,0")}</span>
        )}
      </div>
    )
  },
  {
    accessorKey: "assigned_to",
    header: () => <>Assigned to</>,
    cell: ({ row }) => (
      <TooltipProvider disableHoverableContent delayDuration={0} skipDelayDuration={0}>
        <div className="flex items-center">
          <Tooltip>
            <TooltipTrigger className="hidden md:block">
              <Avatar className="hover:border-primary h-10 w-10 border-2 border-white hover:z-50">
                <AvatarImage
                  src={row.original.creator?.image}
                  alt={`${row.original.creator?.first_name} ${row.original.creator?.last_name}`}
                  className="object-cover"
                />
                <AvatarFallback className="uppercase">
                  {row.original.creator?.first_name[0]}
                  {row.original.creator?.last_name[0]}
                </AvatarFallback>
              </Avatar>
            </TooltipTrigger>

            <TooltipContent className="grid grid-cols-3 gap-2 p-4">
              <p className="text-default text-sm font-normal">Full name:</p>
              <p className="text-main col-span-2 text-sm font-semibold">
                {row.original.creator?.first_name} {row.original.creator?.last_name}
              </p>
              <p className="text-default text-sm font-normal">Entity name:</p>
              <p className="text-main col-span-2 text-sm font-semibold">
                {row.original.creator?.entity?.entity_name}
              </p>
            </TooltipContent>
          </Tooltip>

          <Popover>
            <PopoverTrigger className="block md:hidden">
              <Avatar className="hover:border-primary h-10 w-10 border-2 border-white hover:z-50">
                <AvatarImage
                  src={row.original.creator?.image}
                  alt={`${row.original.creator?.first_name} ${row.original.creator?.last_name}`}
                  className="object-cover"
                />
                <AvatarFallback className="uppercase">
                  {row.original.creator?.first_name[0]}
                  {row.original.creator?.last_name[0]}
                </AvatarFallback>
              </Avatar>
            </PopoverTrigger>

            <PopoverContent className="grid grid-cols-3 gap-2 p-4">
              <p className="text-default text-sm font-normal">Full name:</p>
              <p className="text-main col-span-2 text-sm font-semibold">
                {row.original.creator?.first_name} {row.original.creator?.last_name}
              </p>
              <p className="text-default text-sm font-normal">Entity name:</p>
              <p className="text-main col-span-2 text-sm font-semibold">
                {row.original.creator?.entity?.entity_name}
              </p>
            </PopoverContent>
          </Popover>

          {row.original.assignees?.map((a: any) => (
            <React.Fragment key={a?.user?.id}>
              <Tooltip>
                <TooltipTrigger className="hidden md:block">
                  <Avatar className="hover:border-primary ml-[-12px] h-10 w-10 border-2 border-white hover:z-50">
                    <AvatarImage
                      src={a?.user?.image}
                      alt={`${a?.user?.first_name} ${a?.user?.last_name}`}
                      className="object-cover"
                    />
                    <AvatarFallback className="uppercase">
                      {a?.user?.first_name[0]}
                      {a?.user?.last_name[0]}
                    </AvatarFallback>
                  </Avatar>
                </TooltipTrigger>

                <TooltipContent className="grid grid-cols-3 gap-2 p-4">
                  <p className="text-default text-sm font-normal">Full name:</p>
                  <p className="text-main col-span-2 text-sm font-semibold">
                    {a?.user?.first_name} {a?.user?.last_name}
                  </p>
                  <p className="text-default text-sm font-normal">Entity name:</p>
                  <p className="text-main col-span-2 text-sm font-semibold">
                    {a?.user?.entity?.entity_name}
                  </p>
                </TooltipContent>
              </Tooltip>

              <Popover>
                <PopoverTrigger className="block md:hidden">
                  <Avatar className="hover:border-primary ml-[-12px] h-10 w-10 border-2 border-white hover:z-50">
                    <AvatarImage
                      src={a?.user?.image}
                      alt={`${a?.user?.first_name} ${a?.user?.last_name}`}
                      className="object-cover"
                    />
                    <AvatarFallback className="uppercase">
                      {a?.user?.first_name[0]}
                      {a?.user?.last_name[0]}
                    </AvatarFallback>
                  </Avatar>
                </PopoverTrigger>

                <PopoverContent className="grid grid-cols-3 gap-2 p-4">
                  <p className="text-default text-sm font-normal">Full name:</p>
                  <p className="text-main col-span-2 text-sm font-semibold">
                    {a?.user?.first_name} {a?.user?.last_name}
                  </p>
                  <p className="text-default text-sm font-normal">Entity name:</p>
                  <p className="text-main col-span-2 text-sm font-semibold">
                    {a?.user?.entity?.entity_name}
                  </p>
                </PopoverContent>
              </Popover>
            </React.Fragment>
          ))}
        </div>
      </TooltipProvider>
    )
  },
  {
    accessorKey: "referrals",
    header: () => <>Referrals</>,
    cell: ({ row }) => (
      <TooltipProvider disableHoverableContent delayDuration={0} skipDelayDuration={0}>
        <div className="flex items-center">
          {row.original.referrals?.map((r: any) => (
            <React.Fragment key={r?.introducer?.id}>
              <Tooltip>
                <TooltipTrigger className="hidden md:block">
                  <Avatar className="hover:border-primary ml-[-12px] h-10 w-10 border-2 border-white hover:z-50">
                    <AvatarImage
                      src={r?.introducer?.image}
                      alt={`${r?.introducer?.first_name} ${r?.introducer?.last_name}`}
                      className="object-cover"
                    />
                    <AvatarFallback className="uppercase">
                      {r?.introducer?.first_name[0]}
                      {r?.introducer?.last_name[0]}
                    </AvatarFallback>
                  </Avatar>
                </TooltipTrigger>

                <TooltipContent className="grid grid-cols-3 gap-2 p-4">
                  <p className="text-default text-sm font-normal">Full name:</p>
                  <p className="text-main col-span-2 text-sm font-semibold">
                    {r?.introducer?.first_name} {r?.introducer?.last_name}
                  </p>
                  <p className="text-default text-sm font-normal">Entity name:</p>
                  <p className="text-main col-span-2 text-sm font-semibold">
                    {r?.introducer?.entity?.entity_name}
                  </p>
                </TooltipContent>
              </Tooltip>

              <Popover>
                <PopoverTrigger className="block md:hidden">
                  <Avatar className="hover:border-primary ml-[-12px] h-10 w-10 border-2 border-white hover:z-50">
                    <AvatarImage
                      src={r?.introducer?.image}
                      alt={`${r?.introducer?.first_name} ${r?.introducer?.last_name}`}
                      className="object-cover"
                    />
                    <AvatarFallback className="uppercase">
                      {r?.introducer?.first_name[0]}
                      {r?.introducer?.last_name[0]}
                    </AvatarFallback>
                  </Avatar>
                </PopoverTrigger>
                <PopoverContent className="grid grid-cols-3 gap-2 p-4">
                  <p className="text-default text-sm font-normal">Full name:</p>
                  <p className="text-main col-span-2 text-sm font-semibold">
                    {r?.introducer?.first_name} {r?.introducer?.last_name}
                  </p>
                  <p className="text-default text-sm font-normal">Entity name:</p>
                  <p className="text-main col-span-2 text-sm font-semibold">
                    {r?.introducer?.entity?.entity_name}
                  </p>
                </PopoverContent>
              </Popover>
            </React.Fragment>
          ))}
        </div>
      </TooltipProvider>
    )
  },
  {
    accessorKey: "status",
    header: () => <>Status</>,
    cell: ({ row }) => (
      <div
        className="rounded-sm px-6 py-1.5 text-center capitalize"
        style={{
          color: Object.entries(APPLICATION_STATUS)
            .map(([k, v]) => ({ ...v }))
            .filter((as) => as.key === row.original.status)?.[0].color.text,
          backgroundColor: Object.entries(APPLICATION_STATUS)
            .map(([k, v]) => ({ ...v }))
            .filter((as) => as.key === row.original.status)?.[0].color.background
        }}
      >
        {row.original.status}
      </div>
    )
  },
  {
    accessorKey: "actions",
    header: () => <div className="text-center">Actions</div>,
    cell: ({ row }) => <ActionColumn row={row} />
  }
]

export default columns
