import React, { useEffect, useRef, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import clsx from "clsx"
import { format } from "date-fns"
import { FormProvider, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import * as z from "zod"

import { IconNext } from "@/components/_icons"
import GuestLayout from "@/components/_layout/GuestLayout"
import { LoadingButton, LoadingSpinner } from "@/components/_uiext"
import useApplicationConsentStore from "@/stores/useApplicationConsentStore"
import { base64ToFile } from "@/utils/file"

import { Input } from "@repo/ui/components/ui/input"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { Button } from "@repo/ui/components/ui/button"
import { CLIENT_MSG } from "@repo/i18n"
import { YN } from "@repo/util/constant"

import VerifyDialog from "./components/VerifyDialog"
import SignatureDialog from "./components/SignatureDialog"
import { SubmitSuccessDialog } from "./components/SubmitSuccessDialog"

const FormSchema = z
  .object({
    is_q1: z.boolean({
      required_error: CLIENT_MSG.FIELD_REQUIRED,
      invalid_type_error: CLIENT_MSG.FIELD_REQUIRED
    }),
    is_q2: z.boolean({
      required_error: CLIENT_MSG.FIELD_REQUIRED,
      invalid_type_error: CLIENT_MSG.FIELD_REQUIRED
    }),
    is_q3: z.boolean({
      required_error: CLIENT_MSG.FIELD_REQUIRED,
      invalid_type_error: CLIENT_MSG.FIELD_REQUIRED
    }),
    is_q4: z.boolean({
      required_error: CLIENT_MSG.FIELD_REQUIRED,
      invalid_type_error: CLIENT_MSG.FIELD_REQUIRED
    }),
    is_q5: z.boolean({
      required_error: CLIENT_MSG.FIELD_REQUIRED,
      invalid_type_error: CLIENT_MSG.FIELD_REQUIRED
    }),
    is_q6: z.boolean({
      required_error: CLIENT_MSG.FIELD_REQUIRED,
      invalid_type_error: CLIENT_MSG.FIELD_REQUIRED
    }),
    is_q7: z.boolean({
      required_error: CLIENT_MSG.FIELD_REQUIRED,
      invalid_type_error: CLIENT_MSG.FIELD_REQUIRED
    }),
    q7_detail: z.string(),
    is_q8: z.boolean({
      required_error: CLIENT_MSG.FIELD_REQUIRED,
      invalid_type_error: CLIENT_MSG.FIELD_REQUIRED
    }),
    is_q9: z.boolean({
      required_error: CLIENT_MSG.FIELD_REQUIRED,
      invalid_type_error: CLIENT_MSG.FIELD_REQUIRED
    }),
    q9_detail: z.string(),
    full_name: z.string(),
    signature: z.string({ invalid_type_error: CLIENT_MSG.FIELD_REQUIRED }).min(1),
    sign_date: z.date({ required_error: CLIENT_MSG.FIELD_REQUIRED }),
    is_completed: z.boolean().default(true)
  })
  .superRefine((data: any, ctx) => {
    if (data.is_q7 && !data.q7_detail) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: CLIENT_MSG.FIELD_REQUIRED,
        path: ["q7_detail"]
      })
    }

    if (data.is_q9 && !data.q9_detail) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: CLIENT_MSG.FIELD_REQUIRED,
        path: ["q9_detail"]
      })
    }
  })

type FormSchemaType = z.infer<typeof FormSchema>

export default function ApplicationsConsents() {
  const { uuid: consentUuid } = useParams()
  const navigate = useNavigate()
  const store = useApplicationConsentStore()

  const initialized = useRef(false)
  const [loading, setLoading] = useState(true)
  const [openVerify, setOpenVerify] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [appData, setAppData] = useState<any>(null)

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      is_q1: undefined,
      is_q2: undefined,
      is_q3: undefined,
      is_q4: undefined,
      is_q5: undefined,
      is_q6: undefined,
      is_q7: undefined,
      q7_detail: "",
      is_q8: undefined,
      is_q9: undefined,
      q9_detail: "",
      signature: undefined,
      sign_date: undefined,
      is_completed: true
    }
  })

  const initialize = () => {
    store.getConsentAction(consentUuid ?? "", (newData) => {
      if (newData.is_completed) {
        navigate(-1)
      } else {
        setOpenVerify(true)
        setLoading(false)
      }
    })
  }

  const handleVerifySuccess = (newData: any) => {
    setIsVerified(true)
    setAppData(newData)

    const consent = newData?.consents?.filter((c: any) => c.uuid === consentUuid)?.[0]
    form.reset({
      is_q1: consent.is_q1,
      is_q2: consent.is_q2,
      is_q3: consent.is_q3,
      is_q4: consent.is_q4,
      is_q5: consent.is_q5,
      is_q6: consent.is_q6,
      is_q7: consent.is_q7,
      q7_detail: consent.q7_detail ?? "",
      is_q8: consent.is_q8,
      is_q9: consent.is_q9,
      q9_detail: consent.q9_detail ?? "",
      full_name:
        consent.full_name ??
        `${consent?.individual?.first_name} ${consent?.individual?.middle_name} ${consent?.individual?.last_name}`,
      signature: consent.signature,
      sign_date: consent.sign_date ? new Date(consent.sign_date) : undefined,
      is_completed: true
    })
  }

  const onSubmit = (values: FormSchemaType) => {
    let signature: any = values.signature
    if (values.signature?.includes("data:image/")) {
      signature = base64ToFile(values.signature, "image.png", "image/png")
    }
    store.submitConsentAction(consentUuid ?? "", { ...values, signature }, (newData) => {
      setSubmitSuccess(true)
    })
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      initialize()
    }
  }, [consentUuid])

  if (!isVerified) {
    return (
      <GuestLayout>
        <LoadingSpinner loading={loading} />

        <VerifyDialog
          consentUuid={consentUuid ?? ""}
          open={openVerify}
          setOpen={setOpenVerify}
          onSuccessCallback={handleVerifySuccess}
        />
      </GuestLayout>
    )
  }

  return (
    <GuestLayout>
      <FormProvider {...form}>
        <form className="flex flex-col gap-4" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-col rounded-xl border bg-white p-8 md:flex-row">
            <div className="flex flex-1 flex-col pr-0">
              <h5 className="text-main mb-8 text-center text-2xl font-semibold">
                Privacy Consent, Declarations & Authority to Act
              </h5>

              <p className="text-default mb-2 text-sm">
                In compliance with the <i>Commonwealth Privacy Act</i>, applicant parties to a
                finance application should complete and give this return to the below-named
                Introducer/Originator for the purpose of the Privacy Act:
              </p>

              <p className="text-main mb-4 text-sm font-semibold">
                Name of Introducer/Originator: PFG Capital Pty Ltd ACN 652 060 038
              </p>

              <h6 className="text-main mb-4 text-left text-lg font-semibold">
                Acknowledge of Disclosure of Credit Information to a Credit Reporting Agency
              </h6>

              <p className="text-default mb-2 text-sm">
                I/We acknowledge that Section 18E(S) (c) of the Privacy Act allows for a Credit
                Provider, which the above-named Introducer/Originator may approach in arranging
                My/Our finance (hereinafter the Approached Credit Provider), to give a credit
                reporting agency certain personal information about My/Our application for finance.
              </p>

              <p className="text-default text-sm">
                The information which may be given to an agency is covered by Section 18E(1) of the
                Act and includes:-
              </p>

              <ul className="text-default mb-2 list-outside list-disc pl-8 text-sm">
                <li>Such permitted particulars about Me/Us which allows Me/Us to be identified;</li>
                <li>The fact that I/We have applied for finance and the amount;</li>
                <li>
                  The fact that the abovenamed Credit Provider is a current Credit Provider to
                  Me/Us;
                </li>
                <li>
                  Payments which become overdue more than 60 days and for which collection action
                  has commenced;
                </li>
                <li>Advice that payments are no longer overdue;</li>
                <li>Cheques drawn by Me/Us which have been dishonoured more than once;</li>
                <li>
                  In specified circumstances, that in the opinion of the above-named Credit Provider
                  has been paid or otherwise discharged.
                </li>
              </ul>

              <p className="text-default mb-4 text-sm">
                By virtue of this acknowledgment, I/We understand that the above-named Introducer
                has informed Me/Us of the Disclosure Policy to a credit reporting agency of
                information about Me/Us of Approached Credit Providers and I/We so authorise such
                disclosures.
              </p>

              <h6 className="text-main mb-4 text-left text-lg font-semibold">
                Agreement/Authority for Credit Provider to Perform Certain Permitted Actions
                Concerning a Finance Application or Transaction
              </h6>

              <p className="text-default mb-2 text-sm">
                I/We agree that, if it is considered relevant in assessing My/Our application for
                personal credit, the Approached Credit Provider may obtain a report about Me/Our
                commercial activities or commercial credit worthiness from a business which provides
                information about the commercial credit worthiness of persons.
              </p>

              <p className="text-default mb-2 text-sm">
                I/We agree that, if it is considered relevant in assessing My/Our application for
                commercial credit, the Approached Credit Provider may obtain from a credit reporting
                agency a credit report containing personal credit information about Me/Us.
              </p>

              <p className="text-default mb-4 text-sm">
                I/We agree that the Approached Credit Provider may give and seek from any Credit
                Providers named in the accompanying finance application and/or Credit Providers that
                may be named in a personal or commercial credit report issued by a credit reporting
                agency or a commercial credit report agency respectively, information about my/our
                personal or commercial credit arrangements. I/We understand that this information
                can include any information about Me/Our credit worthiness, credit standing, credit
                history or credit capacity that credit providers are allowed to give or receive from
                each other under the Privacy Act.
              </p>

              <h6 className="text-main mb-4 text-left text-lg font-semibold">
                Authorisation to Act on Behalf of Individuals
              </h6>

              <p className="text-default mb-2 text-sm">
                For the purposes of arranging the finance which is the subject of My/Our finance
                application, the details of which appears below, I/We authorise the abovenamed
                Introducer to obtain a report about My/Our consumer of commercial credit worthiness
                from a credit reporting agency or a commercial credit reporting business or from a
                Credit Provider named in this application or referred to in such reports.
              </p>

              <p className="text-default mb-2 text-sm">
                I/We also authorise the above-named Introducer to pass on the above obtained reports
                to such Credit Providers as are appropriate, for their consideration of this
                application.
              </p>

              <p className="text-default mb-2 text-sm">
                I/We also authorise the above-named Introducer to give to and receive from such
                parties and interests as are necessary to the arranging of this finance, such
                personal information about Me/Us which is necessary to the arrangement.
              </p>

              <p className="text-default mb-4 text-sm">
                This authority remains in force until finance is arranged.
              </p>

              <h6 className="text-main mb-4 text-left text-lg font-semibold">
                Consent to receive documents electronically
              </h6>

              <p className="text-default text-sm">
                By signing this document, you consent to receiving electronic copies of documents in
                connection with a credit application or a loan facility. We will rely on this
                consent to communicate with you electronically to the email address specified by you
                in a loan application form or otherwise notified by you. By consenting to receiving
                electronic copies of documents, you acknowledge that:
              </p>

              <ul className="text-default list-outside list-disc pl-8 text-sm">
                <li>You may no longer receive paper documents;</li>
                <li>
                  You must check your emails regularly for notices and other communications from us;
                </li>
                <li>
                  You must advise us if your email address changes so that we may update our
                  records.
                </li>
              </ul>

              <p className="text-default mb-4 text-sm">
                Your consent to receive electronic documents may be withdrawn at any time by
                providing notice to us in writing.
              </p>

              <h6 className="text-main mb-4 text-left text-lg font-semibold">
                Verification of identity
              </h6>

              <p className="text-default main mb-4 text-sm">
                I/we confirm that I/we am/are authorised to provide the personal details presented
                and I/we consent to my/our information being checked with the document issuer or
                official record holder via third party systems and services for the purpose of
                confirming my/our identity.
              </p>

              <h6 className="text-main mb-4 text-left text-lg font-semibold">
                Authority and Consent
              </h6>

              <p className="text-default text-sm">
                The undersigned hereby applies for or guarantees the loan described herein to be
                secured by a mortgage on the property described herein and represents that all
                statements made in this application are true and made for the purpose of obtaining
                the loan. Verification may be obtained from any source named herein. The undersigned
                agrees to:
              </p>

              <ul className="text-default list-outside list-disc pl-8 text-sm">
                <li>
                  support this application with a valuation of the subject property by a qualified
                  valuer selected by the Lender, at the expense of undersigned and without implied
                  obligation on the part of the Lender;
                </li>
                <li>
                  pay all necessary expenses, including legal costs, incurred in obtaining this
                  loan;
                </li>
                <li>
                  the Lender using all the undersigned’s details regarding the loan in marketing
                  material including a Product Disclosure Statement and/or loan summary issued by
                  the Lender or its associated entities.
                </li>
              </ul>

              <p className="text-default mb-4 text-sm">
                It is agreed that by accepting this application the Lender is not obligated to grant
                a loan. The Lender requires that the Mortgaged Property will require general
                insurance coverage against hazards equal, at least, to the value of the property
                improvement. Such insurer shall be acceptable to the Lender, who have sole
                discretion for rejection without grounds. The undersigned's solicitor or agent is
                authorized to accept notice on behalf of the undersigned. I/We declare that I/We,
                the undersigned, am/are over the age of 18 at the time of the execution of this
                application.
              </p>

              <h6 className="text-main mb-4 text-left text-lg font-semibold">
                Declaration by applicant and Guarantor declaration
              </h6>

              <ol className="text-default mb-2 flex list-outside list-decimal flex-col gap-2 pl-4 text-sm">
                <li>
                  <FormField
                    control={form.control}
                    name="is_q1"
                    render={({ field }) => (
                      <FormItem className="flex w-full flex-col space-x-2 space-y-0 md:flex-row md:items-center">
                        <div className="flex flex-1 flex-col">
                          <FormLabel className="text-sm font-normal">
                            Have you ever been declared bankrupt or insolvent or has your estate
                            been assigned for the benefit of creditors?
                          </FormLabel>
                          <FormMessage />
                        </div>
                        <FormControl>
                          <div className="flex gap-2.5 self-end">
                            {[YN.Yes, YN.No].map((option) => (
                              <Button
                                key={option}
                                type="button"
                                size="sm"
                                className={clsx(
                                  "h-auto border px-6 py-4 font-semibold capitalize",
                                  (field.value && option === YN.Yes) ||
                                    (field.value !== null && !field.value && option === YN.No)
                                    ? "border-primary"
                                    : "text-default hover:bg-primary hover:border-primary border-[#868194] bg-transparent hover:text-white"
                                )}
                                onClick={() => field.onChange(option === YN.Yes)}
                              >
                                {option}
                              </Button>
                            ))}
                          </div>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </li>
                <li>
                  <FormField
                    control={form.control}
                    name="is_q2"
                    render={({ field }) => (
                      <FormItem className="flex w-full flex-col space-x-2 space-y-0 md:flex-row md:items-center">
                        <div className="flex flex-1 flex-col">
                          <FormLabel className="text-sm font-normal">
                            Have you ever been a shareholder or officer of any company to which a
                            manager, receiver and/or liquidator has been appointed?
                          </FormLabel>
                          <FormMessage />
                        </div>
                        <FormControl>
                          <div className="flex gap-2.5 self-end">
                            {[YN.Yes, YN.No].map((option) => (
                              <Button
                                key={option}
                                type="button"
                                size="sm"
                                className={clsx(
                                  "h-auto border px-6 py-4 font-semibold capitalize",
                                  (field.value && option === YN.Yes) ||
                                    (field.value !== null && !field.value && option === YN.No)
                                    ? "border-primary"
                                    : "text-default hover:bg-primary hover:border-primary border-[#868194] bg-transparent hover:text-white"
                                )}
                                onClick={() => field.onChange(option === YN.Yes)}
                              >
                                {option}
                              </Button>
                            ))}
                          </div>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </li>
                <li>
                  <FormField
                    control={form.control}
                    name="is_q3"
                    render={({ field }) => (
                      <FormItem className="flex w-full flex-col space-x-2 space-y-0 md:flex-row md:items-center">
                        <div className="flex flex-1 flex-col">
                          <FormLabel className="text-sm font-normal">
                            Is there any unsatisfied judgment entered in any court against you, or
                            any company of which you are or were a shareholder or officer?
                          </FormLabel>
                          <FormMessage />
                        </div>
                        <FormControl>
                          <div className="flex gap-2.5 self-end">
                            {[YN.Yes, YN.No].map((option) => (
                              <Button
                                key={option}
                                type="button"
                                size="sm"
                                className={clsx(
                                  "h-auto border px-6 py-4 font-semibold capitalize",
                                  (field.value && option === YN.Yes) ||
                                    (field.value !== null && !field.value && option === YN.No)
                                    ? "border-primary"
                                    : "text-default hover:bg-primary hover:border-primary border-[#868194] bg-transparent hover:text-white"
                                )}
                                onClick={() => field.onChange(option === YN.Yes)}
                              >
                                {option}
                              </Button>
                            ))}
                          </div>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </li>
                <li>
                  <FormField
                    control={form.control}
                    name="is_q4"
                    render={({ field }) => (
                      <FormItem className="flex w-full flex-col space-x-2 space-y-0 md:flex-row md:items-center">
                        <div className="flex flex-1 flex-col">
                          <FormLabel className="text-sm font-normal">
                            If you are seeking finance to complete the purchase of the security
                            property, do you intend to borrow any other money for this purpose?
                          </FormLabel>
                          <FormMessage />
                        </div>
                        <FormControl>
                          <div className="flex gap-2.5 self-end">
                            {[YN.Yes, YN.No].map((option) => (
                              <Button
                                key={option}
                                type="button"
                                size="sm"
                                className={clsx(
                                  "h-auto border px-6 py-4 font-semibold capitalize",
                                  (field.value && option === YN.Yes) ||
                                    (field.value !== null && !field.value && option === YN.No)
                                    ? "border-primary"
                                    : "text-default hover:bg-primary hover:border-primary border-[#868194] bg-transparent hover:text-white"
                                )}
                                onClick={() => field.onChange(option === YN.Yes)}
                              >
                                {option}
                              </Button>
                            ))}
                          </div>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </li>
                <li>
                  <FormField
                    control={form.control}
                    name="is_q5"
                    render={({ field }) => (
                      <FormItem className="flex w-full flex-col space-x-2 space-y-0 md:flex-row md:items-center">
                        <div className="flex flex-1 flex-col">
                          <FormLabel className="text-sm font-normal">
                            Have you, or any company with which you are or were associated, ever had
                            a property sold through mortgagee sale proceedings?
                          </FormLabel>
                          <FormMessage />
                        </div>
                        <FormControl>
                          <div className="flex gap-2.5 self-end">
                            {[YN.Yes, YN.No].map((option) => (
                              <Button
                                key={option}
                                type="button"
                                size="sm"
                                className={clsx(
                                  "h-auto border px-6 py-4 font-semibold capitalize",
                                  (field.value && option === YN.Yes) ||
                                    (field.value !== null && !field.value && option === YN.No)
                                    ? "border-primary"
                                    : "text-default hover:bg-primary hover:border-primary border-[#868194] bg-transparent hover:text-white"
                                )}
                                onClick={() => field.onChange(option === YN.Yes)}
                              >
                                {option}
                              </Button>
                            ))}
                          </div>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </li>
                <li>
                  <FormField
                    control={form.control}
                    name="is_q6"
                    render={({ field }) => (
                      <FormItem className="flex w-full flex-col space-x-2 space-y-0 md:flex-row md:items-center">
                        <div className="flex flex-1 flex-col">
                          <FormLabel className="text-sm font-normal">
                            Has a mortgagee ever sold your property to recover a debt owing by you?
                          </FormLabel>
                          <FormMessage />
                        </div>
                        <FormControl>
                          <div className="flex gap-2.5 self-end">
                            {[YN.Yes, YN.No].map((option) => (
                              <Button
                                key={option}
                                type="button"
                                size="sm"
                                className={clsx(
                                  "h-auto border px-6 py-4 font-semibold capitalize",
                                  (field.value && option === YN.Yes) ||
                                    (field.value !== null && !field.value && option === YN.No)
                                    ? "border-primary"
                                    : "text-default hover:bg-primary hover:border-primary border-[#868194] bg-transparent hover:text-white"
                                )}
                                onClick={() => field.onChange(option === YN.Yes)}
                              >
                                {option}
                              </Button>
                            ))}
                          </div>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </li>
                <li>
                  <div className="flex flex-col gap-2">
                    <FormField
                      control={form.control}
                      name="is_q7"
                      render={({ field }) => (
                        <FormItem className="flex w-full flex-col space-x-2 space-y-0 md:flex-row md:items-center">
                          <div className="flex flex-1 flex-col">
                            <FormLabel className="text-sm font-normal">
                              Has any application in respect of this loan been submitted by you, or
                              any other person, to any other lender? If so, give details
                            </FormLabel>
                            <FormMessage />
                          </div>
                          <FormControl>
                            <div className="flex gap-2.5 self-end">
                              {[YN.Yes, YN.No].map((option) => (
                                <Button
                                  key={option}
                                  type="button"
                                  size="sm"
                                  className={clsx(
                                    "h-auto border px-6 py-4 font-semibold capitalize",
                                    (field.value && option === YN.Yes) ||
                                      (field.value !== null && !field.value && option === YN.No)
                                      ? "border-primary"
                                      : "text-default hover:bg-primary hover:border-primary border-[#868194] bg-transparent hover:text-white"
                                  )}
                                  onClick={() => field.onChange(option === YN.Yes)}
                                >
                                  {option}
                                </Button>
                              ))}
                            </div>
                          </FormControl>
                        </FormItem>
                      )}
                    />

                    {form.watch("is_q7") && (
                      <FormField
                        control={form.control}
                        name="q7_detail"
                        render={({ field }) => (
                          <FormItem>
                            <Input autoComplete="off" {...field} />
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}
                  </div>
                </li>
                <li>
                  <FormField
                    control={form.control}
                    name="is_q8"
                    render={({ field }) => (
                      <FormItem className="flex w-full flex-col space-x-2 space-y-0 md:flex-row md:items-center">
                        <div className="flex flex-1 flex-col">
                          <FormLabel className="text-sm font-normal">
                            Do you require an interpreter for written or spoken English?
                          </FormLabel>
                          <FormMessage />
                        </div>
                        <FormControl>
                          <div className="flex gap-2.5 self-end">
                            {[YN.Yes, YN.No].map((option) => (
                              <Button
                                key={option}
                                type="button"
                                size="sm"
                                className={clsx(
                                  "h-auto border px-6 py-4 font-semibold capitalize",
                                  (field.value && option === YN.Yes) ||
                                    (field.value !== null && !field.value && option === YN.No)
                                    ? "border-primary"
                                    : "text-default hover:bg-primary hover:border-primary border-[#868194] bg-transparent hover:text-white"
                                )}
                                onClick={() => field.onChange(option === YN.Yes)}
                              >
                                {option}
                              </Button>
                            ))}
                          </div>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </li>
                <li>
                  <div className="flex flex-col gap-2">
                    <FormField
                      control={form.control}
                      name="is_q9"
                      render={({ field }) => (
                        <FormItem className="flex w-full flex-col space-x-2 space-y-0 md:flex-row md:items-center">
                          <div className="flex flex-1 flex-col">
                            <FormLabel className="text-sm font-normal">
                              Is this application made on behalf of a Trust? If so, give details.
                            </FormLabel>
                            <FormMessage />
                          </div>
                          <FormControl>
                            <div className="flex gap-2.5 self-end">
                              {[YN.Yes, YN.No].map((option) => (
                                <Button
                                  key={option}
                                  type="button"
                                  size="sm"
                                  className={clsx(
                                    "h-auto border px-6 py-4 font-semibold capitalize",
                                    (field.value && option === YN.Yes) ||
                                      (field.value !== null && !field.value && option === YN.No)
                                      ? "border-primary"
                                      : "text-default hover:bg-primary hover:border-primary border-[#868194] bg-transparent hover:text-white"
                                  )}
                                  onClick={() => field.onChange(option === YN.Yes)}
                                >
                                  {option}
                                </Button>
                              ))}
                            </div>
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    {form.watch("is_q9") && (
                      <FormField
                        control={form.control}
                        name="q9_detail"
                        render={({ field }) => (
                          <FormItem>
                            <Input autoComplete="off" {...field} />
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}
                  </div>
                </li>
              </ol>

              <p className="text-default mb-2 text-sm">
                <span className="font-medium">REQUIRED:</span> By signing this application, I/we
                hereby consent to the giving of a copy of the credit contract to any guarantors
                (whether named in this contract or not).
              </p>

              <p className="text-default mb-2 text-sm">
                <span className="font-medium">REQUIRED:</span> I/we acknowledge and agree that if
                valuation/s of the property/ies proposed as security has/have been commenced.
                <br />
                I/we will not be entitled to a refund of any valuation fee/s paid even if the
                proposed loan does not proceed.
              </p>

              <p className="text-default mb-4 text-sm">
                I/We hereby apply for a loan and certify that the above statements are true and
                correct and will remain true and correct unless and until I/We notify you otherwise
                in writing. I/We agree to pay all costs and expenses reasonable incurred as a
                consequence of this application, to the extent that such payments are permitted by
                law. I/We authorize you to arrange a valuation of the security offered, provided
                that approval “in principle” of this loan has been granted and I/We understand that
                such a valuation will be required from an approved registered valuer, and that no
                refund of valuation fees will be made in the event that the valuation does not meet
                your requirements. My solicitor/agent is authorized to accept any notification on
                my/our behalf. If a loan is approved in principle, you will issue me/us a letter of
                offer. If I/We accept the offer I/We will be bound by its terms and conditions. I/We
                agree to execute a first ranking mortgage over the security property.
              </p>

              <h6 className="text-main mb-4 text-left text-lg font-semibold">
                Declaration of Purpose
              </h6>

              <p className="text-default mb-4 text-sm">
                I/We, the undersigned, hereby declare that the credit to be provided by the Credit
                Provider will be applied wholly or predominantly for the following purpose(s):
              </p>

              <ol className="text-default mb-4 list-outside list-decimal pl-8 text-sm">
                <li className="mb-2">
                  <span className="font-medium">Business purpose:</span>
                  <p>
                    The loan will be used to support and fund business-related activities, including
                    but not limited to working capital, purchase of equipment, expansion of business
                    operations, and other related business expenses.
                  </p>
                </li>
                <li>
                  <span className="font-medium">Investment purpose:</span>
                  <p>
                    The loan will be utilized for investment purposes, which may include acquiring
                    commercial or residential property, purchasing shares, or other forms of
                    investments aimed at generating returns.
                  </p>
                </li>
              </ol>

              <p className="text-default mb-4 text-sm">
                I/We acknowledge that by signing this declaration, I/we confirm that the credit will
                not be used for personal, domestic, or household purposes. I/We understand that by
                making this declaration, I/we may lose protection under the National Consumer Credit
                Protection Act 2009.
              </p>

              <h6 className="text-main mb-4 text-left text-lg font-semibold">
                Mandate To Act & Irrevocable Authority to Pay at Settlement
              </h6>

              <div className="text-default mb-4 flex flex-col gap-2 text-sm">
                <div className="mb-2 flex gap-4">
                  <div className="min-w-6 font-semibold">1.</div>
                  <div className="flex flex-col gap-2">
                    <span className="font-semibold">Sourcing of Loan</span>
                    <p>
                      This Mandate to Act ‘Mandate’ confirms that the Introducer/Originator has been
                      exclusively and irrevocably appointed by the borrower for the sole purpose of
                      providing its services on a reasonable efforts basis to obtain approval for a
                      finance facility as detailed in this Mandate.
                    </p>

                    <div className="flex gap-4">
                      <div className="min-w-6">1.1</div>
                      <div>
                        The Borrower authorises the Introducer/Originator to give and receive from
                        potential Lenders and their associated entities, advisers or agents any such
                        information about the Security Property, the Borrower or any other party
                        related to this transaction as is considered necessary or prudent by the
                        Originator or the Lender in their absolute discretion.
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">1.2</div>
                      <div>
                        The Introducer/Originator will assess the Borrower’s information and prepare
                        a submission on the Borrower’s behalf to any potential Lender and will use
                        all reasonable efforts to procure approval of the finance facility sought.
                        The Introducer/Originator is not responsible if the Borrower is not approved
                        for a loan facility.
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">1.3</div>
                      <div className="flex flex-col gap-2">
                        <div>
                          It is acknowledged on behalf of the Borrower that the valuation fees and
                          other usual fees and charges from the lenders and other third parties are
                          expected to be payable in connection with the envisaged loan transaction.
                        </div>

                        <div>
                          If any fee is not paid or costs and expenses not reimbursed before
                          settlement, the Introducer/Originator is authorised to deduct that amount
                          from the loan settlement proceeds.
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">1.4</div>
                      <div>
                        The date by which the Introducer/Originator must secure the Loan Offer is
                        the date on which all relevant, requested documents are supplied to the
                        Broker plus 30 days or such other period agreed by the parties in writing.
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">1.5</div>
                      <div>
                        The Borrower acknowledges that the Loan Offer(s) sourced by the
                        Introducer/Originator will be drawn from a range of potential lenders that
                        will not necessarily include all lenders who offer credit of the nature of
                        the credit sought and shall include the mortgage products managed by the
                        Introducer/Originator.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2 flex gap-4">
                  <div className="min-w-6 font-semibold">2.</div>
                  <div className="flex flex-col gap-2">
                    <span className="font-semibold">Fees and other charges</span>
                    <p>
                      The Borrower acknowledges that in consideration of the services performed by
                      the Introducer/Originator pursuant to this mandate:
                    </p>

                    <div className="flex gap-4">
                      <div className="min-w-6">2.1</div>
                      <div>
                        The Borrower and Guarantor jointly acknowledge that the
                        Introducer/Originator work on a success basis and no upfront fee is due and
                        payable to the Introducer/Originator.
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">2.2</div>
                      <div>
                        The Borrower and Guarantor jointly acknowledge that the
                        Introducer/Originator fee on success is <b>1.10% (gst inclusive)</b> of the
                        formal approval issued by the Lender and deemed due and payable to the
                        Introducer/Originator on 1st draw down or on settlement of the total
                        facility advanced by the Lender whichever occurs first.
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">2.3</div>
                      <div className="flex flex-col gap-2">
                        <div>
                          The Borrower and Guarantor are jointly and severally liable for any fees
                          incurred by or payable to the Introducer/Originator in relation to this
                          finance transaction.
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">2.4</div>
                      <div>
                        The Borrower agrees that all monies due and payable under this Mandate are
                        recoverable by the Introducer/Originator. The Borrower/s acknowledge that
                        they will be liable for costs and expenses incurred in recovering any amount
                        owed including legal costs on a solicitor/client basis on any amount that is
                        not paid by the due date.
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">2.5</div>
                      <div>
                        The Borrower’s and Guarantor’s hereby irrevocably authorises and directs the
                        Lender and the Lender’s solicitor to deduct the Introducer/Originator
                        Introducer fee from the loan amount at settlement and further irrevocably
                        directs that sum be paid directly to the Introducer/Originator immediately
                        thereafter.
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">2.6</div>
                      <div className="flex flex-col gap-2">
                        <div>
                          If the Introducer/Originator procures a Loan Offer based on the terms
                          sought by the Borrower as set out in the Terms of Loan, then the Borrower
                          and Guarantor must pay the Brokerage Fee to the Introducer/Originator:
                        </div>

                        <div className="flex gap-4">
                          <div className="min-w-6">(i)</div>
                          <div>
                            even if the Borrower and Guarantor decides not to accept the Loan Offer
                            or does not proceed to settlement of the loan unless the Borrower and
                            Guarantor has terminated this Agreement, prior to the issuance of the
                            Loan Offer or in such circumstances where no Loan Offer has been issued
                            prior to the expiry of this agreement; or
                          </div>
                        </div>

                        <div className="flex gap-4">
                          <div className="min-w-6">(ii)</div>
                          <div>
                            if the Borrower and Guarantor has advised that the loan is not suitable,
                            but it is later discovered that the loan has been written by the Lender
                            whose Loan Offer was not accepted.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">2.7</div>
                      <div className="flex flex-col gap-2">
                        <div>
                          The Client must, immediately on request by the Introducer/Originator pay;
                        </div>

                        <div className="flex gap-4">
                          <div className="min-w-6">(i)</div>
                          <div>
                            any valuation fees incurred by the Introducer/Originator when the
                            Introducer/Originator is authorised by the Lender to instruct the
                            Valuer, or
                          </div>
                        </div>

                        <div className="flex gap-4">
                          <div className="min-w-6">(ii)</div>
                          <div>
                            any legal fees incurred by the Introducer/Originator when the
                            Introducer/Originator instructs any lawyer on the Borrower’s behalf to
                            act in connection with the Loan.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">2.8</div>
                      <div className="flex flex-col gap-2">
                        <div>The borrower agrees:</div>

                        <div className="flex gap-4">
                          <div className="min-w-6">(i)</div>
                          <div>
                            to pay or reimburse to the Introducer/Originator all other costs and
                            expenses connected with the processing of the Borrower’s loan
                            application, including but not limited to postage, freight, telephone
                            and facsimile charges; and
                          </div>
                        </div>

                        <div className="flex gap-4">
                          <div className="min-w-6">(ii)</div>
                          <div>
                            these costs and expenses, if any, are payable from settlement proceeds.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">2.9</div>
                      <div className="flex flex-col gap-2">
                        <div>
                          All fees and charges payable to the Introducer/Originator under this
                          agreement are separate and independent from fees and charges payable by
                          the Borrower to any Lender or other entity.
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">2.10</div>
                      <div className="flex flex-col gap-2">
                        <div>
                          The Borrower agrees to pay any Ongoing Management Fee until the loan is
                          discharged, with effect from the date of settlement. The Ongoing
                          Management Fee specified in the Table of Fees outlined in the Loan
                          Documentation is an annual fee payable on a monthly/quarterly/annual
                          basis.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2 flex gap-4">
                  <div className="min-w-6 font-semibold">3.</div>
                  <div className="flex flex-col gap-2">
                    <span className="font-semibold">Caveats</span>

                    <div className="flex gap-4">
                      <div className="min-w-6">3.1</div>
                      <div className="flex flex-col gap-2">
                        <div>The borrower:</div>

                        <div className="flex gap-4">
                          <div className="min-w-6">(i)</div>
                          <div>
                            hereby charges all his right, title and interest in the land described
                            herein with the performance of his obligations under this Agreement to
                            the extent of the fees validly due to the Introducer/Originator and
                            hereby consents to the registration by the Introducer/Originator of a
                            caveat against the title to such land to protect the charge created by
                            this Agreement;
                          </div>
                        </div>

                        <div className="flex gap-4">
                          <div className="min-w-6">(ii)</div>
                          <div>
                            acknowledges and agrees that this charge creates a caveatable interest
                            at law and in equity;
                          </div>
                        </div>

                        <div className="flex gap-4">
                          <div className="min-w-6">(iii)</div>
                          <div>
                            consents to the Introducer/Originator to lodging a caveat on the
                            security property and or any other property held by the borrowers now
                            and / or in the future, if moneys due under this mandate are not paid
                            when due; and
                          </div>
                        </div>

                        <div className="flex gap-4">
                          <div className="min-w-6">(iv)</div>
                          <div>
                            agrees to sign all documents and take all other steps as required by the
                            Introducer/Originator to facilitate lodgment of a caveat pursuant to
                            this clause.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">3.2</div>
                      <div>
                        3.2 The Introducer/Originator will not cause a caveat to be lodged against
                        the property which is the subject of this charging clause during the
                        currency of this mandate and shall only do so when the Introducer/Originator
                        has been notified of circumvention by the Borrower or failure to pay the
                        fees due under this Agreement.
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">3.3</div>
                      <div>
                        If a caveat is entered, the Introducer/Originator agrees to consent to and
                        allow registration of such other commercial financial facilities as may need
                        to be registered on the title to facilitate completion of the development to
                        be affected.
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">3.4</div>
                      <div className="flex flex-col gap-2">
                        <div>This clause 3.3 only applies in Development loans.</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2 flex gap-4">
                  <div className="min-w-6 font-semibold">4.</div>
                  <div className="flex flex-col gap-2">
                    <span className="font-semibold">Financial or Other Benefits</span>

                    <div className="flex gap-4">
                      <div className="min-w-6">4.1</div>
                      <div className="flex flex-col gap-2">
                        <div>
                          The Borrower acknowledges that the Introducer/Originator may be paid a
                          commission by a lender under an accepted Loan Offer. This may comprise an
                          upfront fee and / or an ongoing commission, each calculated as a
                          percentage of the loan made to the Borrower. This commission, where
                          payable, is in addition to the fees under this Agreement. The amount of
                          these commissions, if any, may not be ascertainable at the date of this
                          Agreement.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2 flex gap-4">
                  <div className="min-w-6 font-semibold">5.</div>
                  <div className="flex flex-col gap-2">
                    <span className="font-semibold">Dealings with the Lender</span>

                    <div className="flex gap-4">
                      <div className="min-w-6">5.1</div>
                      <div className="flex flex-col gap-2">
                        <div>
                          The Introducer/Originator will liaise between the Borrower and any lender
                          introduced by the Introducer/Originator. If the Introducer/Originator
                          obtains a Loan Offer from a lender then the Borrower must not seek to
                          circumvent the Introducer/Originator by approaching such lender(s)
                          directly. If the Borrower breaches this provision, then the
                          Introducer/Originator will be entitled in addition to the fees set out in
                          this mandate an amount equal to such commissions that the
                          Introducer/Originator would have received if the Loan Offer proceeded to
                          settlement.
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">5.2</div>
                      <div className="flex flex-col gap-2">
                        <div>
                          The Borrower acknowledges the full fees and charges contained within this
                          agreement remain due and payable in the event that the
                          Introducer/Originator obtains a Loan Offer from a lender who has
                          previously supplied an offer for finance for the same security property
                          and the same transaction through any other Introducer/Originator, mortgage
                          originator or third-party representative. Should this case arise, the
                          Borrower irrevocably agrees to direct the lender at settlement to pay all
                          fees due and payable under the terms of this Agreement to the
                          Introducer/Originator.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2 flex gap-4">
                  <div className="min-w-6 font-semibold">6.</div>
                  <div className="flex flex-col gap-2">
                    <span className="font-semibold">Miscellaneous provisions</span>

                    <div className="flex gap-4">
                      <div className="min-w-6">6.1</div>
                      <div className="flex flex-col gap-2">
                        <div>
                          <b>(Severability)</b> If anything in this agreement is unenforceable,
                          illegal or void then it is severed, and the remainder of this Agreement
                          remains in force.
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">6.2</div>
                      <div className="flex flex-col gap-2">
                        <div>
                          <b>(Acknowledgment)</b> The Introducer/Originator has not and will not
                          provide the Borrower with any financial or other advice in relation to the
                          Loan Offer or its application and the Borrower will independently select
                          the loan relying on its own assessment of its suitability for the
                          Borrower’s purposes.
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">6.3</div>
                      <div className="flex flex-col gap-2">
                        <div>
                          <b>(Entire Understanding)</b> This Agreement is the entire agreement and
                          understanding between the parties connected with the subject matter of
                          this Agreement and that this Agreement supersedes any prior agreement or
                          understanding on anything connected with that subject matter.
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">6.4</div>
                      <div className="flex flex-col gap-2">
                        <div>
                          <b>(Notice)</b> Any notice may be sent by ordinary mail or facsimile to
                          the address of the party as detailed in this document or such other
                          address as notified.
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">6.5</div>
                      <div className="flex flex-col gap-2">
                        <div>
                          <b>(Governing Law and Jurisdiction)</b> This Agreement is governed by the
                          laws of New South Wales and the parties submit to the non-exclusive
                          jurisdiction of the Courts of New South Wales and the Federal Court of
                          Australia.
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="min-w-6">6.6</div>
                      <div className="flex flex-col gap-2">
                        <div>
                          <b>(Feedback)</b> The Introducer/Originator is committed to providing high
                          quality services to the Borrower. To this end the Borrower is requested to
                          refer any feedback or complaints to the Introducer/Originator.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p className="text-default mb-4 text-sm">
                I/we hereby declare that I am /we are duly empowered as the Borrower(s) or on behalf
                of the Borrower(s) to execute this mandate and that all information given is true
                and accurate at the time of signing and that there are no factors not disclosed to
                the Originator which may impair or impede loan approval.
              </p>

              <div className="flex flex-col gap-4 rounded-xl border p-8 md:flex-row">
                {appData?.consents?.map((c: any) => (
                  <React.Fragment key={c.uuid}>
                    {c?.uuid === consentUuid ? (
                      <div className="flex flex-1 flex-col">
                        <div className="mb-4">
                          <FormField
                            control={form.control}
                            name="full_name"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel className="text-main text-sm font-normal capitalize">
                                  {c?.individual?.role ?? ""} Full Name
                                </FormLabel>
                                <Input disabled autoComplete="off" {...field} />
                                <Input readOnly type="hidden" {...field} />
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>

                        <div className="mb-4">
                          <FormField
                            control={form.control}
                            name="signature"
                            render={({ field }) => (
                              <FormItem className="flex flex-col">
                                <FormLabel className="text-main text-sm font-normal capitalize">
                                  Signature
                                </FormLabel>
                                <SignatureDialog
                                  value={field.value}
                                  onConfirm={(signUri: string) => {
                                    field.onChange(signUri)
                                    form.setValue("sign_date", new Date())
                                    form.clearErrors("sign_date")
                                  }}
                                />
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>

                        <div>
                          <FormField
                            control={form.control}
                            name="sign_date"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel className="text-main text-sm font-normal capitalize">
                                  Date
                                </FormLabel>
                                <Input
                                  disabled
                                  {...field}
                                  value={field.value ? format(field.value, "dd/MM/yyyy") : ""}
                                />
                                <Input
                                  readOnly
                                  type="hidden"
                                  {...field}
                                  value={field.value ? format(field.value, "dd/MM/yyyy") : ""}
                                />
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-1 flex-col">
                        <div className="mb-4">
                          <FormItem>
                            <FormLabel className="text-main text-sm font-normal capitalize">
                              {c?.individual?.role ?? ""} Full Name
                            </FormLabel>
                            <Input
                              disabled
                              autoComplete="off"
                              value={
                                c.full_name ||
                                `${c?.individual?.first_name} ${c?.individual?.middle_name} ${c?.individual?.last_name}`
                              }
                            />
                          </FormItem>
                        </div>

                        <div className="mb-4">
                          <FormItem className="flex flex-col">
                            <FormLabel className="text-main text-sm font-normal capitalize">
                              Signature
                            </FormLabel>
                            {(!c?.signature || typeof c?.signature === "undefined") && (
                              <div className="bg-background h-24 w-full rounded-xl border opacity-50" />
                            )}
                            {typeof c?.signature === "string" && (
                              <img src={c?.signature} className="h-24 w-full rounded-xl border" />
                            )}
                          </FormItem>
                        </div>

                        <div className="mb-4">
                          <FormItem>
                            <FormLabel className="text-main text-sm font-normal capitalize">
                              Date
                            </FormLabel>
                            <Input
                              disabled
                              autoComplete="off"
                              value={c.sign_date ? format(c.sign_date, "dd/MM/yyyy") : ""}
                            />
                          </FormItem>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-2">
            <LoadingButton
              loading={store.loading}
              type="submit"
              className="flex items-center gap-4 px-6 py-4 md:!w-auto"
            >
              Submit
              <IconNext className="text-sm" />
            </LoadingButton>
          </div>
        </form>
      </FormProvider>

      <SubmitSuccessDialog open={submitSuccess} setOpen={setSubmitSuccess} />
    </GuestLayout>
  )
}
