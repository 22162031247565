import React, { useEffect } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { IconNext } from "@/components/_icons"
import { LoadingButton } from "@/components/_uiext"
import useAdminApplicationDraftStore from "@/stores/useAdminApplicationDraftStore"

import { Button } from "@repo/ui/components/ui/button"
import { Form, FormField, FormItem, FormMessage } from "@repo/ui/components/ui/form"
import { LOAN_TYPES } from "@repo/util/constant"
import { CLIENT_MSG } from "@repo/i18n"

import LoanTypeItem from "../_components/LoanTypeItem"
import { LOADING_TIMEOUT } from "../util"
import { useAdminAppContext } from "../AdminAppContext"

const FormSchema = z.object({
  loan_type: z.string({
    invalid_type_error: CLIENT_MSG.CHOOSE_ONE_ITEM,
    required_error: CLIENT_MSG.CHOOSE_ONE_ITEM
  })
})

type FormSchemaType = z.infer<typeof FormSchema>

export default function LoanType() {
  const { steps, activeStep, setActiveStep, setLoading, data, setData } = useAdminAppContext()
  const navigate = useNavigate()
  const store = useAdminApplicationDraftStore()

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      loan_type: undefined
    }
  })

  const handleBackClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          ...form.getValues(),
          current_step: steps[activeStep - 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev - 1)

        setTimeout(() => {
          setLoading(false)
        }, LOADING_TIMEOUT)
      }
    )
  }

  const handleSaveClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          ...form.getValues(),
          current_step: steps[activeStep]
        }
      },
      (newData) => {
        setData(newData)

        setTimeout(() => {
          setLoading(false)
          navigate("/admin/applications/list")
        }, LOADING_TIMEOUT)
      }
    )
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateAction(
      data.uuid,
      {
        params: {
          ...values,
          current_step: steps[activeStep + 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev + 1)
      }
    )
  }

  useEffect(() => {
    form.reset({
      loan_type: data?.loan_type
    })
  }, [data])

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col pr-0 xl:pr-[352px]">
            <h3 className="text-main mb-2 text-lg font-semibold">Loan type</h3>

            <p className="text-default mb-4 text-sm font-normal">
              Please select the applicable loan type.
            </p>

            <div className="w-full">
              <FormField
                control={form.control}
                name="loan_type"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
                      {Object.values(LOAN_TYPES)
                        .filter((lt) => lt.funding_solutions.includes(data?.funding_solution))
                        .sort((a, b) => a.key.localeCompare(b.key))
                        .map((lt) => (
                          <LoanTypeItem
                            key={lt.key}
                            isSelected={lt.key === field.value}
                            value={lt.key}
                            description={lt.description}
                            onClick={() => {
                              field.onChange(lt.key)
                              form.handleSubmit(onSubmit)()
                            }}
                          />
                        ))}
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>

        <div className="flex w-full justify-between gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto bg-transparent px-0 py-4 font-semibold hover:bg-transparent"
            onClick={handleSaveClick}
          >
            Save and exit
          </Button>

          <div className="flex justify-end gap-2">
            <Button
              type="button"
              className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
              onClick={handleBackClick}
            >
              Back
            </Button>

            <LoadingButton
              loading={store.locked}
              type="submit"
              className="flex items-center gap-4 px-6 py-4 md:!w-auto"
            >
              Next
              <IconNext className="text-sm" />
            </LoadingButton>
          </div>
        </div>
      </form>
    </Form>
  )
}
