import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

import { LoadingButton } from "@/components/_uiext"
import useAdminApplicationDraftStore from "@/stores/useAdminApplicationDraftStore"

import { Dialog, DialogContent } from "@repo/ui/components/ui/dialog"

import IndividualAutoComplete from "./IndividualAutoComplete"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  data: any
  setData: Dispatch<SetStateAction<any>>
}

export default function InviteBorrowerDialog(props: Readonly<Props>) {
  const { open, setOpen, data, setData } = props
  const store = useAdminApplicationDraftStore()
  const [options, setOptions] = useState<any[]>([])
  const [value, setValue] = useState<any[]>([])

  const handleInviteClick = () => {
    store.inviteBorrowersAction(
      data?.uuid ?? "",
      value.map((v) => v.uuid),
      (newData) => {
        setData(newData)
        setOpen(false)
      }
    )
  }

  useEffect(() => {
    if (data?.borrower?.individuals?.length > 0) {
      const tempOptions = data?.borrower?.individuals
      setOptions(tempOptions)

      const tempValue = data?.invites?.map((i: any) => i.individual)
      setValue(tempValue)
    }
  }, [data, open])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        className="flex h-full flex-col gap-0 bg-white p-10 md:h-auto"
        onOpenAutoFocus={(e: any) => e.preventDefault()}
      >
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col">
            <h1 className="text-main mb-6 text-center text-lg font-semibold">Invite borrower</h1>

            <div className="text-main mb-2 text-sm font-normal">Select borrowers email address</div>

            <div className="mb-4">
              <IndividualAutoComplete
                options={options}
                disabled={false}
                value={value}
                onValueChange={(value: any) => setValue(value)}
              />
            </div>
          </div>

          <div className="flex justify-center">
            <LoadingButton
              type="button"
              // disabled={!value.length}
              loading={store.locked}
              onClick={handleInviteClick}
              className="w-full px-6 py-4 md:w-auto"
            >
              Invite
            </LoadingButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
