export const extractPlace = (result: Record<string, any>) => {
  const address_components: Array<Record<string, any>> = result.address_components
  const formatted_address = result.formatted_address

  const streetNumber = address_components?.filter((ac) => ac.types.includes("street_number"))?.[0]
    ?.short_name

  const route = address_components?.filter((ac) => ac.types.includes("route"))?.[0]?.long_name

  const suburb = address_components?.filter((ac) => ac.types.includes("locality"))?.[0]?.short_name

  const state = address_components?.filter((ac) =>
    ac.types.includes("administrative_area_level_1")
  )?.[0]

  const country = address_components?.filter((ac) => ac.types.includes("country"))?.[0]

  const postcode = address_components?.filter((ac) => ac.types.includes("postal_code"))?.[0]
    ?.short_name

  return {
    formatted_address,
    street: streetNumber ? `${streetNumber} ${route}` : route,
    suburb,
    state,
    country,
    postcode
  }
}
