import qs from "qs"

import api from "./api"

// Get borrowers
export const apiGetBorrowersAtAdminApplicationDraft = (applicationUuid: string, params: any) =>
  api().get(`/admin/application/drafts/${applicationUuid}/get-borrowers?${qs.stringify(params)}`)

// Find lenders
export const apiFindLendersAtAdminApplicationDraft = (applicationUuid: string, params: any) =>
  api().get(`/admin/application/drafts/${applicationUuid}/find-lenders?${qs.stringify(params)}`)

// Create application draft
export const apiCreateAtAdminApplicationDraft = (params: any) =>
  api().postForm("/admin/application/drafts", params)

// Update application draft
export const apiUpdateAtAdminApplicationDraft = (applicationUuid: string, params: any) =>
  api().putForm(`/admin/application/drafts/${applicationUuid}`, params)

// Submit application draft
export const apiSubmitAtAdminApplicationDraft = (applicationUuid: string) =>
  api().patchForm(`/admin/application/drafts/${applicationUuid}`)

// Invite borrowers
export const apiInviteBorrowersAtAdminApplicationDraft = (applicationUuid: string, params: any) =>
  api().postForm(`/admin/application/drafts/${applicationUuid}/invite-borrowers`, params)
// Invite email
export const apiInviteEmailAtAdminApplicationDraft = (inviteUuid: string) =>
  api().postForm(`/admin/application/drafts/invite-email/${inviteUuid}`)

// Consent to act request
export const apiConsentToActRequestAtAdminApplicationDraft = (applicationUuid: string) =>
  api().postForm(`/admin/application/drafts/${applicationUuid}/consent-to-act-request`)

// Consent email
export const apiConsentEmailAtAdminApplicationDraft = (consentUuid: string) =>
  api().postForm(`/admin/application/drafts/consent-email/${consentUuid}`)

// ID verify request
export const apiIdVerifyRequestAtAdminApplicationDraft = (applicationUuid: string) =>
  api().postForm(`/admin/application/drafts/${applicationUuid}/id-verify-request`)

// Verify email
export const apiVerifyEmailAtAdminApplicationDraft = (verifyUuid: string) =>
  api().postForm(`/admin/application/drafts/verify-email/${verifyUuid}`)

// Refresh ID verifications
export const apiRefreshVerificationAtAdminApplicationDraft = (applicationUuid: string) =>
  api().postForm(`/admin/application/drafts/${applicationUuid}/refresh-verification`)
