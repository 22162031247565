import React from "react"

import { Link, Text, View } from "@react-pdf/renderer"

import { CHECK_ITEM_TYPES } from "@repo/util/constant"

interface Props {
  data: Record<string, any>
}

export default function ContentSupportingDocumentation(props: Readonly<Props>) {
  const { data } = props

  return (
    <View style={{ display: "flex", flexDirection: "column" }} wrap={true}>
      <Text
        style={{
          color: "#0E052B",
          fontWeight: 600,
          fontSize: 14,
          borderBottom: "1px solid #CDCDCD"
        }}
      >
        Supporting documents
      </Text>

      <View
        style={{
          paddingLeft: 32,
          paddingVertical: 16,
          display: "flex",
          flexDirection: "column",
          gap: 8
        }}
      >
        {data?.checkitems?.map((ci: any) => (
          <React.Fragment key={ci.section}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 16
              }}
            >
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>{ci.section}</Text>
            </View>

            {ci.items.map((item: any) => (
              <View
                key={item.text}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 16
                }}
              >
                <Text
                  style={{
                    flexGrow: 1,
                    flexShrink: 1,
                    flexBasis: "0%",
                    color: "#868194",
                    fontWeight: 400,
                    fontSize: 12
                  }}
                >
                  {item.text}
                </Text>

                {item.type === CHECK_ITEM_TYPES.YesNo && (
                  <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12 }}>
                    {item.result}
                  </Text>
                )}

                {item.type === CHECK_ITEM_TYPES.Input && (
                  <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 12 }}>
                    {item.result}
                  </Text>
                )}

                {item.type === CHECK_ITEM_TYPES.Upload && (
                  <Link
                    src={item.result}
                    style={{
                      color: "#0E052B",
                      fontWeight: 400,
                      fontSize: 12,
                      textDecoration: "none"
                    }}
                  >
                    {item.result ? "Click here" : "No present"}
                  </Link>
                )}
              </View>
            ))}
          </React.Fragment>
        ))}
      </View>
    </View>
  )
}
