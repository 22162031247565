import React from "react"

import { InputMask } from "@react-input/mask"
import { APIProvider } from "@vis.gl/react-google-maps"
import clsx from "clsx"
import { getNames } from "country-list"
import { format } from "date-fns"
import pluralize from "pluralize"

import { IconCalendar } from "@/components/_icons"
import { PlacesAutoPredict } from "@/components/_uiext"

import { Checkbox } from "@repo/ui/components/ui/checkbox"
import { FormControl, FormItem, FormLabel } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import {
  BORROWER_INDIVIDUAL_ROLES,
  GENDERS,
  MARITAL_STATUSES,
  RESIDENTIAL_STATUSES,
  TITLES,
  YN
} from "@repo/util/constant"

import { useAdminAppContext } from "../AdminAppContext"

export default function IndividualALDetail({ item }: Readonly<any>) {
  const { data } = useAdminAppContext()

  return (
    <div className="flex flex-col py-4">
      <div
        className={clsx(
          "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
          Object.entries(BORROWER_INDIVIDUAL_ROLES).filter((bir) =>
            bir[1].entity_types.includes(data?.borrower?.entity_type)
          )?.length > 0
            ? ""
            : "hidden"
        )}
      >
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Role</FormLabel>
            <Select disabled value={item.role}>
              <SelectTrigger
                className={clsx(
                  "h-12 w-full text-sm capitalize",
                  item.role ? "text-main" : "text-default"
                )}
              >
                <SelectValue placeholder="Please select" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(BORROWER_INDIVIDUAL_ROLES).map(([k, v]) => {
                  if (!v.entity_types.includes(data?.borrower?.entity_type)) return null

                  return (
                    <SelectItem key={k} value={v.key} className="capitalize">
                      {v.key}
                    </SelectItem>
                  )
                })}
              </SelectContent>
            </Select>
          </FormItem>
        </div>

        <div className="hidden flex-1 md:block"></div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Title</FormLabel>
            <Select disabled value={item.title}>
              <SelectTrigger
                className={clsx("h-12 w-full text-sm", item.title ? "text-main" : "text-default")}
              >
                <SelectValue placeholder="Please select" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(TITLES).map(([k, v]) => (
                  <SelectItem key={k} value={v}>
                    {v}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormItem>
        </div>

        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Gender</FormLabel>
            <Select disabled value={item.gender}>
              <SelectTrigger
                className={clsx("h-12 w-full text-sm", item.gender ? "text-main" : "text-default")}
              >
                <SelectValue placeholder="Please select" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(GENDERS).map(([k, v]) => (
                  <SelectItem key={k} value={v}>
                    {v}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormItem>
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">First name</FormLabel>
            <Input disabled autoComplete="off" value={item.first_name} />
          </FormItem>
        </div>

        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Middle name</FormLabel>
            <Input disabled autoComplete="off" value={item.middle_name} />
          </FormItem>
        </div>

        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Last name</FormLabel>
            <Input disabled autoComplete="off" value={item.last_name} />
          </FormItem>
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Marital status</FormLabel>
            <Select disabled value={item.marital_status}>
              <SelectTrigger
                className={clsx(
                  "h-12 w-full text-sm",
                  item.marital_status ? "text-main" : "text-default"
                )}
              >
                <SelectValue placeholder="Please select" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(MARITAL_STATUSES).map(([k, v]) => (
                  <SelectItem key={k} value={v}>
                    {v}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormItem>
        </div>

        <div className="flex-1">
          <FormItem className="flex flex-col">
            <FormLabel className="text-main mb-1 text-sm font-normal">Birth date</FormLabel>
            <FormControl>
              <div className="relative">
                <Input
                  readOnly
                  disabled
                  className="cursor-pointer"
                  value={format(item.birth_date, "dd/MM/yyyy")}
                />
                <div className="absolute right-[17.5px] top-[50%] translate-y-[-50%]">
                  <IconCalendar className="text-default text-lg" />
                </div>
              </div>
            </FormControl>
          </FormItem>
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem className="flex flex-col">
            <FormLabel className="text-main text-sm font-normal">Country of birth</FormLabel>
            <Select disabled value={item.birth_country}>
              <SelectTrigger
                className={clsx(
                  "h-12 w-full text-sm",
                  item.birth_country ? "text-main" : "text-default"
                )}
              >
                <SelectValue placeholder="Please select" />
              </SelectTrigger>
              <SelectContent>
                {getNames()
                  .sort((a, b) => a.localeCompare(b))
                  .map((as) => (
                    <SelectItem key={as} value={as}>
                      {as}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          </FormItem>
        </div>

        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Place of birth</FormLabel>
            <Input disabled autoComplete="off" value={item.birth_place} />
          </FormItem>
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-semibold">Residential address</FormLabel>
            <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY ?? ""}>
              <PlacesAutoPredict
                disabled
                textValue={item.residential_full_address}
                onPlaceSelect={() => {}}
              />
            </APIProvider>
          </FormItem>
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem className="flex flex-col">
            <FormLabel className="text-main text-sm font-normal">Residential years</FormLabel>
            <Select disabled value={item.residential_years.toString()}>
              <SelectTrigger
                className={clsx(
                  "h-12 w-full text-sm",
                  item.residential_years ? "text-main" : "text-default"
                )}
              >
                <SelectValue placeholder="Please select" />
              </SelectTrigger>
              <SelectContent>
                {Array.from({ length: 50 }, (_, i) => i + 1).map((ry) => (
                  <SelectItem key={ry} value={ry.toString()}>
                    {ry} {pluralize("year", ry)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormItem>
        </div>

        <div className="flex-1">
          <FormItem className="flex flex-col">
            <FormLabel className="text-main text-sm font-normal">Residential months</FormLabel>
            <Select disabled value={item.residential_months?.toString()}>
              <SelectTrigger
                className={clsx(
                  "h-12 w-full text-sm",
                  item.residential_months ? "text-main" : "text-default"
                )}
              >
                <SelectValue placeholder="Please select" />
              </SelectTrigger>
              <SelectContent>
                {Array.from({ length: 12 }, (_, i) => i + 1).map((rm) => (
                  <SelectItem key={rm} value={rm.toString()}>
                    {rm} {pluralize("month", rm)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormItem>
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-semibold">
              Previous address (if less than 2 years at current address)
            </FormLabel>
            <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY ?? ""}>
              <PlacesAutoPredict
                disabled
                textValue={item.previous_full_address}
                onPlaceSelect={() => {}}
              />
            </APIProvider>
          </FormItem>
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:items-center md:gap-8">
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Australian resident</FormLabel>
            <Select disabled value={item.australian_resident}>
              <SelectTrigger
                className={clsx(
                  "h-12 w-full text-sm",
                  item.australian_resident ? "text-main" : "text-default"
                )}
              >
                <SelectValue placeholder="Please select" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(YN).map(([k, v]) => (
                  <SelectItem key={k} value={v}>
                    {v}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormItem>
        </div>

        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">
              Current residential Status
            </FormLabel>
            <Select disabled value={item.current_residential_status}>
              <SelectTrigger
                className={clsx(
                  "h-12 w-full text-sm",
                  item.current_residential_status ? "text-main" : "text-default"
                )}
              >
                <SelectValue placeholder="Please select" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(RESIDENTIAL_STATUSES).map(([k, v]) => (
                  <SelectItem key={k} value={v}>
                    {v}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormItem>
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Postal address</FormLabel>
            <Input disabled autoComplete="off" value={item.postal_address} />
          </FormItem>
        </div>

        <div className="hidden flex-1 md:block" />
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Email address</FormLabel>
            <Input disabled type="email" autoComplete="off" value={item.email} />
          </FormItem>
        </div>

        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Mobile number</FormLabel>
            <InputMask
              showMask
              disabled
              component={Input}
              mask="04__ ___ ___"
              placeholder="04__ ___ ___"
              replacement={{ _: /\d/ }}
              value={item.mobile_number}
            />
          </FormItem>
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Drivers license number</FormLabel>
            <Input disabled autoComplete="off" value={item.drivers_license_number} />
          </FormItem>
        </div>

        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Card number</FormLabel>
            <Input disabled autoComplete="off" value={item.card_number} />
          </FormItem>
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem className="flex flex-col">
            <FormLabel className="text-main mb-1 text-sm font-normal">Expiry date</FormLabel>
            <FormControl>
              <div className="relative">
                <Input
                  readOnly
                  disabled
                  className="cursor-pointer"
                  value={format(item.expiry_date, "dd/MM/yyyy")}
                />
                <div className="absolute right-[17.5px] top-[50%] translate-y-[-50%]">
                  <IconCalendar className="text-default text-lg" />
                </div>
              </div>
            </FormControl>
          </FormItem>
        </div>

        <div className="hidden flex-1 md:block" />
      </div>
    </div>
  )
}
