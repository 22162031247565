import qs from "qs"

import api from "./api"

// Get
export const apiGetAdminApplicationsList = (params: any) =>
  api().get(`/admin/applications?${qs.stringify(params)}`)
export const apiGetAdminApplicationByUuid = (uuid: string) =>
  api().get(`/admin/applications/${uuid}`)

// Archive
export const apiArchiveAdminApplication = (uuid: string) =>
  api().patch(`/admin/applications/${uuid}/archive`)

// Update
export const apiUpdateAdminApplication = (uuid: string, params: any) =>
  api().putForm(`/admin/applications/${uuid}`, params)

// Update status
export const apiUpdateAdminApplicationStatus = (uuid: string, params: any) =>
  api().putForm(`/admin/applications/status/${uuid}`, params)

// Update earning
export const apiUpdateAdminApplicationEarning = (uuid: string, params: any) =>
  api().putForm(`/admin/applications/earning/${uuid}`, params)

// Update indicative approval
export const apiUpdateAdminApplicationIndicativeApproval = (uuid: string, params: any) =>
  api().putForm(`/admin/applications/indicative-approval/${uuid}`, params)

// Update formal approval
export const apiUpdateAdminApplicationFormalApproval = (uuid: string, params: any) =>
  api().putForm(`/admin/applications/formal-approval/${uuid}`, params)
