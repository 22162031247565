import React, { Dispatch, SetStateAction, useState } from "react"

import clsx from "clsx"
import { NumericFormat } from "react-number-format"

import { IconFilter } from "@/components/_icons"
import { PopDlg, PopDlgContent, PopDlgTrigger } from "@/components/_uiext/PopDlg"

import { Button } from "@repo/ui/components/ui/button"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { LOAN_TERMS, REPAYMENTS, ALL } from "@repo/util/constant"

interface Props {
  params: any
  setParams: Dispatch<SetStateAction<any>>
}

export default function LenderFilterPopover({ params, setParams }: Readonly<Props>) {
  const [open, setOpen] = useState(false)

  const [interestRate, setInterestRate] = useState(params.interest_rate)
  const [loanValueRatio, setLoanValueRatio] = useState(params.loan_value_ratio)
  const [minimumLoanSize, setMinimumLoanSize] = useState(params.minimum_loan_size)
  const [maximumLoanSize, setMaximumLoanSize] = useState(params.maximum_loan_size)
  const [minimumLoanTerm, setMinimumLoanTerm] = useState(params.minimum_loan_term)
  const [maximumLoanTerm, setMaximumLoanTerm] = useState(params.maximum_loan_term)
  const [paymentOption, setPaymentOption] = useState(params.payment_option)

  const handleResetClick = () => {
    setInterestRate("")
    setLoanValueRatio("")
    setMinimumLoanSize("")
    setMaximumLoanSize("")
    setMinimumLoanTerm("")
    setMaximumLoanTerm("")
    setPaymentOption("")

    setParams({
      interest_rate: "",
      loan_value_ratio: "",
      minimum_loan_size: "",
      maximum_loan_size: "",
      minimum_loan_term: "",
      maximum_loan_term: "",
      payment_option: ""
    })
    setOpen(false)
  }

  const handleApplyClick = () => {
    setParams({
      interest_rate: interestRate,
      loan_value_ratio: loanValueRatio,
      minimum_loan_size: minimumLoanSize,
      maximum_loan_size: maximumLoanSize,
      minimum_loan_term: minimumLoanTerm,
      maximum_loan_term: maximumLoanTerm,
      payment_option: paymentOption
    })
    setOpen(false)
  }

  return (
    <PopDlg open={open} onOpenChange={setOpen}>
      <PopDlgTrigger asChild>
        <Button
          className={clsx(
            "text-default flex h-auto w-full items-center justify-center gap-1.5 rounded-xl border bg-transparent px-4 py-3 text-sm font-semibold hover:bg-transparent md:w-auto",
            open ? "border-primary" : "border-[#868194]"
          )}
        >
          <IconFilter className="text-xl" />
          Filter
        </Button>
      </PopDlgTrigger>

      <PopDlgContent align="end" className="w-full md:w-[400px]">
        <div className="flex flex-col justify-center gap-4">
          <p className="text-main text-sm">Filter</p>

          <NumericFormat
            customInput={Input}
            thousandSeparator
            prefix="%"
            decimalScale={2}
            fixedDecimalScale
            placeholder="% Interest rate"
            value={interestRate ?? ""}
            onValueChange={(values, sourceInfo) => {
              setInterestRate(values.value)
            }}
          />
          <NumericFormat
            customInput={Input}
            thousandSeparator
            prefix="%"
            decimalScale={2}
            fixedDecimalScale
            placeholder="% Maximum LVR"
            value={loanValueRatio ?? ""}
            onValueChange={(values, sourceInfo) => {
              setLoanValueRatio(values.value)
            }}
          />

          <div className="flex items-center gap-2">
            <NumericFormat
              customInput={Input}
              thousandSeparator
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              placeholder="$ Min loan size"
              value={minimumLoanSize ?? ""}
              onValueChange={(values, sourceInfo) => {
                setMinimumLoanSize(values.value)
              }}
            />
            <span className="text-default text-sm">-</span>
            <NumericFormat
              customInput={Input}
              thousandSeparator
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              placeholder="$ Max loan size"
              value={maximumLoanSize ?? ""}
              onValueChange={(values, sourceInfo) => {
                setMaximumLoanSize(values.value)
              }}
            />
          </div>

          <div className="flex items-center gap-2">
            <Select onValueChange={(v) => setMinimumLoanTerm(v)} value={minimumLoanTerm}>
              <SelectTrigger
                className={clsx(
                  "h-12 w-full text-sm",
                  minimumLoanTerm ? "text-main" : "text-default"
                )}
              >
                <SelectValue placeholder="Min loan term" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem className="capitalize" value={ALL}>
                  {ALL}
                </SelectItem>
                {Object.entries(LOAN_TERMS).map(([k, v]) => (
                  <SelectItem key={k} value={v.key.toString()}>
                    {v.text}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <span className="text-default text-sm">-</span>
            <Select onValueChange={(v) => setMaximumLoanTerm(v)} value={maximumLoanTerm}>
              <SelectTrigger
                className={clsx(
                  "h-12 w-full text-sm",
                  maximumLoanTerm ? "text-main" : "text-default"
                )}
              >
                <SelectValue placeholder="Max loan term" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem className="capitalize" value={ALL}>
                  {ALL}
                </SelectItem>
                {Object.entries(LOAN_TERMS).map(([k, v]) => (
                  <SelectItem key={k} value={v.key.toString()}>
                    {v.text}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <Select onValueChange={(v) => setPaymentOption(v)} value={paymentOption}>
            <SelectTrigger
              className={clsx("h-12 w-full text-sm", paymentOption ? "text-main" : "text-default")}
            >
              <SelectValue placeholder="Payment options" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem className="capitalize" value={ALL}>
                {ALL}
              </SelectItem>
              {Object.entries(REPAYMENTS).map(([k, v]) => (
                <SelectItem key={k} value={v}>
                  {v}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <div className="flex justify-end gap-2.5">
            <Button
              className="text-default h-auto w-full border border-[#868194] bg-transparent px-6 py-4 text-sm font-semibold hover:bg-transparent md:w-auto"
              onClick={handleResetClick}
            >
              Reset
            </Button>
            <Button
              className="border-primary h-auto w-full border px-6 py-4 text-sm font-semibold md:w-auto"
              onClick={handleApplyClick}
            >
              Apply filter
            </Button>
          </div>
        </div>
      </PopDlgContent>
    </PopDlg>
  )
}
