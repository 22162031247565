import React, { useEffect, useState } from "react"

import clsx from "clsx"
import { format } from "date-fns"
import json2mq from "json2mq"
import numeral from "numeral"
import { Oval } from "react-loader-spinner"
import { useMediaQuery } from "react-responsive"
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Rectangle } from "recharts"

import { IconPurse } from "@/components/_icons"

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"

interface CustomXAxisTickProps {
  x: number
  y: number
  payload: {
    value: string
  }
}

const CustomXAxisTick = ({ x, y, payload }: CustomXAxisTickProps): JSX.Element => {
  return (
    <g transform={`translate(${x},${y - 8})`} style={{ zIndex: 1000 }}>
      <circle cx={0} cy={0} r={4} fill="#CDCDCD" />

      <text x={0} y={20} textAnchor="middle" fill="#868194" fontSize={10}>
        {payload.value}
      </text>
    </g>
  )
}

interface CustomTooltipProps {
  active?: boolean
  payload?: any
  label?: string
}

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className="flex flex-col gap-1 rounded-xl border bg-white p-4">
        <p className="text-main text-sm font-semibold">{label}</p>

        {payload.map((p: any) => (
          <p key={p.dataKey} className="text-default text-sm font-normal" style={{ color: p.fill }}>
            {p.dataKey === "commissions_earned" ? "Total commissions" : "Total settled"}:{" "}
            <span className="font-semibold">{numeral(p.value).format("$0,0")}</span>
          </p>
        ))}
      </div>
    )
  }

  return null
}

interface Props {
  loading: boolean
  data: any
}

export default function CommissionsSettlements(props: Readonly<Props>) {
  const { loading, data } = props

  const isMobile = useMediaQuery({ query: json2mq({ maxWidth: 767 }) })
  const [years, setYears] = useState<number[]>([])
  const [selectedYear, setSelectedYear] = useState<number>()
  const [chartData, setChartData] = useState<any[]>([
    {
      year: 2024,
      month: 1,
      month_name: "Jan",
      commissions_earned: 1000,
      settled_amount: 1000
    }
  ])

  useEffect(() => {
    const tempYears: number[] = [new Date().getFullYear()]

    for (const d of data) {
      if (d.earning_date) {
        const y = Number(d.earning_date.substring(0, 4))

        if (!tempYears.includes(y)) {
          tempYears.push(y)
        }
      }
    }

    const sortedTempYears = tempYears.toSorted((a, b) => b - a)

    const tempChartData: any[] = []
    for (const y of sortedTempYears) {
      for (let i = 1; i <= 12; i++) {
        const date = new Date(y, i - 1)

        tempChartData.push({
          year: y,
          month: i,
          month_name: format(date, isMobile ? "MMMMM" : "MMM"),
          commissions_earned: 0,
          settled_amount: 0
        })
      }
    }

    for (const d of data) {
      if (d.earning_date) {
        const y = Number(d.earning_date.substring(0, 4))
        const m = Number(d.earning_date.substring(5, 7))
        const one = tempChartData.filter((tcd) => tcd.year === y && tcd.month === m)?.[0]
        one.commissions_earned += d.commissions_earned
        one.settled_amount += d.settled_amount
      }
    }

    setYears(sortedTempYears)
    setSelectedYear(sortedTempYears?.[0])
    setChartData(tempChartData)
  }, [data])

  return (
    <div className="flex w-full flex-col rounded-2xl border border-[#ECECEC] bg-white">
      <div className="flex w-full flex-col items-center justify-between gap-4 border-b border-b-[#ECECEC] px-4 py-4 md:flex-row">
        <h3 className="text-main text-lg font-semibold">Commissions & settlements</h3>

        <div className="flex flex-col items-center gap-4 md:flex-row">
          <div className="flex gap-4">
            <div className="flex items-center gap-1.5">
              <div className="h-2 min-w-8 rounded-full bg-[#379FFF]"></div>
              <p className="text-default text-nowrap text-xs font-normal">Commission earned</p>
            </div>

            <div className="flex items-center gap-1.5">
              <div className="h-2 min-w-8 rounded-full bg-[#08ECB3]"></div>
              <p className="text-default text-nowrap text-xs font-normal">Settled amount</p>
            </div>
          </div>

          <Select
            onValueChange={(v) => setSelectedYear(Number(v))}
            value={selectedYear?.toString()}
          >
            <SelectTrigger
              className={clsx(
                "h-12 w-full text-sm capitalize md:w-40",
                selectedYear ? "text-main" : "text-default"
              )}
            >
              <SelectValue placeholder="Year" />
            </SelectTrigger>
            <SelectContent>
              {years.map((y) => (
                <SelectItem key={y} value={y.toString()}>
                  {y}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      {loading && (
        <div className="flex flex-col items-center justify-center p-6">
          <Oval visible={true} height="80" width="80" color="#08ECB3" secondaryColor="#08ECB3" />
        </div>
      )}

      {!loading && (
        <>
          {chartData
            .filter((cd) => cd.year === selectedYear)
            .reduce((sum, record) => sum + record.commissions_earned + record.settled_amount, 0) ===
          0 ? (
            <div className="flex flex-col p-8">
              <div className="mb-4 flex justify-center">
                <div className="flex max-h-16 min-h-16 min-w-16 max-w-16 items-center justify-center rounded-full bg-[#CDCDCD]">
                  <IconPurse className="text-2xl text-white" />
                </div>
              </div>

              <p className="text-default mb-4 text-center text-lg font-semibold">
                No commissions found
              </p>
            </div>
          ) : (
            <div className="h-64 w-full p-8">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={600}
                  height={300}
                  data={chartData.filter((cd) => cd.year === selectedYear)}
                >
                  <YAxis
                    includeHidden={false}
                    tickLine={false}
                    axisLine={false}
                    tick={{ fontSize: 10, textAnchor: "start", dx: -50, fill: "#868194" }}
                    tickFormatter={(value: any, index: number) =>
                      `${numeral(value).format("$0,0")}`
                    }
                  />
                  <Tooltip content={<CustomTooltip />} cursor={false} />
                  <Bar
                    dataKey="commissions_earned"
                    fill="#379FFF"
                    radius={[8, 8, 0, 0]}
                    activeBar={<Rectangle fill="#379FFF" stroke="#379FFF" radius={[8, 8, 0, 0]} />}
                  />
                  <Bar
                    dataKey="settled_amount"
                    fill="#08ECB3"
                    radius={[8, 8, 0, 0]}
                    activeBar={<Rectangle fill="#08ECB3" stroke="#08ECB3" radius={[8, 8, 0, 0]} />}
                  />
                  <XAxis
                    includeHidden={false}
                    dataKey="month_name"
                    tickLine={false}
                    axisLine={{ stroke: "#CDCDCD" }}
                    tick={CustomXAxisTick}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
        </>
      )}
    </div>
  )
}
